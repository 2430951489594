import axios from "axios";
import axiosRetry from "axios-retry";
import { useAuth0 } from "@auth0/auth0-vue";
import { useTravelDataStore } from "~/stores/travelDataStore";
// import { isTokenExpired, removeToken, getToken } from '~/services/tokenService';
// import { useUserStore } from '~/store/userStore';

// Deprecation fallback
export function accApiClient() {
  return goodBitsClient();
}

export function goodBitsClient(options) {
  const config = useRuntimeConfig();
  const headers = {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": config.public.goodbitsKey,
  };

  try {
    const travelDataStore = useTravelDataStore();
    if (travelDataStore.isAppFlow) {
      headers["Ocp-Apim-Subscription-Key"] = config.public.goodbitsKeyApp;
    }
    const userStore = useUserStore();
    const auth0token = userStore.auth0token;

    if (auth0token) {
      headers["Authorization"] = `Bearer ${auth0token}`;
    }
  } catch (e) {
    // No userstore available!
  }

  if (options?.otherToken) {
    headers["Authorization"] = `Bearer ${options.otherToken}`;
  }

  let baseUrl = config.public.goodbitsUrl;

  const client = axios.create({
    baseURL: baseUrl,
    headers,
  });

  axiosRetry(client, {
    retries: 3,
    retryDelay: (retryCount, error) => {
      return retryCount * 2000;
    },
  });

  return client;
}

export function goodBitsClientAnon() {
  const config = useRuntimeConfig();

  const headers = {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": config.public.goodbitsKey,
  };

  let baseUrl = config.public.goodbitsUrl;

  const client = axios.create({
    baseURL: baseUrl,
    headers,
  });

  axiosRetry(client, {
    retries: 3,
    retryDelay: (retryCount, error) => {
      return retryCount * 2000;
    },
  });

  return client;
}
