export default function useNavigation() {
  /**
   * We need to keep two states for drawers to be open. The regular drawer open keeps a state of selected drawer.
   * When setting this to false, the entire subtree gets removed from the dom and we do not know the selected drawe anymore.
   *
   * Easiest fix for now is to keep a separate state for mobile drawer open as it is currently (which is absolutely not necessary).
   */
  const drawerOpen = useState("drawerOpen", () => false);
  const mobileDrawerOpen = useState("mobileDrawerOpen", () => false);

  function resetNavigationActiveState() {
    if (!import.meta.client) {
      return;
    }

    const navItemsElement =
      document.getElementsByClassName("nav-item-remover") || [];

    for (let i = 0; i < navItemsElement.length; i++) {
      navItemsElement[i].classList.remove("active");
    }
  }

  return {
    drawerOpen,
    mobileDrawerOpen,
    resetNavigationActiveState,
  };
}
