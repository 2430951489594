// formatSingleDate: formats a single date string as a human-readable date
export const formatSingleDate = (
  dateString,
  includeYear = false,
  includeWeekDay = false,
) => {
  const options = { month: "short", day: "numeric", timeZone: "UTC" }; //weekday: 'short',

  if (includeYear) {
    options.year = "numeric";
  }
  if (includeWeekDay) {
    options.weekday = "short";
  }
  let date = new Date(dateString);
  if (typeof dateString == "string") {
    const dateWithoutTime = dateString.slice(0, 10);
    date = new Date(dateWithoutTime);
  }
  return date.toLocaleDateString(undefined, options);
};

// formatMYSQLDate: formats a date string in mysql format
export const formatMYSQLDate = (dateString) => {
  return dateString
    ? new Date(dateString).toISOString().split("T")[0]
    : undefined;
};

export const unifyDates = (dates) => {
  const arrival = dates.start;
  const departure = dates.end;

  const arrivalDate = `${arrival.getFullYear()}-${arrival.getMonth() < 9 ? "0" : ""}${arrival.getMonth() + 1}-${arrival.getDate() < 10 ? "0" : ""}${arrival.getDate()}`;
  const departureDate = `${departure.getFullYear()}-${departure.getMonth() < 9 ? "0" : ""}${departure.getMonth() + 1}-${departure.getDate() < 10 ? "0" : ""}${departure.getDate()}`;

  return {
    arrivalDate,
    departureDate,
  };
};

// formatDate: formats date range provided start and end values or an object with start and end values
export const formatDate = ({ start, end } = {}, startFallback, endFallback) => {
  if (start && end) {
    return `${formatSingleDate(start)} - ${formatSingleDate(end)}`;
  } else if (startFallback && endFallback) {
    return `${formatSingleDate(startFallback)} - ${formatSingleDate(endFallback)}`;
  } else {
    return "";
  }
};

// formatTime: formats a time string as a human-readable time
export const formatTime = (timeString) => {
  const date = new Date(`2000-01-01 ${timeString}:00`);
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  return date.toLocaleTimeString(undefined, options);
};

export const formatTimeFromDateStr = (dateString, timezone) => {
  let dt = new Date(dateString);
  const options = {
    hour: "2-digit",
    minute: "2-digit",
  };
  if (timezone) {
    options.timeZone = timezone;
  }
  // Ignore "timezone"-part in date string
  else if (dateString.length === "0000-00-00T00:00:00+00:00".length) {
    dt = new Date(dateString.substring(0, dateString.length - 6));
  }
  return dt.toLocaleTimeString(undefined, options);
};

export const nightsCountFromDateStrings = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const timeDiff = endDate.getTime() - startDate.getTime();
  return Math.ceil(timeDiff / (1000 * 3600 * 24));
};

// formatTimeString: formats a military time string as a regular time string
export const formatTimeString = (timeString) => {
  const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  if (!timeRegex.test(timeString)) {
    return "";
  }

  return formatTime(timeString);
};
