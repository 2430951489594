import { defineStore } from "pinia";

export const useLayoutStore = defineStore("layout", {
  state: () => ({
    isNavigationHeaderVisible: true,
    isFooterVisible: true,
  }),
  actions: {
    hideNavAndFooter() {
      this.isNavigationHeaderVisible = false;
      this.isFooterVisible = false;
    },
    showNavAndFooter() {
      this.isNavigationHeaderVisible = true;
      this.isFooterVisible = true;
    },
  },
});
