export default defineNuxtRouteMiddleware((to, from) => {
  let manipulated = false;

  if (
    from.fullPath != to.fullPath &&
    from.name?.startsWith("book___") &&
    to.name?.startsWith("book-rates___") &&
    JSON.stringify(from.query) != JSON.stringify(to.query)
  ) {
    to.query = { ...to.query, ...from.query };
    manipulated = true;
  }

  const keepParams = [
    "unmaskTranslations",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "gclid",
    "fclid",
  ];
  keepParams.map((para) => {
    if (from.query.hasOwnProperty(para) && !to.query.hasOwnProperty(para)) {
      to.query[para] = from.query[para];
      manipulated = true;
    }
  });

  if (manipulated) {
    return navigateTo(to);
  }
});
