
// @ts-nocheck


export const localeCodes =  [
  "en",
  "nl",
  "fr",
  "zh",
  "it",
  "es"
]

export const localeLoaders = {
  en: [
    {
      key: "locale_messageLoader_46ts_8f67527f",
      load: () => import("#nuxt-i18n/8f67527f" /* webpackChunkName: "locale_messageLoader_46ts_8f67527f" */),
      cache: false
    }
  ],
  nl: [
    {
      key: "locale_messageLoader_46ts_8f67527f",
      load: () => import("#nuxt-i18n/8f67527f" /* webpackChunkName: "locale_messageLoader_46ts_8f67527f" */),
      cache: false
    }
  ],
  fr: [
    {
      key: "locale_messageLoader_46ts_8f67527f",
      load: () => import("#nuxt-i18n/8f67527f" /* webpackChunkName: "locale_messageLoader_46ts_8f67527f" */),
      cache: false
    }
  ],
  zh: [
    {
      key: "locale_messageLoader_46ts_8f67527f",
      load: () => import("#nuxt-i18n/8f67527f" /* webpackChunkName: "locale_messageLoader_46ts_8f67527f" */),
      cache: false
    }
  ],
  it: [
    {
      key: "locale_messageLoader_46ts_8f67527f",
      load: () => import("#nuxt-i18n/8f67527f" /* webpackChunkName: "locale_messageLoader_46ts_8f67527f" */),
      cache: false
    }
  ],
  es: [
    {
      key: "locale_messageLoader_46ts_8f67527f",
      load: () => import("#nuxt-i18n/8f67527f" /* webpackChunkName: "locale_messageLoader_46ts_8f67527f" */),
      cache: false
    }
  ]
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en",
      language: "en-US",
      files: [
        "/home/vsts/work/1/s/apps/lobby/i18n/locales/messageLoader.ts"
      ]
    },
    {
      code: "nl",
      language: "nl-NL",
      files: [
        "/home/vsts/work/1/s/apps/lobby/i18n/locales/messageLoader.ts"
      ]
    },
    {
      code: "fr",
      language: "fr-FR",
      files: [
        "/home/vsts/work/1/s/apps/lobby/i18n/locales/messageLoader.ts"
      ]
    },
    {
      code: "zh",
      language: "zh-Hant",
      files: [
        "/home/vsts/work/1/s/apps/lobby/i18n/locales/messageLoader.ts"
      ]
    },
    {
      code: "it",
      language: "it-IT",
      files: [
        "/home/vsts/work/1/s/apps/lobby/i18n/locales/messageLoader.ts"
      ]
    },
    {
      code: "es",
      language: "es-US",
      files: [
        "/home/vsts/work/1/s/apps/lobby/i18n/locales/messageLoader.ts"
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  customRoutes: "config",
  pages: {
    "get-booking/reservations": {
      nl: "/get-booking/reserveringen",
      it: "/get-booking/prenotaziones",
      es: "/get-booking/reservas"
    },
    "get-booking/reservation": {
      nl: "/get-booking/reservering",
      it: "/get-booking/prenotazione",
      es: "/get-booking/reserva"
    },
    "get-booking/reservation-payment": {
      nl: "/get-booking/reservering-payment",
      it: "/get-booking/prenotazione-payment",
      es: "/get-booking/reserva-payment"
    },
    "my/profile": {
      nl: "/my/mijn-profiel",
      fr: "/my/mon-profil",
      it: "/my/il-mio-profilo",
      es: "/my/mi-perfil"
    },
    "my/reservations": {
      nl: "/my/mijn-reserveringen",
      fr: "/my/mes-reservations",
      it: "/my/le-mie-prenotazioni",
      es: "/my/mis-reservas"
    },
    "my/reservation-[reservationId]": {
      nl: "/my/mijn-reservering-[reservationId]",
      fr: "/my/ma-reservation-[reservationId]",
      it: "/my/la-mia-prenotazione-[reservationId]",
      es: "/my/mi-reserva-[reservationId]"
    },
    "my/booking-[reservationId]": {
      nl: "/my/mijn-boeking-[reservationId]"
    },
    "my/membership": {
      nl: "/my/lidmaatschap",
      fr: "/my/abonnement"
    },
    "hotels/index": {
      es: "/hoteles"
    },
    "hotels/[continent]/index": {
      es: "/hoteles/[continent]"
    },
    "hotels/[continent]/[city]/index": {
      es: "/hoteles/[continent]/[city]"
    },
    "hotels/[continent]/[city]/[hotelSlug]/index": {
      es: "/hoteles/[continent]/[city]/[hotelSlug]"
    },
    "hotels/[continent]/[city]/[hotelSlug]/[featureSlug]": {
      es: "/hoteles/[continent]/[city]/[hotelSlug]/[featureSlug]"
    },
    register: {
      nl: "/inschrijven",
      fr: "/souscription",
      it: "/registro"
    }
  },
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en",
    language: "en-US",
    files: [
      {
        path: "/home/vsts/work/1/s/apps/lobby/i18n/locales/messageLoader.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "nl",
    language: "nl-NL",
    files: [
      {
        path: "/home/vsts/work/1/s/apps/lobby/i18n/locales/messageLoader.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "fr",
    language: "fr-FR",
    files: [
      {
        path: "/home/vsts/work/1/s/apps/lobby/i18n/locales/messageLoader.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "zh",
    language: "zh-Hant",
    files: [
      {
        path: "/home/vsts/work/1/s/apps/lobby/i18n/locales/messageLoader.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "it",
    language: "it-IT",
    files: [
      {
        path: "/home/vsts/work/1/s/apps/lobby/i18n/locales/messageLoader.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "es",
    language: "es-US",
    files: [
      {
        path: "/home/vsts/work/1/s/apps/lobby/i18n/locales/messageLoader.ts",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/