export default defineNuxtPlugin(({ $config }) => {
  const { public: publicConfig } = $config;

  // There seems to be a timing issue for handling the windows.
  // cmtr object. Therefore this script cannot be migrated yet to nuxt scripts.
  useHead({
    script: [
      {
        src: "/scripts3/tracker.js?999",
        defer: true,
        type: "text/javascript",
      },
    ],
  });

  if (publicConfig.redFastLabs) {
    useScript({
      src: publicConfig.redFastLabs,
      defer: true,
    });
  }

  if (publicConfig.convertExperiments) {
    useScript({
      src: publicConfig.convertExperiments,
      defer: true,
    });
  }

  if (publicConfig.oneTrust) {
    useScript({
      src: publicConfig.oneTrust,
      "data-document-language": true,
      "data-domain-script": publicConfig.oneTrustDataDomain,
    });
  }

  useScript({
    src: "/scripts3/datalayer.js?2",
    defer: true,
  });

  useScript({
    src: "/scripts3/newrelic.js",
    defer: true,
  });

  useScript({
    src: "/scripts3/onetrust.js?2",
  });

  if (publicConfig.rpiScriptSrc) {
    useScript({
      src: publicConfig.rpiScriptSrc,
      defer: true,
      fetchpriority: "high",
    });
  }
});
