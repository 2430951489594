import { goodBitsClient } from "./httpService";
import { getCitySlug, getContinentSlug } from "~/services/contentfulService";
import { buildSlugFromPropertyCode } from "~/services/slugService";

export function fetchCurrencies(userLanguage) {
  return goodBitsClient().get("/masterdata/v1/currencies", {
    params: {
      userLanguage,
    },
  });
}

export async function fetchRatePlans(userLanguage, ratePlanCodes) {
  return await goodBitsClient().get("/masterdata/v2/rate-plans", {
    params: {
      userLanguage,
      ratePlanCodes,
    },
  });
}

export async function fetchRatePlanCategories(userLanguage) {
  return goodBitsClient().get("/masterdata/v2/rate-plan-categories", {
    params: {
      userLanguage,
    },
  });
}

export function fetchRatePlanPolicies(userLanguage) {
  return goodBitsClient().get("/masterdata/v2/rate-plan-policies", {
    params: {
      userLanguage,
    },
  });
}

export function fetchAddons(hotelIdOrCode, userLanguage, expands) {
  return fetchPropertyData(hotelIdOrCode, userLanguage, expands);
}

export async function fetchPropertyData(hotelIdOrCode, userLanguage, expands) {
  let expandSeries = expands.join(",");
  return await goodBitsClient().get(
    `/masterdata/v2/hotels/${hotelIdOrCode}?userLanguage=${userLanguage}&expand=${expandSeries}`,
  );
}

export async function fetchMultiplePropertyData(
  hotelCodes,
  userLanguage,
  expands,
) {
  const hotels = [];

  // Can NOT be this call since it does not deliver mediaAssets
  // return await goodBitsClient().get(`/masterdata/v2/hotels?userLanguage=${userLanguage}&hotelCodes=${hotelCodes.join()}`);

  async function fetchPropertyMasterData(hotelCode) {
    const propertyData = await fetchPropertyData(
      hotelCode,
      userLanguage,
      expands,
    );
    const hotelData = await propertyData;
    hotels[hotelCode] = {
      name: hotelData.data.name,
      img: hotelData.data.mainImage.file,
      media:
        [
          hotelData.data.mainImage.file,
          ...hotelData.data.mediaAssets.map((media) => media.file),
        ] || [],
      address: hotelData.data.location.googleMapsAddressQuery,
      checkInOut: hotelData.data.checkInCheckOutConfiguration,
      timeZone: hotelData.data.city?.timeZone?.identifier || null,
    };
    return hotelData;
  }
  async function loadMultipleProperties(hotelCodes) {
    const promises = hotelCodes.map(fetchPropertyMasterData);
    const responses = await Promise.all(promises);
    return responses;
  }

  await loadMultipleProperties(hotelCodes);

  return hotels;
}

export function fetchCountries(userLanguage, onlyWithHotels = true) {
  return goodBitsClient().get("/masterdata/v2/countries", {
    params: {
      userLanguage,
      onlyWithHotel: onlyWithHotels,
    },
  });
}

export function fetchLanguages(userLanguage) {
  return goodBitsClient().get("/masterdata/v3/configuration/languages", {
    params: {
      contentLanguage: userLanguage,
    },
  });
}

export async function fetchAllHotels(userLanguage) {
  return await goodBitsClient().get("/masterdata/v2/hotels", {
    params: {
      userLanguage,
    },
  });
}

export async function fetchHotel(hotelIdOrCode, userLanguage) {
  return await goodBitsClient().get(`/masterdata/v2/hotels/${hotelIdOrCode}`, {
    params: {
      userLanguage,
    },
  });
}

export async function fetchHotelsFromCity(cityId, userLanguage) {
  return await goodBitsClient().get(`/masterdata/v2/hotels/`, {
    params: {
      userLanguage,
      cityId,
    },
  });
}

export async function fetchFAQs(hotelIdOrCode, userLanguage) {
  return await goodBitsClient().get(
    `/masterdata/v2/hotels/${hotelIdOrCode}/faq`,
    {
      params: {
        userLanguage,
      },
    },
  );
}

export async function fetchHotelMeetingRooms(userLanguage, hotelCode) {
  return await goodBitsClient().get(`/masterdata/v3/properties/${hotelCode}`, {
    params: {
      contentLanguage: userLanguage,
      expand: "meetingSpace",
    },
  });
}
export async function getMasterdataV3(
  endpoint,
  userLanguage,
  expandParams = undefined,
  propertyCode = undefined,
) {
  const expand = expandParams ? `?&expand=${expandParams.join(",")}` : "";
  const url = `/masterdata/v3/${endpoint}${propertyCode ? `/${propertyCode}` : ""}${expand}`;
  return await goodBitsClient().get(url, {
    params: {
      contentLanguage: userLanguage,
    },
  });
}

export const loadContinentsWithAllProperties = async (
  _params,
  userLanguage,
  continent,
  country,
  marketingClient,
  contentfulClient,
  localePath,
  publicConfig,
) => {
  const locations = await getMasterdataV3("locations", userLanguage)
    .then((res) => res.data)
    .catch(() => null);

  if (locations) {
    const continents = locations.continents
      .filter(({ code }) => continent === undefined || continent === code)
      .filter(
        ({ countries }) =>
          country === undefined ||
          countries.some(({ code }) => code === country),
      )
      .sort((a) => {
        if (a.code == "EUROPE") return -1;
        if (a.code == "ASIA") return 1;
        return 0;
      })
      .map((continent) => ({
        ...continent,
        countries:
          country === undefined
            ? continent.countries
            : continent.countries.filter(({ code }) => code === country),
      }));

    return await flattenContinents(
      continents,
      userLanguage,
      marketingClient,
      contentfulClient,
      localePath,
      publicConfig,
    );
  }

  return [];
};

function buildHotelsSlugFromArray(slugArray) {
  const localePath = useLocalePath();
  return `${localePath("hotels")}/${slugArray?.join("/")}`;
}

// Utility functions to format data
const flattenContinents = async (
  continents,
  userLanguage,
  marketingClient,
  contentfulClient,
  publicConfig,
  localePath,
) => {
  return await Promise.all(
    continents.map(async (continent) => {
      const slug = await getContinentSlug(
        continent.contentId,
        userLanguage,
        contentfulClient,
      ).catch(() => "");

      const cities = await flattenCountriesToCities(
        continent.countries,
        userLanguage,
        marketingClient,
        contentfulClient,
        publicConfig,
        localePath,
      );
      return {
        id: continent.contentId,
        title: continent.title,
        cities,
        url: buildHotelsSlugFromArray(slug),
      };
    }),
  ).catch((e) => {
    console.log({ e });
  });
};

async function flattenCountriesToCities(
  countries,
  userLanguage,
  marketingClient,
  contentfulClient,
  publicConfig,
  localePath,
) {
  const flatCities = [];

  await Promise.allSettled(
    countries.map(async (country) => {
      await Promise.allSettled(
        country.cities.map(async (city) => {
          const slug = await getCitySlug(
            city.contentId,
            userLanguage,
            contentfulClient,
          ).catch(() => "");

          flatCities.push({
            id: city.contentId,
            code: city.code,
            title: city.title,
            list: await flattenProperties(
              city.properties,
              userLanguage,
              marketingClient,
              contentfulClient,
              publicConfig,
              localePath,
            ),
            url: buildHotelsSlugFromArray(slug),
            coordinates: city.coordinates,
            heroImage: city.heroImage,
          });
        }),
      );
    }),
  );

  return flatCities;
}

async function flattenProperties(
  properties,
  userLanguage,
  marketingClient,
  contentfulClient,
  publicConfig,
  localePath,
) {
  const flatProperties = [];

  await Promise.all(
    properties.map(async (property) => {
      const marketingHotels = await marketingClient.withAllLocales
        .getEntries({
          content_type: "hotel",
        })
        .then((res) => res.items)
        .catch(() => null);

      const slug = await buildSlugFromPropertyCode(
        property.code,
        marketingHotels,
        userLanguage,
        marketingClient,
        contentfulClient,
        publicConfig,
        localePath,
      ).catch(() => "");

      const content = await getContentForProperty(property.code, userLanguage);

      flatProperties.push({
        id: property.contentId,
        title: property.title,
        code: property.code,
        url: slug,
        content,
      });
    }),
  );
  return flatProperties;
}

/**
 * expand: Parameter to expand on certain topics, comma separated. Unknown expand parameters are quietly ignored.
   Available values : hotel, meetingSpace, coworking, bars, pools, art, genericFacilities, location
 * 
 */
export async function getContentForProperty(
  propertyCode,
  locale,
  expand = [
    "hotel",
    "meetingSpace",
    "coworking",
    "bars",
    "pools",
    "art",
    "genericFacilities",
    "location",
  ],
) {
  const property = await getMasterdataV3(
    "properties",
    localeToCfLocal(locale),
    expand,
    propertyCode,
  )
    .then((res) => res.data)
    .catch(() => null);

  return property;
}

export async function getContentForFeature(propertyCode, locale) {
  return await getMasterdataV3(
    "properties",
    locale,
    ["bars", "location"],
    propertyCode,
  )
    .then((res) => res.data)
    .catch(() => null);
}
