// TODO: clean this up everyhwere. This isn't a composable.
const localeLookupNuxt2Contentful = {
  // all other languages map 1:1 from nuxt to contentful
  zh: "zh-Hant",
  cn: "zh-Hans",
  it: "it-IT",
  es: "es-US",
};

export const cfLocaleToLocal = (cfLocale) => {
  const a = Object.keys(localeLookupNuxt2Contentful).filter((loc) => {
    return localeLookupNuxt2Contentful[loc] == cfLocale;
  });
  return a[0] || cfLocale;
};

export const localeToCfLocal = (locale) => {
  return localeLookupNuxt2Contentful[locale] || locale;
};
