import { getSlugForPagetype } from "~/services/slugService";

export async function getLanguageUrl(
  lang,
  pagetypeId,
  marketingClient,
  contentfulClient,
  publicConfig,
  localePath,
) {
  if (!marketingClient || !contentfulClient || !publicConfig || !localePath) {
    throw new Error(
      `Method "getLanguageUrl" is missing required parameters: ${!marketingClient ? "marketingClient" : ""} ${!contentfulClient ? "contentfulClient" : ""} ${!publicConfig ? "publicConfig" : ""} ${!localePath ? "localePath" : ""}`,
    );
  }

  const s = await getSlugForPagetype(
    pagetypeId,
    lang.shortCode,
    marketingClient,
    contentfulClient,
    publicConfig,
    localePath,
  );
  return { ...lang, slug: s };
}
