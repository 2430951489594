import type {
  NavigationGroup,
  PropertyBasedNavigationItem,
} from "~/shared/types/navigation";

export default function useHotels() {
  /**
   * Returns a list of hotels sorted by continents and cities.
   */
  const list = useState<NavigationGroup[]>("hotels", () => shallowRef([]));

  /**
   * Flatten all hotel properties
   */
  const hotelProperties = computed(() => {
    const result: any[] = [];

    for (const continent of list.value) {
      for (const city of continent.subNavigation) {
        if (city.subNavigation) {
          for (const property of city.subNavigation) {
            result.push(property);
          }
        }
      }
    }

    return result;
  });

  /**
   * Builds a navigation compatible object from hotel list
   * NOTE: this will be refactored in the near future (2025 - C1)
   */
  // TODO: add typing to entities
  const navigation = computed(() =>
    list.value.map((hotel: any) => ({
      cities: hotel.subNavigation?.map((city: any) => ({
        // TODO: add coords
        coordinates: {},
        id: city.sys?.id,
        list: city.subNavigation?.map((property: any) => ({
          id: property?.sys?.id,
          title: property.text,
          code: property.code,
          url: property?.slug?.value,
        })),
        title: city.text,
        url: city?.slug?.value,
      })),
      id: hotel?.sys?.id,
      title: hotel.text,
      url: hotel?.slug?.value,
    })),
  );

  /**
   * This is extremely inefficient. We should refactor to fetch all hotels from master data
   * and sort them based on continent in a computed property in stead of fetching them,
   * sorting them beforehand and then use this computed to de-sort them again
   */
  const mapMarkers = computed(() =>
    list.value
      .reduce<NavigationGroup[]>(
        (accNavigation, current) => [
          ...accNavigation,
          ...current?.subNavigation?.reduce(
            (accSubNavigation: any, city: any) => [
              ...accSubNavigation,
              ...city?.subNavigation,
            ],
            [],
          ),
        ],
        [],
      )
      .map((hotel: PropertyBasedNavigationItem) => ({
        name: hotel.text,
        url: hotel.slug?.value,
        lat: hotel.coordinates?.lat,
        lng: hotel.coordinates?.lon,
      })),
  );

  function hotelsByContinent(continent: string) {
    return list.value.find((navigationGroup) =>
      navigationGroup.slug.value.includes(continent),
    );
  }

  return {
    hotelsByContinent,
    list,
    hotelProperties,
    mapMarkers,
    navigation,
  };
}
