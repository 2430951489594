
import * as contentfulRuntime$i9YN8rifAUm4BRxjKj80WAZ1MVlnClk0isla8E7UJXg from '/home/vsts/work/1/s/node_modules/.pnpm/@nuxt+image@1.10.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/contentful'
import * as bynderRuntime$nL74dku_wznmOH_vW_45iaZ9Muo_45JXti09cA_459V5JGKpk from '/home/vsts/work/1/s/apps/lobby/providers/bynder.js'

export const imageOptions = {
  "screens": {
    "xs": 502,
    "sm": 768,
    "md": 992,
    "lg": 1080,
    "xl": 1200,
    "xxl": 1440,
    "2xl": 1536,
    "mic": 320
  },
  "presets": {
    "responsive": {
      "fit": "cover",
      "width": 1200
    },
    "fullimage": {
      "modifiers": {
        "fit": "cover",
        "width": 1200
      }
    },
    "bynder": {
      "modifiers": {
        "focuspoint": null
      },
      "format": "webp"
    }
  },
  "provider": "contentful",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['contentful']: { provider: contentfulRuntime$i9YN8rifAUm4BRxjKj80WAZ1MVlnClk0isla8E7UJXg, defaults: {} },
  ['bynder']: { provider: bynderRuntime$nL74dku_wznmOH_vW_45iaZ9Muo_45JXti09cA_459V5JGKpk, defaults: undefined }
}
        