import { defineStore } from "pinia";

const defaultTimeout = 4289;
const createSnack = (text, status, scope) => ({
  text,
  status,
  id: Math.random() * 1000,
  scope: scope || "global",
});

export const useSnackbarStore = defineStore("snackbar-store", {
  state: () => ({
    snacks: [],
  }),
  actions: {
    updateState(payload, status) {
      // Get text and timeout from payload
      const { text, timeout, scope, replace } = payload;

      const { removeMessage } = payload;
      if (removeMessage) this.removeSnack(removeMessage);

      // We create the toast with function above
      const snack = createSnack(text, status, scope);

      // Replace? => get rid of all messages so far
      if (replace) {
        this.removeScope(scope || "global");
      }

      // We push toasts to the state
      this.snacks.push(snack);

      // We create a delay to delete toast after its provided timeout is over
      if (timeout !== false) {
        setTimeout(() => {
          this.snacks = this.snacks.filter((t) => t.id !== snack.id);
        }, timeout ?? defaultTimeout);
      }

      const value = payload.value || `scope: ${scope}`;
      this.trackUiMessage({
        category: status,
        environment: "nuxt3",
        value,
        text,
      });

      return snack.id;
    },

    info(payload) {
      this.updateState(payload, "info");
    },

    success(payload) {
      this.updateState(payload, "success");
    },

    /**
     *
     * @param {{text: String, timeout: Number | Boolean, scope: String, replace?: Boolean, removeMessage?: String}} payload
     */
    warning(payload) {
      this.updateState(payload, "warning");
    },

    error(payload) {
      this.updateState(payload, "error");
    },

    // Remove a single snack
    removeSnack(snackId) {
      this.snacks = this.snacks.filter((t) => t.id !== snackId);
    },

    // Remove a complete scope of snacks
    removeScope(scope) {
      const remScope = scope || "global";
      this.snacks = this.snacks.filter((t) => t.scope !== remScope);
    },
    removeAllButGlobalScope() {
      this.snacks = this.snacks.filter((t) => t.scope !== "global");
    },

    removeAll() {
      this.snacks = [];
    },

    trackUiMessage(transactionData) {
      // Try to track the data – if not fail silently
      try {
        cmtr;
        cmtr.callDDM("ui_message", {
          ui_message: { ...transactionData, ...{ referrer: location.href } },
        });
      } catch (e) {
        // silently fail, tracking did not work out
      }
    },
  },
});
