export const validateEmail = (email: string) =>
  /^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-\.]*)[A-Z0-9_+-]@([A-Z0-9](?!.*--)[A-Z0-9\-]*[A-Z0-9]\.)+[A-Z]{2,}$/i.test(
    email,
  );

export const validatePhone = (phone: string) =>
  /^(?:\+|\b00)[1-9]\d{7,14}$/.test(phone);

export const validatePassword = (pwd: string) =>
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?!.* ).{8,20}$/.test(pwd); // 8-20 characters, at least one uppercase, one lowercase, one number, no spaces
