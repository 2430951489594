import { localeToCfLocal } from "~/composables/useLocales";
import { generateLocalePath } from "~/utils/localePath";

const homeSlug = "home";

const getPageType = (page) => {
  return page.fields?.pageType || null;
};

const urlBase = (locale) => {
  return locale != "en" ? "/" + locale : "";
};

export async function getLinkToPage(
  pageId,
  locale,
  marketingClient,
  contentfulClient,
  publicConfig,
  localePath,
) {
  if (!marketingClient || !contentfulClient || !publicConfig || !localePath) {
    throw new Error(
      `Method "getLinkToPage" is missing required parameters: ${!marketingClient ? "marketingClient" : ""} ${!contentfulClient ? "contentfulClient" : ""} ${!publicConfig ? "publicConfig" : ""} ${!localePath ? "localePath" : ""}`,
    );
  }

  try {
    const target = await marketingClient.getEntry(pageId, {
      locale: localeToCfLocal(locale),
    });

    if (!target) return null;

    // Check if this is an external Link
    if (target.sys.contentType.sys?.id == "externalUrl") {
      return {
        url: target.fields?.url,
        title: target.fields?.title,
        nofollow: target.fields?.nofollow,
        isExternal: true,
      };
    }
    // This is a page
    if (target.sys.contentType.sys?.id == "page") {
      // Generic URL builder
      const url = await getSlugForPagetype(
        getPageType(target).sys.id,
        locale,
        marketingClient,
        contentfulClient,
        publicConfig,
        localePath,
      );
      return {
        url,
        title: target.fields?.pageTitle,
      };
    }
  } catch {
    return {
      url: urlBase(locale),
      title: false,
    };
  }
}

export async function getSlugForPage(
  pageId,
  locale,
  marketingClient,
  contentfulClient,
  localePath,
) {
  if (!marketingClient || !contentfulClient || !localePath) {
    throw new Error(
      `Method "getSlugForPage" is missing required parameters: ${!marketingClient ? "marketingClient" : ""} ${!contentfulClient ? "contentfulClient" : ""} ${!localePath ? "localePath" : ""}`,
    );
  }

  try {
    const page = await marketingClient.getEntry(pageId, {
      locale: localeToCfLocal(locale),
    });

    if (!page) return null;

    // Generic URL builder
    return getSlugForPagetypeName(
      {
        pageType: getPageType(page),
        slug: page.fields?.pageType?.fields?.slug,
      },
      locale,
      contentfulClient,
      localePath,
    );
  } catch {
    return urlBase(locale);
  }
}

/**
 * @param pageTypeId either an cfId for a marketing pagetype or an object like {id: cfId, type: [type]}
 * @param locale target language for the slug
 * @returns {Promise<*|string|string>}
 */
export async function getSlugForPagetype(
  pageTypeId,
  locale,
  marketingClient,
  contentfulClient,
  publicConfig,
  localePath,
) {
  if (!marketingClient || !contentfulClient || !publicConfig || !localePath) {
    throw new Error(
      `Method "getSlugForPagetype" is missing required parameters: ${!marketingClient ? "marketingClient" : ""} ${!contentfulClient ? "contentfulClient" : ""} ${!publicConfig ? "publicConfig" : ""} ${!localePath ? "localePath" : ""}`,
    );
  }

  // Is this the home page
  if (pageTypeId == publicConfig.ctfHomepageId) {
    return localePath({ path: "/" }, locale);
  }
  // Is this the register page
  else if (pageTypeId == publicConfig.ctfRegisterId) {
    return localePath({ path: "/register" }, locale);
  } else if (pageTypeId.type === "masterPage") {
    return await contentfulClient
      .getEntry(pageTypeId.id, {
        include: 5,
        locale: localeToCfLocal(locale),
      })
      .then((loc) => {
        return locationSlugs(loc);
      })
      .then((slugs) => {
        return `${generateLocalePath("hotels", locale)}/${slugs.join("/")}`;
      });
  } else {
    const loc = localeToCfLocal(locale);

    return await marketingClient
      .getEntry(pageTypeId, { locale: loc })
      .then(async (pageType) => {
        // Deeper dig into location – if necessary
        if (pageType?.fields?.location?.sys) {
          const location = await contentfulClient.getEntry(
            pageType.fields?.location?.sys.id,
            {
              include: 3,
              locale: loc,
            },
          );

          if (location) pageType.fields.location = location;
        }
        return pageType;
      })
      .then(async (pageType) => {
        return pageType
          ? await getSlugForPagetypeName(
              { pageType, slug: pageType?.fields?.slug },
              locale,
              contentfulClient,
              localePath,
            )
          : null;
      })
      .catch((e) => {
        // console.log("no slug for found for locale", locale);
        // console.log({ e });
        return null;
      });
  }
}

export async function getSlugForMeetingRoomLocation(
  location,
  locale,
  localePath,
) {
  if (!localePath) {
    throw new Error(
      'No localePath provided to "getSlugForMeetingRoomLocation"',
    );
  }

  // TODO: adapt to new location model?!
  const urlBuilder = (slug, fixedPart) => {
    return (
      (fixedPart ? localePath(fixedPart, locale) : urlBase(locale)) +
      ("/" + slug || "")
    );
  };

  return urlBuilder(
    locationSlugs(location, [], true).join("/"),
    "/meeting-rooms",
  );
}

/**
 * return slugs for location content object
 *
 * @param {{}} location
 * @param {[]} slugs
 * @returns {[]}
 */
export const locationSlugs = (location, slugs = [], omitContinent = false) => {
  switch (location?.sys?.contentType?.sys.id) {
    case "address":
      return locationSlugs(location.fields.city, slugs); // Proceed to linked city object
      break;
    case "city-master":
      const citySlug = location.fields.slug;
      return omitContinent
        ? [citySlug, ...slugs]
        : locationSlugs(location.fields.country, [citySlug, ...slugs]); // Prepend and proceed to linked country object
    case "country":
      return locationSlugs(location.fields.continent, slugs); // We skip countries in URL
    case "continent-master":
      const continentSlug = location.fields.slug;
      return [continentSlug, ...slugs]; // We end here; prepend and return

    // These are legacy models ('OLD'), deprecated
    case "city":
      const citySlugOld = location.fields.name
        ?.toLowerCase()
        .replaceAll(" ", "-");
      return omitContinent
        ? [citySlugOld, ...slugs]
        : locationSlugs(location.fields.continent, [citySlugOld, ...slugs]); // Prepend and proceed to linked country object
    case "continent":
      const continentSlugOld = location.fields.name
        ?.toLowerCase()
        .replaceAll(" ", "-");
      return [continentSlugOld, ...slugs]; // We end here; prepend and return
    default:
      return [...slugs];
  }
};

const fixedSlug = (type) => {
  switch (type) {
    case "generic":
      return "services";
    case "Rooftopbar":
      return "rooftop-bars";
    case "Restaurant & bar":
    case "External":
      return "bars";
    default:
      return type;
  }
};

/**
 * generate a slug for a given pagetype in marketing
 *
 * @param {{pageType: object, slug: string, location: object}} params
 * @param {string} locale
 * @returns {Promise<string|string|*>}
 */
export const getSlugForPagetypeName = async (
  params,
  locale,
  contentfulClient,
  localePath,
) => {
  if (!contentfulClient || !localePath) {
    throw new Error(
      `Method "getSlugForPagetypeName" is missing required parameters: ${!contentfulClient ? "contentfulClient" : ""} ${!localePath ? "localePath" : ""}`,
    );
  }

  const { pageType, slug, location } = params;
  const pageTypeName = pageType?.sys.contentType?.sys.id;

  // Generic URL builder
  const urlBuilder = (slug, fixedPart) => {
    return (
      (fixedPart ? localePath(fixedPart, locale) : urlBase(locale)) +
      ("/" + slug || "")
    );
  };
  const pageSlug = pageType.fields?.slug;

  switch (pageTypeName) {
    case "contentCanvas":
      return urlBuilder(slug);
    // Fixed
    case "legal":
      return urlBuilder(slug, "/legal");
    case "blogArticle":
      const blogSlugs = locationSlugs(pageType.fields?.location, [], true);
      return urlBuilder(slug, `/blog/${blogSlugs.join("/")}`);
    case "hotelsOverview":
      if (location) {
        const locationSlug = locationSlugs(location);
        return urlBuilder(
          "",
          `${generateLocalePath("hotels/index", locale)}/${locationSlug.join("/")}`,
        );
      } else {
        const overviewLocation = await contentfulClient
          .getEntry(pageType.fields?.location?.sys.id, {
            locale: localeToCfLocal(locale),
            include: 10,
          })
          .catch((e) => {
            //console.log({ e });
          });
        if (overviewLocation) {
          return urlBuilder(
            "",
            `${generateLocalePath("hotels/index", locale)}/${locationSlugs(overviewLocation).join("/")}`,
          );
        }
      }
      return urlBuilder(
        "",
        `${generateLocalePath("hotels/index", locale)}/${slug}`,
      );
    case "hotel":
      // Render from slug + location
      const hotelLocation = await contentfulClient
        .getEntry(pageType.fields?.hotelMasterContent?.sys.id, {
          locale: localeToCfLocal(locale),
          include: 10,
        })
        .then((prop) => prop.fields?.location)
        .catch(() => null);
      const locationSlug = locationSlugs(hotelLocation);
      return urlBuilder(
        slug || pageSlug,
        `${generateLocalePath("hotels/index", locale)}/${locationSlug.join("/")}`,
      );

    // Hotel/property features
    case "propertyFeatures":
      // coworking or "bar"
      const featureSlug = pageType.fields?.slug;

      let fixedPart = "/";
      if (pageType.fields?.rooftopOrCoworking?.fields?.type === "Rooftopbar") {
        fixedPart = "/rooftop-bars";
      } else {
        fixedPart =
          "/" + pageType.fields?.rooftopOrCoworking?.sys.contentType.sys.id;
      }

      const featureLocationSlug = locationSlugs(location);

      return urlBuilder(
        featureSlug,
        `${fixedPart}/${featureLocationSlug.join("/")}`,
      );

    case "hotelFeature":
      const hotelFeatureSlug = pageType?.fields?.slug;
      const hotelFeatureLocationSlug = locationSlugs(location);

      return urlBuilder(
        hotelFeatureSlug,
        `/hotels/${hotelFeatureLocationSlug.join("/")}/${slug}`,
      );
    default:
      return urlBase(locale);
  }
};

/**
 * Build slug from a hotels propertyCode (in masterdata)
 *
 * @param {string} propertyCode
 * @param {string} locale
 * @returns {string|null}
 */
export const buildSlugFromPropertyCode = async (
  propertyCode,
  marketingHotels,
  locale,
  marketingClient,
  contentfulClient,
  publicConfig,
  localePath,
) => {
  if (!marketingClient || !contentfulClient || !publicConfig || !localePath) {
    throw new Error(
      `Method "buildSlugFromPropertyCode" is missing required parameters: ${!marketingClient ? "marketingClient" : ""} ${!contentfulClient ? "contentfulClient" : ""} ${!publicConfig ? "publicConfig" : ""} ${!localePath ? "localePath" : ""}`,
    );
  }

  if (!marketingHotels) return urlBase(locale);

  const propertyLoaded = marketingHotels.find(
    (hotel) =>
      (hotel.fields.slug?.[localeToCfLocal(locale)] &&
        hotel.fields.hotelMasterContent?.en?.fields?.code?.en ==
          propertyCode) ||
      false,
  );

  if (!propertyLoaded) return null;

  return await getSlugForPagetype(
    propertyLoaded.sys.id,
    locale,
    marketingClient,
    contentfulClient,
    publicConfig,
    localePath,
  ).catch(() => null);
};

/**
 * Given a pageType (mnarketing object) build the corresponding slug
 * try to use only existing data and only load if necessary
 *
 * @param {{}} target
 * @param {string} locale
 */
export const buildSlugForTarget = async (
  target,
  locale,
  marketingClient,
  contentfulClient,
  localePath,
) => {
  if (!marketingClient || !contentfulClient || !localePath) {
    throw new Error(
      `Method "buildSlugForTarget" is missing required parameters: ${!marketingClient ? "marketingClient" : ""} ${!contentfulClient ? "contentfulClient" : ""} ${!localePath ? "localePath" : ""}`,
    );
  }

  const pageType = getPageType(target);

  if (!pageType) {
    // Fetch target page from CF here!
    return await getSlugForPage(
      target.sys.id,
      locale,
      marketingClient,
      contentfulClient,
      localePath,
    );
  } else {
    const slug =
      pageType.fields?.slug != homeSlug ? pageType.fields?.slug || "" : "";
    return await getSlugForPagetypeName(
      { pageType, slug },
      locale,
      contentfulClient,
      localePath,
    );
  }
};
