import { i18nRoutes } from "~/i18n/routes.config";

export const generateLocalePath = (routeName, locale) => {
  const defaultLocale = "en";
  const routes = i18nRoutes[routeName] || i18nRoutes[`${routeName}/index`];
  if (!routes) {
    //throw new Error(`Route "${routeName}" not found in customRoutes.`);
    return `${locale !== defaultLocale ? "/" + locale : ""}/${routeName.replaceAll("/index", "")}`;
  }

  // Return the localized route or fallback to default
  if (locale === defaultLocale) {
    return routes[defaultLocale] || `/${routeName.replaceAll("/index", "")}`;
  }
  return routes[locale]
    ? `/${locale}${routes[locale]}`
    : `/${locale}${routes[defaultLocale] || `/${routeName.replaceAll("/index", "")}`}`;
};
