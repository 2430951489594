import { createAuth0 } from "@auth0/auth0-vue";
import axios from "axios";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const auth = createAuth0({
    domain: config.public.auth0Domain,
    clientId: config.public.auth0ClientId,
    authorizationParams: {
      redirect_uri: config.public.auth0Redirect,
    },
    cookieDomain: ".citizenm.com",
  });

  if (process.client) {
    nuxtApp.vueApp.use(auth);
  }

  auth.signup = async (params) => {
    try {
      const response = await axios.post(
        `https://${config.public.auth0Domain}/dbconnections/signup`,
        {
          clientId: config.public.auth0ClientId,
          connection: config.public.auth0Dbconnection,
          ...params,
        },
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  };

  return {
    provide: {
      auth: auth,
    },
  };
});
