import type { Stage } from "~/shared/types/env";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  function isStage(stages: Stage | string[]) {
    const stage = config?.public?.stage?.toLowerCase() || "";

    return stages.includes(stage);
  }

  function isStorybook(): boolean {
    const instance = getCurrentInstance();

    return (
      instance?.appContext?.config?.globalProperties?.$isStorybook || false
    );
  }

  function isDevelopment() {
    // NOTE: storybook might be crash while using useRuntimeConfig
    return isStorybook() || isStage(["local", "dev", "test", "acc"]);
  }

  function isProduction() {
    return isStage(["stg", "prod", "live"]);
  }

  function showDevMessage() {
    return isStorybook() || isStage(["local", "dev", "test", "acc", "stg"]);
  }

  return {
    provide: {
      isStage,
      isDev: isDevelopment(),
      isProd: isProduction(),
      isStorybook: isStorybook(),
      showDevMessage: showDevMessage(),
    },
  };
});
