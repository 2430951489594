import routerOptions0 from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.3.1_eslint@9.22.0_jiti@2.4_0a10e4b1150148363c7327f78562b5b8/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/vsts/work/1/s/apps/lobby/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}