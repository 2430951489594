export default defineNuxtRouteMiddleware((to) => {
  const snackbarStore = useSnackbarStore();
  const layoutStore = useLayoutStore();
  const { mobileDrawerOpen, drawerOpen, resetNavigationActiveState } =
    useNavigation();

  snackbarStore.removeAllButGlobalScope();

  mobileDrawerOpen.value = false;
  drawerOpen.value = false;
  resetNavigationActiveState();

  /**
   * TODO: remove this when Navigation is refactored
   *
   * SEE: https://citizenm.atlassian.net/browse/FOJO-2245
   */
  if (import.meta.client) {
    document.body.style.overflow = "auto";
  }

  if (to.path.includes("app")) {
    layoutStore.hideNavAndFooter();
  }
});
