/**
 * IMPORT TYPES
 * @typedef {import('~/types/travelData').TravelData} TravelData
 */

import { defineStore } from "pinia";
import { validateTravelDataInput } from "~/utils";

export const useTravelDataStore = defineStore("travelData", {
  persist: true,
  state: () => ({
    isAppFlow: false,
    searchEngineRate: undefined,
    currentStep: undefined,
    isShowMoreExpanded: false,
    hotelCodes: undefined,
    hotelName: undefined,
    cityId: undefined,
    arrivalDate: undefined,
    departureDate: undefined,
    totalGuests: undefined,
    numberOfRooms: undefined,
    promoCode: undefined,
    selectedRateName: undefined,
    promoCodeWasChanged: false,
    paymentType: undefined,

    bookingCode: undefined,

    selectedTaxViewOption: "",
    selectedPriceType: undefined,
    isIncludingTaxes: true,
    priceTypeTouched: false,

    country: "",
  }),
  getters: {
    /**
     * Returns the states as a TravelData Object
     * @return {TravelData}
     */
    getTravelDataObject() {
      return {
        isAppFlow: this.isAppFlow,
        searchEngineRate: this.searchEngineRate,
        currentStep: this.currentStep,
        isShowMoreExpanded: this.isShowMoreExpanded,
        hotelCodes: this.hotelCodes,
        hotelName: this.hotelName,
        arrivalDate: this.arrivalDate,
        departureDate: this.departureDate,
        totalGuests: this.totalGuests,
        numberOfRooms: this.numberOfRooms,
        promoCode: this.promoCode,
        selectedRateName: this.selectedRateName,
        promoCodeWasChanged: this.promoCodeWasChanged,
        paymentType: this.paymentType,
        bookingCode: this.bookingCode,
        selectedTaxViewOption: this.selectedTaxViewOption,
        selectedPriceType: this.selectedPriceType,
        isIncludingTaxes: this.isIncludingTaxes,
        priceTypeTouched: this.priceTypeTouched,
        country: this.country,
      };
    },
    isInsideABookingStep() {
      const currentStep = this.currentStep;

      const bookingSteps = [
        "rates",
        "addons",
        "details",
        "payment",
        "app/rates",
        "app/addons",
        "app/details",
        "app/payment",
      ];

      if (bookingSteps.includes(currentStep)) {
        return true;
      }

      return false;
    },
    actualBookingStep() {
      if (!this.currentStep && this.isDataValid) {
        return "book/rates";
      }

      if (this.currentStep === "rates") {
        return "book/addons";
      }

      if (this.currentStep === "addons") {
        return "book/details";
      }

      if (this.currentStep === "details") {
        return "book/payment";
      }

      if (this.currentStep === "app") {
        return "app/rates";
      }

      if (this.currentStep === "app/rates") {
        return "app/addons";
      }

      if (this.currentStep === "app/addons") {
        return "app/details";
      }

      if (this.currentStep === "app/details") {
        return "app/payment";
      }

      return "";
    },

    isDataValid(state) {
      try {
        validateTravelDataInput(state);
        return true;
      } catch (e) {
        return false;
      }
    },
    selectedPriceTypeStr(state) {
      switch (state.selectedPriceType) {
        case "pricePerNightInclTax":
          return "website.general.booking.room_from_night_i_tax";

        case "pricePerNightExclTax":
          return "website.general.booking.room_from_night_e_tax";

        case "pricePerStayInclTax":
          return "website.general.booking.room_from_stay_i_tax";

        case "pricePerStayExclTax":
          return "website.general.booking.room_from_stay_e_tax";

        default:
          return "website.general.booking.room_from_night_i_tax";
      }
    },
    requestedData(state) {
      return {
        arrival: state.arrivalDate,
        departure: state.departureDate,
        rooms: state.numberOfRooms,
        adults: state.totalGuests,
      };
    },
    getDataForQuery(state) {
      const outputDate = (date) => {
        let year = date.getFullYear();
        let month = date.getMonth() + 1;

        let startDay = date.getDate();

        month = ("0" + month).slice(-2); // Leading zeros
        startDay = ("0" + startDay).slice(-2);

        return `${year}-${month}-${startDay}`;
      };

      return {
        arrivalDate: outputDate(new Date(state.arrivalDate)),
        departureDate: outputDate(new Date(state.departureDate)),
        numberOfRooms: state.numberOfRooms,
        totalGuests: state.totalGuests,
      };
    },
    getNightsOfStay(state) {
      const start = new Date(state.arrivalDate);
      const end = new Date(state.departureDate);

      const timeDifference = end - start;

      const nights = Math.ceil(timeDifference / (1000 * 3600 * 24));

      return nights > 0 ? nights : 0;
    },
  },
  actions: {
    setCurrentStep(step) {
      this.currentStep = step;
    },

    resetTravelData() {
      this.$reset();
    },

    resetTravelDataApp() {
      this.$reset();
      this.currentStep = "app";
      this.userFromApp = true;
    },

    selectPriceType(priceType, isUserAction = true) {
      this.selectedPriceType = priceType;

      switch (priceType) {
        case "pricePerNightInclTax":
        case "pricePerStayInclTax":
          this.selectedTaxViewOption = "all taxes included";
          this.isIncludingTaxes = true;
          return;
        case "pricePerNightExclTax":
        case "pricePerStayExclTax":
          this.selectedTaxViewOption = "not all taxes included in rate";
          this.isIncludingTaxes = false;
          return;
      }

      if (isUserAction) {
        this.priceTypeTouched = true;
      }
    },
    setTravelInformations(hotelRooms) {
      this.arrivalDate = hotelRooms[0].arrival;
      this.departureDate = hotelRooms[0].departure;
      this.numberOfRooms = hotelRooms.length;
      this.totalGuests = hotelRooms[0].guestsPerRoom;
      this.hotelCodes = hotelRooms[0].hotelCode;
    },
    setBookingCode(bookingCode) {
      this.bookingCode = bookingCode;
    },
    setCartId(cartId) {
      this.cartId = cartId;
    },

    setInitialData() {
      this.currentStep = undefined;
      this.arrivalDate = new Date();
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      this.departureDate = tomorrow;
      this.totalGuests = 1;
      this.numberOfRooms = 1;
    },

    changeToLocationId(locationId) {
      this.hotelCodes = locationId;
    },

    checkTraveldata() {
      const today = new Date();
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      if (
        Number.isNaN(new Date(this.arrivalDate).valueOf()) ||
        new Date(this.arrivalDate) <= today
      ) {
        this.arrivalDate = today;
      }

      if (
        Number.isNaN(new Date(this.departureDate).valueOf()) ||
        new Date(this.departureDate) <= tomorrow
      ) {
        this.departureDate = tomorrow;
      }

      /*
        TODO
        Would be nice to add a this.guestsPerRoom property to the store so we
        dont do calculations on the fly for the backend.
        This should be used to make sure that the total number of guests is at least the total
        number of rooms. This is not needed for now, but it might be needed in the future.

        // this.guestsPerRoom = Math.max(this.totalGuests, this.numberOfRooms);
      */

      this.totalGuests = this.totalGuests || 1;
      this.numberOfRooms = this.numberOfRooms || 1;
    },
  },
});
