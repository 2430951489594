export function setUpConfetti(fullscreen) {
  if (!document.querySelector(".confetti-wrapper")) {
    return;
  }

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const wrapper = document.querySelector(".confetti-wrapper");

  let width = wrapper.clientWidth;
  let height = wrapper.clientHeight;
  if (fullscreen) {
    width = window.innerWidth;
    height = window.innerHeight;
  }
  canvas.width = width;
  canvas.height = height;

  let canvasX = context.canvas.width / 2;
  let canvasY = context.canvas.height / 2;

  canvas.id = "particel-canvas";

  canvas.style.position = "absolute";
  canvas.style.top = "0";
  canvas.style.left = "0";
  canvas.style.pointerEvents = "none";

  wrapper.appendChild(canvas);

  const confetti = [];
  let confettiCount = 120;
  if (width > 1080) {
    confettiCount = 480;
  } else if (width > 760) {
    confettiCount = 320;
  } else if (width > 520) {
    confettiCount = 240;
  }

  const gravity = 0.5;
  const terminalVelocity = 5;
  const drag = 0.075;
  const colors = [
    {
      front: "#FF0038",
      back: "#76001a",
    },
    {
      front: "#03D386",
      back: "#028655",
    },
    {
      front: "#00AFEF",
      back: "#00678d",
    },
    {
      front: "#FF7219",
      back: "#7b3000",
    },
    {
      front: "#F9BA02",
      back: "#987101",
    },
    {
      front: "#B8B8B8",
      back: "#5A5A5A",
    },
  ];

  function resizeCanvas() {
    let style = getComputedStyle(wrapper);
    width = parseInt(style["width"]);
    height = parseInt(style["height"]);
    canvas.width = width;
    canvas.height = height;
    canvasX = context.canvas.width / 2;
    canvasY = context.canvas.height / 2;
  }

  function randomRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  function initializeConfetti() {
    resizeCanvas();
    for (let i = 0; i < confettiCount; i++) {
      confetti.push({
        color: colors[Math.floor(randomRange(0, colors.length))],
        dimensions: {
          x: randomRange(7, 14),
          y: randomRange(7, 21),
        },
        position: {
          x: randomRange(0, canvas.width),
          y: canvas.height - 1,
        },
        rotation: randomRange(0, 2 * Math.PI),
        scale: {
          x: 1,
          y: 1,
        },
        velocity: {
          x: randomRange(-25, 25),
          y: randomRange(0, -50),
        },
      });
    }
  }

  function render() {
    context.clearRect(0, 0, canvas.width, canvas.height);

    confetti.forEach((confetto, index) => {
      const width = confetto.dimensions.x * confetto.scale.x;
      const height = confetto.dimensions.y * confetto.scale.y;

      context.translate(confetto.position.x, confetto.position.y);
      context.rotate(confetto.rotation);

      confetto.velocity.x -= confetto.velocity.x * drag;
      confetto.velocity.y = Math.min(
        confetto.velocity.y + gravity,
        terminalVelocity,
      );
      confetto.velocity.x +=
        Math.random() > 0.5 ? Math.random() : -Math.random();

      confetto.position.x += confetto.velocity.x;
      confetto.position.y += confetto.velocity.y;

      if (confetto.position.y >= canvas.height) {
        confetti.splice(index, 1);
      }

      if (confetto.position.x > canvas.width) {
        confetto.position.x = 0;
      }

      if (confetto.position.x < 0) {
        confetto.positionx = canvas.width;
      }

      confetto.scale.y = Math.cos(confetto.position.y * 0.1);
      context.fillStyle =
        confetto.scale.y > 0 ? confetto.color.front : confetto.color.back;

      context.fillRect(-width / 2, -height / 2, width, height);
      context.setTransform(1, 0, 0, 1, 0, 0);
    });

    window.requestAnimationFrame(render);
  }

  initializeConfetti();
  render();

  window.addEventListener("resize", () => {
    resizeCanvas();
  });
}
