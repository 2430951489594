export const CONTENTFUL_SPACE = {
  Marketing: "marketing",
  Product: "product",
} as const;

export const CONTENTFUL_LOCALE = {
  cn: "zh-Hans",
  en: "en",
  es: "es-US",
  fr: "fr",
  it: "it-IT",
  nl: "nl",
  zh: "zh-Hant",
} as const;
