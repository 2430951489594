import { nanoid } from "nanoid";

export const validateTravelDataInput = (params) => {
  const validationRules = {
    hotelCodes: {
      required: true,
      type: "string",
      alternateNames: ["destination_raw", "location", "destination"],
    },
    arrivalDate: {
      required: true,
      type: "time",
      alternateNames: ["startdate"],
    },
    departureDate: {
      required: true,
      type: "time",
      alternateNames: ["enddate"],
    },
    totalGuests: {
      required: true,
      type: "number",
      alternateNames: ["count_guests"],
      default: 1,
    },
    numberOfRooms: {
      required: true,
      type: "number",
      alternateNames: ["count_rooms"],
      default: 1,
    },
    promoCode: {
      required: false,
      type: "string",
      alternateNames: [],
    },
  };

  const validatedParams = {};
  let dataValid = true;

  for (const key in validationRules) {
    const rule = validationRules[key];
    let value = params[key] || null;
    if (!value && rule.alternateNames) {
      rule.alternateNames.forEach((alt) => {
        value = params[alt] ?? value;
      });
    }
    if (!value && rule.default) {
      value = rule.default;
    }
    if (!rule.required || (rule.required && value)) {
      validatedParams[key] = value;
    } else {
      dataValid = false;
      break;
    }
  }

  if (Object.keys(validatedParams).length > 0 && dataValid) {
    return validatedParams;
  } else {
    throw new Error("Please provide all required parameters");
  }
};

export const cfLocation = (locale) => {
  switch (locale) {
    case "zh":
    case "cn":
      return "zh-Hant";
    case "it":
      return "it-IT";
    case "es":
      return "es-US";
    default:
      return locale;
  }
};

export const generateRandomHexString = (length) => {
  return nanoid(length).toLowerCase().replaceAll("_", "-");
};
