import { useSnackbarStore } from "~/stores/snackbarStore";

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) {
    return;
  }
  if (from.query.message || to.query.message) {
    const { $i18n } = useNuxtApp();
    const snackbar = useSnackbarStore();

    snackbar.error({
      text: $i18n.t(`website.error.${to.query.message}`),
      timeout: false,
    });
  }
});
