// Vendor
import type { CustomRoutePages } from "@nuxtjs/i18n";

export const i18nRoutes: CustomRoutePages = {
  // 'book': {
  // },
  // 'get-booking': {
  // },
  "get-booking/reservations": {
    nl: "/get-booking/reserveringen",
    //fr: '/get-booking/reservations',
    it: "/get-booking/prenotaziones",
    es: "/get-booking/reservas",
  },
  "get-booking/reservation": {
    nl: "/get-booking/reservering",
    it: "/get-booking/prenotazione",
    es: "/get-booking/reserva",
  },
  "get-booking/reservation-payment": {
    nl: "/get-booking/reservering-payment",
    it: "/get-booking/prenotazione-payment",
    es: "/get-booking/reserva-payment",
  },
  // 'my/dashboard': {
  // },
  "my/profile": {
    nl: "/my/mijn-profiel",
    fr: "/my/mon-profil",
    it: "/my/il-mio-profilo",
    es: "/my/mi-perfil",
  },
  "my/reservations": {
    nl: "/my/mijn-reserveringen",
    fr: "/my/mes-reservations",
    it: "/my/le-mie-prenotazioni",
    es: "/my/mis-reservas",
  },
  "my/reservation-[reservationId]": {
    nl: "/my/mijn-reservering-[reservationId]",
    fr: "/my/ma-reservation-[reservationId]",
    it: "/my/la-mia-prenotazione-[reservationId]",
    es: "/my/mi-reserva-[reservationId]",
  },
  "my/booking-[reservationId]": {
    nl: "/my/mijn-boeking-[reservationId]",
  },
  // 'my/meetings': {
  // },
  "my/membership": {
    nl: "/my/lidmaatschap",
    fr: "/my/abonnement",
  },
  // Fixed
  // 'legal' : {}
  // Fixed + location
  "hotels/index": {
    es: "/hoteles",
  },
  "hotels/[continent]/index": {
    es: "/hoteles/[continent]",
  },
  "hotels/[continent]/[city]/index": {
    es: "/hoteles/[continent]/[city]",
  },
  "hotels/[continent]/[city]/[hotelSlug]/index": {
    es: "/hoteles/[continent]/[city]/[hotelSlug]",
  },
  "hotels/[continent]/[city]/[hotelSlug]/[featureSlug]": {
    es: "/hoteles/[continent]/[city]/[hotelSlug]/[featureSlug]",
  },

  register: {
    nl: "/inschrijven",
    fr: "/souscription",
    it: "/registro",
  },
};
