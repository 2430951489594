import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoPaymentAmex, LazySvgoPaymentJcb, LazySvgoPaymentMastercard, LazySvgoPaymentUnionpay, LazySvgoPaymentUnknown, LazySvgoPaymentVisa } from '#components'
const lazyGlobalComponents = [
  ["SvgoPaymentAmex", LazySvgoPaymentAmex],
["SvgoPaymentJcb", LazySvgoPaymentJcb],
["SvgoPaymentMastercard", LazySvgoPaymentMastercard],
["SvgoPaymentUnionpay", LazySvgoPaymentUnionpay],
["SvgoPaymentUnknown", LazySvgoPaymentUnknown],
["SvgoPaymentVisa", LazySvgoPaymentVisa],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
