import type { RouterConfig } from "@nuxt/schema";

export default <RouterConfig>{
  scrollBehavior(to, _from, savedPosition) {
    if (to.hash) {
      const navigationHeight =
        document.querySelector(".navigation")?.getBoundingClientRect().height ||
        80;

      return {
        el: to.hash,
        behavior: "smooth",
        top: navigationHeight + 16, // + safety spacing
      };
    } else if (savedPosition) {
      return { ...savedPosition, behavior: "smooth" };
    }

    return { top: 0, left: 0, behavior: "smooth" };
  },
};
