import { goodBitsClient } from "~/services/httpService";
import {
  fetchMultiplePropertyData,
  fetchPropertyData,
} from "~/services/masterdataService";
import { useUserStore } from "~/stores/userStore";
import {
  getBookingInfo,
  getReservationInfo,
} from "~/services/bookingEngineService";

export function loadUserReservations() {
  return goodBitsClient().get("/profiles/v1/profile/bookings");
}

export async function loadReservationInfo(reservationId, locale, t) {
  let reservation = {};
  const id = reservationId.split("-");
  const segment = id[1] || 1;
  const booking = await loadBookingInfo(id[0], locale, t);
  if (booking.hasOwnProperty("reservations")) {
    booking.reservations = await Promise.all(
      booking.reservations.map(async (res) => {
        if (res.reservationCode == reservationId) {
          const userStore = useUserStore();
          reservation = res;
          // Enrich reservation
          if (!res.hasOwnProperty("primaryGuest")) {
            const response = await getReservationInfo(
              res.reservationCode,
              userStore.auth0token,
            );
            reservation = { ...res, ...response.data };
          }
          return reservation;
        }
        return res;
      }),
    );
  } else {
    // Load directly
    const response = await getReservationInfo(reservationId);
    reservation = response.data;
  }
  reservation.isLoaded = true;
  return reservation;
}

export async function loadBookingInfo(reservationId, locale, t) {
  let reservation = {};

  // Check user store for the reservation
  const userStore = useUserStore();
  const bookings = await userStore.loadUserStays(locale, t, false);
  await Promise.all(
    bookings.map(async (booking) => {
      booking.stays = await Promise.all(
        booking.stays.map(async (stay) => {
          if (stay.confirmation == reservationId) {
            reservation = stay;
            // Load details if necessary
            if (!stay.hasOwnProperty("bookingCode") || booking.isBooker) {
              const response = await getBookingInfo(reservationId);
              // prepare data for return
              reservation = { ...stay, ...response.data };
            }
            // Update the store also
            return reservation;
          }
          return stay;
        }),
      );
    }),
  );
  return reservation;
}

export async function getUpcomingReservation(locale, t) {
  const userStore = useUserStore();
  const reservations = await userStore.loadUserStays(locale, t, ["confirmed"]);
  let nextStay = {};
  const stays = reservations[0]?.stays || false;
  if (stays) {
    nextStay.departure = stays[0].departure; // today
    stays.map((stay) => {
      if (stay.departure <= nextStay.departure) {
        nextStay = stay;
      }
    });
  }
  return Object.keys(nextStay).length ? nextStay : null;
}

export async function groupReservationsByStatus(
  loadedReservations,
  locale,
  t,
  limitCount,
) {
  // Store the shown hotelcodes to load details later on
  let hotels = {};
  const localePath = useLocalePath();

  // general ORDER for output here
  const stays = {
    inhouse: [],
    confirmed: [],
    checkedout: [],
    noshow: [],
    canceled: [],
    unknown: [],
  };

  const headerWithCountStatus = ["inhouse", "confirmed", "checkedout"];

  // [Optional] group states together
  const statusCollection = {
    // possible states: confirmed, inhouse, checkedout, canceled, noshow, unknown
    // noshow: 'checkedout',
    // canceled: 'checkedout',
  };

  // Load necessary hotel data
  loadedReservations.bookings
    .filter((booking) => booking.reservations[0])
    .map((booking) => {
      const reservationZero = booking.reservations[0];
      let status = booking.reservations
        .reduce((stats, reservation) => {
          switch (stats) {
            case "inHouse":
            case "confirmed":
              return stats;
            default:
              return reservation?.status;
          }
        }, null)
        ?.toLowerCase();
      reservationZero.status?.toLowerCase();
      if (status === null) status = "unknown"; // Null equals unknown
      if (statusCollection[status]) status = statusCollection[status];
      // Only add if needed
      if (stays[status]) {
        stays[status].push(booking);
        let hotelCode = reservationZero.hotelCode;

        // fill up the hotels
        if (reservationZero.hotelCode == "LAB") {
          hotelCode = "RIT";
          reservationZero.hotelCode = hotelCode;
        }

        if (!hotels[hotelCode]) {
          hotels[hotelCode] = {};
        }
      }
    });

  hotels = await fetchMultiplePropertyData(
    Object.keys(hotels).map((hotelCode) => hotelCode),
    localeToCfLocal(locale.value),
    ["location", "checkInCheckOutConfiguration", "mediaAssets", "city"],
  );

  // Clean up the stays - remove unused status
  Object.keys(stays).map((key) => {
    if (stays[key].length === 0) delete stays[key];
  });

  const reservations = Object.keys(stays).map((status) => {
    const group = {
      title: t(`website.mycitizenm.reservation_status_${status}`),
      status: status,
      count: stays[status].length,
      show: 8,
      stays: [
        ...stays[status].map((booking) => {
          const reservationZero = booking.reservations[0];

          let detailUrl = localePath(`/my/booking-${booking.bookingCode}`);
          if (booking.reservations.length === 1 || !booking.isBooker) {
            detailUrl = localePath(
              `/my/reservation-${reservationZero.reservationCode}`,
            );
          }

          const stay = {
            confirmation: booking.bookingCode,
            hotelCode: reservationZero.hotelCode,
            hotel: hotels[reservationZero.hotelCode]?.name || "noName",
            img: hotels[reservationZero.hotelCode]?.img || "",
            media: hotels[reservationZero.hotelCode]?.media || [],
            address: hotels[reservationZero.hotelCode]?.address || "",
            arrival: reservationZero.arrival,
            departure: reservationZero.departure,
            timeZone: hotels[reservationZero.hotelCode]?.timeZone,
            guestsPerRoom: reservationZero.adults,
            rooms: booking.reservations.length,
            url: detailUrl,
          };
          return stay;
        }),
      ],
    };

    // Sort stays by arrival date
    group.stays.sort((a, b) =>
      a.arrival <= b.arrival ? (a.departure < b.departure ? 1 : -1) : -1,
    );
    if (status == "confirmed") {
      group.stays.reverse();
    }

    return group;
  });

  return reservations || [];
}

export async function getRoomUpgradesForReservation(reservationId) {
  return await goodBitsClient().get(
    `/reservations/v2/reservations/${reservationId}/available-rooms`,
  );
}

export async function addReservationPerson(
  reservationId,
  role,
  override,
  person,
) {
  return await goodBitsClient().post(
    `/my-reservation-bff/v1/reservations/${reservationId}/guests/${override}`,
    person,
    {
      params: {
        notifyRoles: role,
      },
    },
  );
}
export async function updateReservationPerson(
  reservationId,
  role,
  override,
  person,
) {
  return await goodBitsClient().put(
    `/my-reservation-bff/v1/reservations/${reservationId}/guests/${override}`,
    person,
    {
      params: {
        notifyRoles: role,
      },
    },
  );
}
export async function deleteReservationAdditionalguest(
  reservationId,
  role,
  override,
) {
  return await goodBitsClient().delete(
    `/my-reservation-bff/v1/reservations/${reservationId}/guests/${override}`,
  );
}

export async function getModificationAvailability(reservationId, datesPayload) {
  return await goodBitsClient().get(
    `/my-reservation-bff/v1/reservations/${reservationId}/offers`,
    { params: datesPayload },
  );
}
export async function updateReservationDates(reservationId, datesPayload) {
  return await goodBitsClient().put(
    `/my-reservation-bff/v1/reservations/${reservationId}/actions/amend`,
    datesPayload,
    {
      params: {
        notifyRoles: "booker,primaryGuest",
      },
    },
  );
}

export async function getReservationInvoices(reservationId) {
  return await goodBitsClient().get(
    `/my-reservation-bff/v1/reservations/${reservationId}/invoices`,
  );
}
export async function loadReservationInvoice(reservationId, invoiceId) {
  return await goodBitsClient().get(
    `/my-reservation-bff/v1/reservations/${reservationId}/invoices/${invoiceId}?expand=pdf`,
  );
}

export async function getReservationInvoicesWithToken(reservationId, token) {
  return await goodBitsClient().get(
    `/my-reservation-bff/v1/reservations/${reservationId}/invoices`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        expand: "pdf",
      },
    },
  );
}
export async function getReservationInvoiceWithToken(
  reservationId,
  invoiceId,
  token,
) {
  return await goodBitsClient().get(
    `/my-reservation-bff/v1/reservations/${reservationId}/invoices/${invoiceId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        expand: "pdf",
      },
    },
  );
}

export async function getModificationAvailabilityWithToken(
  reservationId,
  token,
  datesPayload,
) {
  return await goodBitsClient().get(
    `/my-reservation-bff/v1/reservations/${reservationId}/offers`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: datesPayload,
    },
  );
}
export async function updateReservationDatesWithToken(
  reservationId,
  token,
  datesPayload,
) {
  return await goodBitsClient().put(
    `/my-reservation-bff/v1/reservations/${reservationId}/actions/amend`,
    datesPayload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        notifyRoles: "booker,primaryGuest",
      },
    },
  );
}

export async function cancelReservation(reservationCode) {
  return await goodBitsClient().put(
    `/my-reservation-bff/v1/reservations/${reservationCode}/actions/cancel`,
  );
}

export async function cancelReservationWithToken(reservationCode, token) {
  return await goodBitsClient({ otherToken: token }).put(
    `/my-reservation-bff/v1/reservations/${reservationCode}/actions/cancel`,
  );
}

export async function addExtraService(reservationCode, serviceId, dates) {
  return await goodBitsClient().post(
    `/my-reservation-bff/v1/reservations/${reservationCode}/services`,
    {
      serviceId,
      dates,
    },
  );
}

export async function deleteExtraService(reservationCode, serviceId) {
  return await goodBitsClient().delete(
    `/my-reservation-bff/v1/reservations/${reservationCode}/services/${serviceId}`,
  );
}

export async function addExtraServiceWithToken(
  reservationCode,
  serviceId,
  dates,
  token,
) {
  return await goodBitsClient({ otherToken: token }).post(
    `/my-reservation-bff/v1/reservations/${reservationCode}/services`,
    {
      serviceId,
      dates,
    },
  );
}

export async function deleteExtraServiceWithToken(
  reservationCode,
  serviceId,
  token,
) {
  return await goodBitsClient({ otherToken: token }).delete(
    `/my-reservation-bff/v1/reservations/${reservationCode}/services/${serviceId}`,
  );
}
