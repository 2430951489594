/**
 * @typedef {Object} Attendee
 * @property {TouchedInput} firstName
 * @property {TouchedInput} lastName
 * @property {TouchedInput} email
 */

import { defineStore } from "pinia";
import { getProfile, getBookings } from "~/services/profileService";
import { groupReservationsByStatus } from "~/services/reservationService";
import { getSmbSubscription } from "~/services/subscriptionService";

// Constants
import { COOKIE_ID_TOKEN } from "~/shared/constants";

const ttlHours = 2;

const initialState = {
  auth0token: null, // Used to store the auth0 user data
  profileId: null,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  taxNumber: "",
  homeAddress: {
    address: "",
    postalCode: "",
    city: "",
    countryCode: "",
    country: "",
  },
  invoiceAddress: {
    address: "",
    postalCode: "",
    city: "",
    countryCode: "",
    companyName: "",
    state: "",
  },
  isProfileComplete: false,
  consent: {},
  memberShip: {
    hasMembership: false,
    paymentRefNumber: "",
    memberShipName: "citizenm+",
    pricingYearly: [],
    annualSubscription: [],
    tierSubscription: {
      tier_3: [],
      tier_6: [],
      tier_10: [],
      tier_25: [],
    },
    selectedPlan: "mycitizenm+annual",
    selectedPlanTier: "",
    isSMB: false,
    businessAccount: null,
    businessAccountCode: "",
    promotionCode: "",
    promotionCodeHardcoded: "",
    prefillOfferCoupon: "",
    isPromotionCodeValid: false,
    selectedCurrency: "EUR",
    termsAndConditions: "",
    inviteCount: 0,
  },
  reservations: [],
  notification_language: null,
  room_temperature: null,
  subscriptionBenefits: null,
};

// Array to deep copy override the initial objects
const initialStateObjects = [
  "homeAddress",
  "invoiceAddress",
  "consent",
  "memberShip",
];

// Deep clone of the initial state
const cloneState = { ...initialState };
initialStateObjects.forEach((obj) => {
  cloneState[obj] = { ...initialState[obj] };
});

export const useUserStore = defineStore("user", {
  persist: true,
  state: () => ({
    loading: false,
    ttl: null,
    ttlStays: null,
    ...initialState,
  }),
  getters: {
    isUser(state) {
      return state.profileId || false;
    },
    memberShipPricePerYear(state) {
      return Number((state.memberShip.priceYearly * 12).toFixed(2));
    },
    totalPrice(state) {
      switch (state.memberShip.selectedPlan) {
        case "mycitizenm+annual":
          const relevantYearlyPricing = state.memberShip.pricingYearly.find(
            (pricing) =>
              pricing.currencyCode === state.memberShip.selectedCurrency,
          );
          return relevantYearlyPricing ? relevantYearlyPricing.totalPrice : 0;
        default:
          if (state.memberShip.selectedPlanTier) {
            return this.totalPriceSMB;
          }
          return 0;
      }
    },
    priceSMBPerSeat(state) {
      switch (state.memberShip.selectedPlanTier) {
        case "tier_3":
        case "tier_6":
        case "tier_10":
        case "tier_25":
          return (
            state.memberShip.tierSubscription[
              state.memberShip.selectedPlanTier
            ]?.find(
              (item) =>
                item.totalAmount.currency === state.memberShip.selectedCurrency,
            )?.totalAmount?.totalGrossAmount || 0
          );
        default:
          return 0;
      }
    },
    isAllInviteSent(state) {
      switch (state.memberShip.selectedPlanTier) {
        case "tier_3":
          return state.memberShip.inviteCount >= 2;
        case "tier_6":
          return state.memberShip.inviteCount >= 5;
        case "tier_10":
          return state.memberShip.inviteCount >= 9;
        case "tier_25":
          return state.memberShip.inviteCount >= 24;
        default:
          return false;
      }
    },
    totalPriceSMB(state) {
      switch (state.memberShip.selectedPlanTier) {
        case "tier_3":
        case "tier_6":
        case "tier_10":
        case "tier_25":
          return this.priceSMBPerSeat;
        default:
          return 0;
      }
    },
    getName() {
      return `${this.firstName} ${this.lastName}`;
    },
    redirect() {
      return this.redirectRoute;
    },
    isTtlOver() {
      return this.ttl < new Date();
    },
    isTtlStaysOver() {
      return this.ttlStays < new Date();
    },
    tierSubscriptions() {
      return [
        ...this.memberShip.tierSubscription.tier_3,
        ...this.memberShip.tierSubscription.tier_6,
        ...this.memberShip.tierSubscription.tier_10,
        ...this.memberShip.tierSubscription.tier_25,
      ];
    },
    getUserType() {
      // consumer / business / employee / subscription_plus
      if (this.memberShip.hasMembership) {
        return "subscription_plus";
      }
      // Other cases? Ho wto tell?
      return "consumer";
    },
  },
  actions: {
    async forceUpdateUser() {
      this.ttl = null;
      this.ttlStays = null;
      await this.loginUser(this.auth0token);
    },
    async loginUser(accessTokenResponse) {
      try {
        if (accessTokenResponse) {
          this.auth0token = accessTokenResponse;
        } else {
          const auth = useNuxtApp().$auth;
          const { getAccessTokenSilently } = auth;
          accessTokenResponse = await getAccessTokenSilently();
          this.auth0token =
            accessTokenResponse?.id_token ?? accessTokenResponse;
        }

        if ((this.isTtlOver || !this.profileId) && accessTokenResponse) {
          const profileInformation = await getProfile()
            .then((response) => response.data)
            .catch((error) => {
              // throw error;
            });
          if (profileInformation) {
            this.isProfileComplete = profileInformation.isComplete;
            this.profileId = profileInformation.id;
            this.firstName = profileInformation.firstName;
            this.lastName = profileInformation.lastName;
            this.email = profileInformation.primaryEmail;
            this.phoneNumber = profileInformation.primaryPhoneNumber;
            this.homeAddress = {
              address: profileInformation.homeAddress?.addressLine1 || "",
              city: profileInformation.homeAddress?.city || "",
              postalCode: profileInformation.homeAddress?.postalCode || "",
              countryCode: profileInformation.homeAddress?.countryCode || "",
            };
            this.invoiceAddress = {
              companyName: profileInformation.invoiceAddress?.companyName || "",
              address: profileInformation.invoiceAddress?.addressLine1 || "",
              city: profileInformation.invoiceAddress?.city || "",
              postalCode: profileInformation.invoiceAddress?.postalCode || "",
              countryCode: profileInformation.invoiceAddress?.countryCode || "",
            };
            this.consent = profileInformation.consent || {};
            this.notification_language =
              profileInformation.preferences?.language?.toUpperCase() || "EN";
            this.room_temperature =
              parseInt(profileInformation.preferences?.roomTemperature) || 0;
            this.subscriptionBenefits =
              profileInformation.subscriptionBenefits || null;

            // store data intermediate for Nuxt2
            this.setToken(
              JSON.stringify({
                name: this.lastName,
                firstname: this.firstName,
                id: this.profileId,
                type: this.getUserType,
              }),
              "id_name",
            );

            this.setTtl();
          }
        }
        return this;
      } catch (e) {
        throw new Error(e);
      }
    },
    logoutUser() {
      // Reset to initial state deeply
      // this.$reset() better?
      initialStateObjects.forEach((obj) => {
        initialState[obj] = { ...cloneState[obj] };
      });
      // Clear objects initial state
      Object.keys(initialState).forEach((key) => {
        this[key] = initialState[key];
      });

      this.removeToken();
      // store data intermediate for Nuxt2
      this.removeToken("id_name");
      this.renewUserData();
    },

    setToken(token, name) {
      try {
        const cname = name || COOKIE_ID_TOKEN;
        const exmins = 6,
          cvalue = token;
        const d = new Date();
        d.setTime(d.getTime() + exmins * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie = `${cname}=${cvalue};${expires};path=/`;
      } catch (e) {
        // Not removed since no client?
      }
    },

    removeToken(name) {
      try {
        const cname = name || COOKIE_ID_TOKEN;
        const d = new Date();
        d.setTime(d.getTime() - 100);
        let expires = "expires=" + d.toUTCString();
        document.cookie = `${cname}=;${expires};path=/`;
      } catch (e) {
        // Not removed since no client?
      }
    },
    async loadUserStays(locale, t, limitToStatus) {
      if (this.isTtlStaysOver || !this.reservations.length) {
        // Fetch reservation data
        const loadReservations = await getBookings().then((data) => data.data);
        this.reservations = await groupReservationsByStatus(
          loadReservations,
          locale,
          t,
        );
        this.setTtlStays();
      }

      // Select only some of these
      if (limitToStatus) {
        return this.reservations.filter((group) =>
          limitToStatus.includes(group.status),
        );
      }

      return this.reservations;
    },

    setTtl() {
      // Lifetime of data
      const now = new Date();
      now.setHours(now.getHours() + ttlHours);
      this.ttl = now;
    },
    setTtlStays() {
      // Lifetime of data
      const now = new Date();
      now.setHours(now.getHours() + ttlHours);
      this.ttlStays = now;
    },

    // Call that makes data reload
    renewUserData() {
      this.ttl = null;
      this.ttlStays = null;
    },

    async loadBusinessAccount() {
      await getSmbSubscription(this.email).then((businessAccount) => {
        this.memberShip.businessAccount = businessAccount;
        this.memberShip.businessAccountCode = businessAccount?.code;
      });
    },
  },
});
