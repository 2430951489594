import { localeToCfLocal } from "~/composables/useLocales";
import { sortByTitle } from "~/utils/array-sorting";
import { slugify } from "~/utils/string";
import { getMasterdataV3 } from "~/services/masterdataService";
import {
  buildSlugFromPropertyCode,
  getLinkToPage,
  getSlugForPagetypeName,
  locationSlugs,
} from "~/services/slugService";

export async function getFooterNavigation(locale, contentfulClient) {
  return contentfulClient.getEntries({
    content_type: "websiteFooternavigation",
    locale: localeToCfLocal(locale),
    include: 10,
  });
}

export async function getRoomTypes(locale, contentfulClient) {
  return contentfulClient.getEntries({
    content_type: "roomtype",
    locale: locale,
    include: 10,
  });
}

export async function getCitySlug(cityId, locale, contentfulClient) {
  return contentfulClient
    .getEntry(cityId, { include: 5, locale: localeToCfLocal(locale) })
    .then((city) => {
      return locationSlugs(city);
    })
    .catch((e) => {
      // console.log({e})
      return null;
    });
}

export async function getContinentSlug(continentId, locale, contentfulClient) {
  return contentfulClient
    .getEntry(continentId, { locale: localeToCfLocal(locale) })
    .then((continent) => {
      return locationSlugs(continent);
    })
    .catch((e) => {
      // console.log({e})
      return null;
    });
}

export async function getMeetingRoomsFromHotel(
  locale,
  hotelCode,
  contentfulClient,
) {
  try {
    const hotelEntries = await contentfulClient.getEntries({
      content_type: "hotel",
      "fields.code[in]": hotelCode,
      locale: localeToCfLocal(locale),
      limit: 1,
    });

    if (hotelEntries.items && hotelEntries.items.length > 0) {
      try {
        const societyMHotels = await contentfulClient.getEntries({
          content_type: "societyMHotel",
          locale: localeToCfLocal(locale),
          links_to_entry: hotelEntries.items[0].sys.id,
          include: 10,
        });

        if (societyMHotels.items && societyMHotels.items.length > 0) {
          return societyMHotels.items[0];
        }
      } catch (innerError) {
        console.error("Error fetching societyMHotel entries:", innerError);
        return null;
      }
    }

    return null;
  } catch (error) {
    console.error("Error in getMeetingRoomsFromHotel:", error);
    return null;
  }
}

/**
 *
 * @param {string} locale
 * @param {string} hotelCode
 * @returns {}
 */

export async function getMeetingRoomsServicesFromHotel(
  locale,
  hotelCode,
  contentfulClient,
) {
  const hotelEntries = await contentfulClient.getEntries({
    content_type: "hotel",
    "fields.code": hotelCode,
    locale: localeToCfLocal(locale),
    limit: 1,
  });

  const societyMHotels = await contentfulClient.getEntries({
    content_type: "societyMHotel",
    locale: localeToCfLocal(locale),
    links_to_entry: hotelEntries.items[0].sys.id,
    include: 10,
  });

  // const societyMFbPackage = await client.getEntries({
  //   content_type: "societyMFbPackagecategory",
  //   locale: localeToCfLocal(locale),
  //   links_to_entry: societyMHotels.items[0].fields.fbsocietyM.sys.id,
  //   include: 10,
  // });

  return societyMHotels.items[0] || undefined;
}

export async function getSocietyMPackages(locale, contentfulClient) {
  const packagesEntries = await contentfulClient.getEntries({
    content_type: "societyMFbPackagecategory",
    locale: localeToCfLocal(locale),
  });

  return packagesEntries;
}

export async function getSocietyMProducts(locale, contentfulClient) {
  const packagesEntries = await contentfulClient.getEntries({
    content_type: "societyMFbProduct",
    locale: localeToCfLocal(locale),
  });

  return packagesEntries;
}

export function getBanners(contentfulClient) {
  return contentfulClient.getEntries({
    content_type: "banner",
    include: 1,
  });
}

export async function getPageForPageType(pageType, locale, marketingClient) {
  return await marketingClient
    .getEntries({
      links_to_entry: pageType.sys.id,
      content_type: "page",
      locale: localeToCfLocal(locale),
      include: 5,
      limit: 1,
    })
    .then((res) => res.items[0])
    .then((page) => {
      if (pageType.fields) {
        page.fields.pageType = pageType;
      }

      return page;
    })
    .catch(() => {
      return {
        fields: {
          pageType,
        },
      };
    });
}

export async function getCallToActionLink(
  linkId,
  locale,
  marketingClient,
  contentfulClient,
  publicConfig,
  localePath,
) {
  const link = await getLinkToPage(
    linkId,
    locale,
    marketingClient,
    contentfulClient,
    publicConfig,
    localePath,
  );
  return link?.url;
}

/**
 * combo function to retrieve a page object directly from a slug
 *
 * @param page_type
 * @param slug
 * @param locale
 * @returns {Promise<void>}
 */
export async function getMarketingPageBySlug(
  params,
  locale,
  marketingClient,
  contentfulClient,
) {
  return getMarketingPageTypeBySlug(
    params,
    locale,
    marketingClient,
    contentfulClient,
  ).then(async (pageType) => {
    if (!pageType) return null;
    return getPageForPageType(pageType, locale, marketingClient);
  });
}

/**
 *
 * @param {{pageType: string, slug: string}} params
 * @param locale
 * @returns {Promise<*|null>}
 */
export async function getMarketingPageTypeBySlug(
  params,
  locale,
  marketingClient,
  contentfulClient,
) {
  const { city, pageType, slug } = params;
  if (!pageType || !slug) {
    return null;
  }

  const queryOptions = {
    content_type: pageType,
    locale: localeToCfLocal(locale),
    "fields.slug": slug,
    include: 5,
    limit: 10,
  };

  const cc = await marketingClient.getEntries(queryOptions).catch((e) => {
    return null;
  });

  /**
   * When we have multiple entries with the same slug and provided a city param
   * Look up the entry that corresponds with the city slug
   */
  if (cc?.items?.length > 1 && city) {
    // Fetch all localized cities from product space
    // This is for now the lowest impact / risk change.
    // TODO: store localized cities globally so we only have to fetch them once
    // SEE: https://citizenm.atlassian.net/browse/WMD1-2305
    const { items: localizedCities } =
      await contentfulClient.withAllLocales.getEntries({
        content_type: "city-master",
        include: 0,
      });

    // Use the city slug from route parameter, which is localized, to find a match
    // Fall back to English when no slug for city is configured
    const targetCity = localizedCities.find(
      (localizedCity) =>
        (localizedCity.fields.slug[localeToCfLocal(locale)] ||
          localizedCity.fields.slug.en) === city,
    );

    if (!targetCity) {
      return null;
    }

    const match = cc?.items?.find((marketingItem) => {
      if (pageType === "hotel") {
        //  Match the localized city with English slug, since that's the one we're getting
        // from the municipality field in the marketing space
        return (
          slugify(
            marketingItem.fields.hotelMasterContent.fields.location.fields
              .municipality,
          ) === targetCity.fields.slug.en.toLowerCase()
        );
      }

      // Use the English slug to match the city for non-hotel pages
      return (
        marketingItem.fields.location.fields.slug.toLowerCase() ===
        targetCity.fields.slug.en.toLowerCase()
      );
    });

    return match || null;
  }

  // Return first item or null
  return cc?.items?.shift() || null;
}

/**
 * The slug here lives in masterdata
 * tehrefore the lookup is a bit more complex
 * this is neede for the overview pages of locations (city and continent pages)
 *
 * @param {city: string, slug: boolean} params
 * @param {string} locale
 * @returns {Promise<null | BaseEntry | {sys: {contentType: {sys: {id: EntrySkeletonType<FieldsType, string>["contentTypeId"]}}}, fields: ChainModifiers extends ("WITH_ALL_LOCALES" | "WITHOUT_LINK_RESOLUTION" | "WITHOUT_UNRESOLVABLE_LINKS") ? ({[FieldName in keyof EntrySkeletonType<FieldsType, string>["fields"]]: {[LocaleName in string]?: ResolvedField<EntrySkeletonType<FieldsType, string>["fields"][FieldName], "WITH_ALL_LOCALES" | "WITHOUT_LINK_RESOLUTION" | "WITHOUT_UNRESOLVABLE_LINKS", string>}} | {[FieldName in keyof EntrySkeletonType<FieldsType, string>["fields"]]: ResolvedField<EntrySkeletonType<FieldsType, string>["fields"][FieldName], "WITH_ALL_LOCALES" | "WITHOUT_LINK_RESOLUTION" | "WITHOUT_UNRESOLVABLE_LINKS", string>}) : ("WITH_ALL_LOCALES" extends ("WITH_ALL_LOCALES" | "WITHOUT_LINK_RESOLUTION" | "WITHOUT_UNRESOLVABLE_LINKS") ? {[FieldName in keyof EntrySkeletonType<FieldsType, string>["fields"]]: {[LocaleName in string]?: ResolvedField<EntrySkeletonType<FieldsType, string>["fields"][FieldName], "WITH_ALL_LOCALES" | "WITHOUT_LINK_RESOLUTION" | "WITHOUT_UNRESOLVABLE_LINKS", string>}} : {[FieldName in keyof EntrySkeletonType<FieldsType, string>["fields"]]: ResolvedField<EntrySkeletonType<FieldsType, string>["fields"][FieldName], "WITH_ALL_LOCALES" | "WITHOUT_LINK_RESOLUTION" | "WITHOUT_UNRESOLVABLE_LINKS", string>})} | {fields: {pageType: *}}>}
 */
export async function getMarketingPageByMasterslug(
  params,
  locale,
  marketingClient,
) {
  const _locale = localeToCfLocal(locale);

  const master = await fetchMasterdata(params, _locale)
    .then((res) => {
      if (params.hotelSlug) return res.hotel;
      if (params.city) return res.city;
      if (params.continent) return res.continent;
      throw new Error("No masterdata object could be loaded!");
    })
    .catch((e) => null);
  if (!master) return null;

  return await marketingClient
    .getEntries({
      content_type: "hotelsOverview",
      include: 10,
      locale: _locale,
    })
    .then((res) => {
      return res.items;
    })
    .then((pages) => {
      // Filter the master data
      return pages.filter((page) => {
        return page.fields?.location?.sys?.id == master.sys.id;
      });
    })
    .then((pages) => {
      return pages?.shift();
    })
    .then(async (pageType) => {
      if (!pageType) {
        return await getPageForPageType(
          {
            sys: {
              id: 0, // DUMMY
            },
            fields: {
              location: master,
            },
          },
          _locale,
          marketingClient,
        );
      }
      return await getPageForPageType(pageType, locale, marketingClient);
    })
    .catch(async () => {
      // Fallback: only master data
      return null;
    });
}

export async function getContentCanvas(slug, locale, marketingClient) {
  const queryOptions = {
    content_type: "contentCanvas",
    locale: localeToCfLocal(locale),
    "fields.slug": slug,
    include: 4,
    limit: 1,
  };

  const cc = await marketingClient.getEntries(queryOptions).catch((e) => {
    return null;
  });

  let city = null;
  if (params.city) {
    cc.items = cc.items?.filter((pageType) => {
      city =
        pageType.fields?.location?.sys.contentType?.sys.id == "city-master"
          ? pageType.fields.location
          : null;
      return params.city == city.fields.slug;
    });
  }

  // if (continent) {
  //     // TODO
  // }

  // Return first item or null
  return cc?.items?.shift() || null;
}

export function extractMetaFromPage(page) {
  let title = "loading";

  // Meta data with fallback to touchpoints meta data
  let meta = {
    ...page?.fields?.touchpoint?.fields?.touchpointMetadata?.fields,
    ...page?.fields?.pageMetadata?.fields,
  };

  if (Object.keys(meta).length > 0) {
    title = meta.seoTitle || title;
    const description = meta.seoDescription || "";
    const keywords = meta.seoKeywords ? meta.seoKeywords.join(",") : "";

    const socialTitle = meta.socialTitle || title;
    const socialDescription = meta.socialDescription
      ? meta.socialDescription != ""
        ? meta.socialDescription
        : description
      : description;

    let socialImage = "";
    if (meta.socialImage) {
      socialImage = meta.socialImage.fields?.asset[0]?.src || "";
    }

    // Robots
    const follow = meta.noIndex ? ["noindex"] : [];
    if (meta.noFollow) follow.push("nofollow");
    const robots = follow.length
      ? [{ hid: "robots", name: "robots", content: follow.join(",") }]
      : [];
    useHead({
      meta: [...robots],
    });
    return {
      title: title,
      ogTitle: socialTitle,
      description: description,
      keywords: keywords,
      ogDescription: socialDescription,
      ogImage: socialImage,
      twitterCard: "summary_large_image",
    };
  }

  // Robots
  // TODO: Merge this logic with line 373
  const follow = meta.noIndex ? ["noindex"] : [];
  if (meta.noFollow) follow.push("nofollow");
  const robots = follow.length
    ? [{ hid: "robots", name: "robots", content: follow.join(",") }]
    : [];
  useHead({
    meta: [...robots],
  });
  return {
    title: title,
    ogTitle: title,
  };
}

/**
 * Fetch masterdata for a given city|continent|hotelSlug
 *
 * @param {{city: string, continent: string, slug: boolean}} params
 * @param {string} locale
 * @returns {Promise<{continent: undefined, city: undefined, hotel: undefined}>}
 */
export const fetchMasterdata = async (params, locale, contentfulClient) => {
  const { hotelSlug, city, continent } = params;
  let cityLoaded = undefined;
  let continentLoaded = undefined;

  const queryOptions = {
    locale: localeToCfLocal(locale),
    include: 10,
    limit: 1,
  };

  if (hotelSlug) {
    // Only a slug
    queryOptions.content_type = "hotel-master";
    queryOptions["fields.slug"] = hotelSlug.join("/");
    const hotels = await contentfulClient
      .getEntries(queryOptions)
      .catch(() => null);
    // Return first item or null
    hotelsLoaded = hotels?.items?.shift() || null;
  } else if (continent) {
    queryOptions.content_type = "continent-master";
    queryOptions["fields.slug"] = continent;
    const continents = await contentfulClient
      .getEntries(queryOptions)
      .catch(() => null);
    // Return first item or null
    continentLoaded = continents?.items?.shift() || null;
  } else if (city) {
    queryOptions.content_type = "city-master";
    queryOptions["fields.slug"] = city;
    const cities = await contentfulClient
      .getEntries(queryOptions)
      .catch(() => null);
    // Return first item or null
    cityLoaded = cities?.items?.shift() || null;
  }

  return {
    city: cityLoaded,
    continent: continentLoaded,
  };
};

/**
 * Finds marketing pagetype data by a given slug
 *
 * @param {{hotelSlug: array}} params
 * @param {string} locale
 * @returns {Promise<*|null>}
 */
export const fetchPageTypeByHotelSlug = async (
  params,
  locale,
  marketingClient,
  contentfulClient,
) => {
  const _locale = localeToCfLocal(locale);
  const { hotelSlug, city, continent } = params;

  const hotelPageType = await marketingClient
    .getEntries({
      content_type: "hotel",
      "fields.slug": hotelSlug,
      locale: _locale,
      include: 10,
    })
    .then((res) => res.items)
    .then(async (hotelPages) => {
      let foundHotel = null;
      await Promise.all(
        hotelPages.map(async (hotel) => {
          const slugs = await contentfulClient
            .getEntry(
              hotel.fields.hotelMasterContent?.fields?.location?.sys.id,
              {
                locale: locale,
                include: 6,
              },
            )
            .then((location) => {
              return locationSlugs(location);
            })
            .catch(() => []);
          if (slugs.length > 1 && slugs[0] == continent && slugs[1] == city) {
            foundHotel = hotel;
          }
        }),
      );
      return foundHotel;
    })
    .then(async (hotel) => {
      // Load hotel info in depth (maybe necessary!)
      const property = await contentfulClient
        .getEntry(hotel.fields.hotelMasterContent?.sys.id, {
          locale: _locale,
          include: 10,
        })
        .then((prop) => {
          hotel.fields.hotelMasterContent = prop;
        })
        .catch(() => null);

      return hotel;
    })
    .catch(() => null);

  return hotelPageType;
};

/**
 * finds property data by a given slug
 *
 * @param {{hotelSlug: string, city: string, continent: string}} params
 * @param {string} locale
 * @returns {Promise<*|null>}
 */
export const fetchPageByHotelSlug = async (
  params,
  locale,
  marketingClient,
  contentfulClient,
) => {
  const { hotelSlug, city, continent } = params;

  const hotelPageType = await fetchPageTypeByHotelSlug(
    params,
    locale,
    marketingClient,
    contentfulClient,
  );
  return getPageForPageType(hotelPageType, locale, marketingClient);
};

/**
 * Fetch a marketing page (and pagetype) by a referenced master data hotel
 *
 * @param {{propertyId: string}} params
 * @param locale
 * @returns {Promise<void>}
 */
export const fetchMarketingPageByProperty = async (
  params,
  locale,
  marketingClient,
) => {
  const { property } = params;
  const propertyId = property.sys.id;

  const queryOptions = {
    content_type: "hotel",
    locale: localeToCfLocal(locale),
    include: 10,
  };
  const marketingPage = await marketingClient
    .getEntries(queryOptions)
    .then((res) => res.items)
    .then((pages) => {
      const pageType = pages.find((item) => {
        const masterLink = item.fields?.hotelMasterContent;
        return (
          masterLink &&
          (masterLink.sys.urn
            ? (masterLink.sys.urn + "").endsWith(propertyId)
            : masterLink.sys.id == propertyId)
        );
      });
      if (!pageType) return null;

      // Ensure depth of the returned object
      pageType.fields.hotelMasterContent = property;
      return getPageForPageType(pageType, locale, marketingClient);
    })
    .catch((e) => {
      return null;
    });
  return marketingPage;
};

/**
 * Fetch hotel data for the hotel info page
 *
 * @param params
 * @param locale
 * @returns {Promise<{marketingHotel: Promise<string>, property: *, propertySlug: ComputedRef<string|*>}|null>}
 */
export const fetchHotelData = async (
  params,
  locale,
  marketingClient,
  contentfulClient,
  localePath,
) => {
  // Collect data for a given slug
  return await fetchPageByHotelSlug(
    params,
    locale,
    marketingClient,
    contentfulClient,
  )
    .then(async (hotelPage) => {
      const hotel = hotelPage.fields?.pageType;
      const masterProperty = hotel?.fields?.hotelMasterContent;
      hotelPage.slug = hotel.fields?.slug
        ? [
            localePath("hotels", locale),
            ...locationSlugs(masterProperty.fields?.location),
            hotel.fields?.slug,
          ].join("/")
        : null;
      return hotelPage;
    })
    .catch((e) => {
      return null;
    });
};

export const renderLink = async (link) => {
  // link.content holds the output information
  // link.data holds the link information
  return `<a>${link.content[0].value}</a>`;
};

export const getHotelMarketingPageByCode = async (
  code,
  locale,
  marketingClient,
  contentfulClient,
) => {
  const queryOptions = {
    content_type: "property",
    "fields.code": code,
    locale: localeToCfLocal(locale),
    limit: 1,
    include: 10,
  };
  const property = await contentfulClient
    .getEntries(queryOptions)
    .then((res) => {
      return res?.items[0] || null;
    })
    .catch((e) => {
      return null;
    });

  if (property) {
    return await fetchMarketingPageByProperty(
      { property },
      locale,
      marketingClient,
    ).catch(() => null);
  }

  return null;
};

const fetchCitiesForLocation = async (params, locale) => {
  const { cityCode, countryCode, continentCode } = params;
  const locationCities = await getMasterdataV3("locations", locale)
    .then((res) => res.data)
    .then((data) => data.continents)
    // Filter continents and countries
    .then((continents) => {
      if (continentCode) {
        return continents.filter(
          (continent) => continent.code == continentCode,
        );
      }
      return continents;
    })
    .then(async (continents) => {
      const countries = [];
      await Promise.all(
        continents.map((continent) => {
          countries.push(
            ...continent.countries.filter((country) => {
              if (
                countryCode &&
                country.code.toLowerCase() != countryCode?.toLowerCase()
              ) {
                return false;
              }
              return true;
            }),
          );
        }),
      );
      return countries;
    })
    // Filter city
    .then(async (countries) => {
      const cities = [];
      await Promise.all(
        countries.map((country) => {
          cities.push(
            ...country.cities.filter((city) => {
              if (
                cityCode &&
                city.code.toLowerCase() != cityCode.toLowerCase()
              ) {
                return false;
              }
              return true;
            }),
          );
        }),
      );
      return cities;
    })
    .catch(() => {
      return [];
    });
  return locationCities;
};

const fetchHotelsForLocation = async (
  params,
  locale,
  marketingClient,
  contentfulClient,
  publicConfig,
  localePath,
) => {
  const { cityCode, countryCode, continentCode } = params;
  const marketingHotels = await marketingClient.withAllLocales
    .getEntries({
      content_type: "hotel",
    })
    .then((res) => res.items)
    .catch(() => null);
  const properties = await fetchCitiesForLocation(params, locale)
    .then(async (cities) => {
      const hotels = [];
      await Promise.all(
        cities.flatMap(async (city) => {
          await Promise.all(
            await city.properties.flatMap(async (hotel) => {
              hotels.push(
                await getMasterdataV3(
                  "properties",
                  locale,
                  ["hotel", "location"],
                  hotel.code,
                )
                  .then((res) => res.data)
                  .then(async (property) => {
                    const propertyHotel = property.hotel;

                    // TODO: must more criteria be fulfilled to show the hotel?
                    if (!propertyHotel) return null;
                    // if(!property.heroImage) return null;

                    // Build a nice hotel slug from its CODE
                    const slug = await buildSlugFromPropertyCode(
                      property.code,
                      marketingHotels,
                      cfLocaleToLocal(locale),
                      marketingClient,
                      contentfulClient,
                      publicConfig,
                      localePath,
                    )
                      .then((slug) => {
                        return slug;
                      })
                      .catch((e) => null);

                    return {
                      code: propertyHotel.code,
                      id: propertyHotel.contentId,
                      name: property.hotel.title,
                      // location: 'SOME LOCATION',
                      // flag: 'nl',
                      pricePerNightIncTax: 0,
                      pricePerNightExcTax: 0,
                      pricePerStayIncTax: 0,
                      pricePerStayExcTax: 0,
                      roomsCount: 0,
                      roomsAvailable: 0,
                      loaded: false,
                      text: property.hotel?.shortDescription || null,
                      photos: hotel.media || [property.heroImage] || [],
                      lat: property.location?.coordinates?.lat,
                      lng: property.location?.coordinates?.lon,
                      url: slug,
                      // @property {string} currency ; will be added dynamically
                      // @property {string} bookUrl ; will be added dynamically
                    };
                  })
                  .catch((e) => {
                    return null;
                  }),
              );
              return;
            }),
          );
        }),
      );
      return hotels.filter((hotel) => !!hotel); // Filter out empty hotels
    })
    .catch((e) => {
      // console.log({e})
      return [];
    });

  return properties;
};

/**
 * Use masterdata API to fetch hotels
 * and format the result according the needs of our components
 *
 * @param {object} location
 * @param {string} locale
 * @returns {Promise<*|*[]>}
 */
export const fetchHotelsForCity = async (
  location,
  locale,
  marketingClient,
  contentfulClient,
  publicConfig,
  localePath,
) => {
  const cityCode = location.fields?.code;
  const countryCode = location.fields?.country?.fields?.code;

  return await fetchHotelsForLocation(
    { cityCode, countryCode },
    localeToCfLocal(locale),
    marketingClient,
    contentfulClient,
    publicConfig,
    localePath,
  );
};

export const fetchHotelsForContinent = async (
  pageType,
  locale,
  contentfulClient,
  localePath,
) => {
  if (!contentfulClient || !localePath) {
    throw new Error(
      `The following properties are required but missing for "fetchHotelsForContinent": ${!contentfulClient ? "contentfulClient" : ""} ${!localePath ? "localePath" : ""}`,
    );
  }

  const continentCode = pageType?.fields?.location?.fields?.code;
  const _locale = localeToCfLocal(locale);

  // TODO: this code should be checked by Joe. It has been put back as result of a failing (test) release
  const cities = await fetchCitiesForLocation({ continentCode }, _locale).then(
    async (cities) => {
      // We do not have the city data here so ...
      // ... load it from CF
      const expandedCities = [];
      await Promise.allSettled(
        cities.map(async (city) => {
          const cityData = await getEntry(city.contentId, _locale).catch(
            (e) => {
              return null;
            },
          );

          // Slug
          const slug = await getSlugForPagetypeName(
            {
              location: cityData,
              pageType: {
                sys: { contentType: { sys: { id: "hotelsOverview" } } },
              },
            },
            // This slug does not need to be converted to CF locale
            // Because its used for URL building, not data fetching
            locale,
            contentfulClient,
            localePath,
          )
            .catch((e) => {
              return null;
            })
            .then((slug) => slug);

          expandedCities.push({
            code: city.code,
            id: city.contentId,
            name: city.title,
            location: city.code,
            //flag: 'nl',
            pricePerNightIncTax: 0,
            pricePerNightExcTax: 0,
            pricePerStayIncTax: 0,
            pricePerStayExcTax: 0,
            properties: city.properties.map((prop) => prop.code)?.join(","),
            roomsCount: city.properties.length,
            roomsAvailable: 0,
            loaded: false,
            // text: hotel.shortDescription,
            photos: cityData?.fields?.heroImage
              ? [cityData?.fields?.heroImage.fields]
              : [],
            lat: city.coordinates?.lat,
            lng: city.coordinates?.lon,
            url: slug,
            slug: cityData?.fields?.slug,
            //     // @property {string} currency ; will be added dynamically
            //     // @property {string} bookUrl ; will be added dynamically
          });
        }),
      );

      return expandedCities;
    },
  );
  return cities;
};

export async function getContinents(locale) {
  // New approach
  // Load continent data fropm masterdata v3
  return await getMasterdataV3("locations", localeToCfLocal(locale))
    .then((res) => res.data)
    .then((data) => {
      // TODO: this code should be checked by Joe. It has been put back as result of a failing (test) release.
      const continents = data.continents.sort(function (a, b) {
        if (a.code == "EUROPE") return -1;
        if (a.code == "ASIA") return 1;
        //if (a.code == 'AMERICA')
        return 0;
      });
      return continents.map((continent) => {
        const cities = [];
        continent.countries.map((country) => {
          country.cities.map((city) => {
            const properties = city.properties.map((property) => {
              return {
                title: property.title,
                code: property.code,
                id: property.code,
                cfId: property.contentId,
                type: "property",
              };
            });
            cities.push({
              title: city.title,
              properties: properties.sort(sortByTitle),
              code: city.code,
              id: city.contentId,
              cfId: city.contentId,
              type: "city",
            });
          });
        });

        return {
          code: continent.code,
          id: continent.contentId,
          cfId: continent.contentId,
          title: continent.title,
          cities: cities.sort(sortByTitle),
          type: "continent",
        };
      });
    })
    .catch(() => null);
}

export async function getMeetingrooms(locale) {
  // TODO!
}

export const loadMarketingFeatures = async (locale, marketingClient) => {
  const propertyFeatures = await marketingClient
    .getEntries({
      content_type: "propertyFeatures",
      locale: localeToCfLocal(locale),
      // links_to_entry: feature.sys.id,
      include: 10,
    })
    .then((res) => res.items)
    .catch((e) => {
      return [];
    });
  const hotelFeatures = await marketingClient
    .getEntries({
      content_type: "hotelFeature",
      locale: localeToCfLocal(locale),
      // links_to_entry: feature.sys.id,
      include: 10,
    })
    .then((res) => res.items)
    .catch((e) => {
      return [];
    });

  return {
    propertyFeatures,
    hotelFeatures,
  };
};

export const getMarketingPageforFeature = async (
  params,
  locale,
  marketingClient,
  contentfulClient,
) => {
  const { featureType, continent, city, hotelSlug, featureSlug } = params;

  // Possibly multiple objects with this slug
  const marketingPageTypes = await marketingClient
    .getEntries({
      content_type: featureType,
      locale: cfLocaleToLocal(locale),
      ["fields.slug"]: featureSlug,
      include: 10,
    })
    .then((res) => res.items)
    .catch(() => []);

  // Check location
  const actualRoute = [continent, city, hotelSlug].join("/");
  let property = null;
  let marketingPageType = null;

  await Promise.all(
    marketingPageTypes.map(async (mtp) => {
      const content =
        mtp.fields?.hotelFeatureContent || mtp.fields?.rooftopOrCoworking;

      // We can assume that there is exactly one feature in master that is linked by exactly one property
      await contentfulClient
        .getEntries({
          content_type: "property",
          links_to_entry: content.sys.id,
          include: 10,
          limit: 1,
        })
        .then((res) => res.items[0])
        .then((prop) => {
          // Check at least the location params
          const locations = locationSlugs(prop.fields?.location).join("/");
          if (actualRoute.includes(locations)) {
            property = prop;
            marketingPageType = mtp;
          }
        })
        .catch(() => null);
    }),
  );

  // Make it a ”page"
  if (property && marketingPageType) {
    return {
      marketingPage: marketingPageType
        ? await getPageForPageType(marketingPageType, locale, marketingClient)
        : null,
      property,
    };
  }

  return {};
};

export const loadPropertyCodeForSlug = async (
  params,
  locale,
  marketingClient,
  contentfulClient,
) => {
  const { continent, city, hotelSlug } = params;
  const hotelPageType = await getMarketingPageTypeBySlug(
    { city, pageType: "hotel", slug: hotelSlug },
    localeToCfLocal(locale),
    marketingClient,
    contentfulClient,
  );
  const master = hotelPageType.fields?.hotelMasterContent;
  let propertyCode = null;

  // Now also check location path to prohibit something like '/hotels/antarctica/souther-south-pole/amstel-hotel'
  const actualRoute = [continent, city, hotelSlug].join("/");
  const locations = locationSlugs(master?.fields?.location, [hotelSlug]);
  if (actualRoute.includes(locations.join("/"))) {
    propertyCode = master?.fields?.code;
  }

  return {
    propertyCode,
  };
};
