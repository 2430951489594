const sortArrayByProperty = (a, b, property) => {
  if (!a[property]) return 0;
  const stringA = a[property].toUpperCase(); // ignore upper and lowercase
  const stringB = b[property].toUpperCase(); // ignore upper and lowercase
  if (stringA < stringB) {
    return -1;
  }
  if (stringA > stringB) {
    return 1;
  }

  // names must be equal
  return 0;
};

export const sortByTitle = (a, b) => {
  return sortArrayByProperty(a, b, "title");
};

export const sortByText = (a, b) => {
  return sortArrayByProperty(a, b, "text");
};
