const pageNames = ["app", "book", "get-booking", "my"];

export default defineNuxtRouteMiddleware((to) => {
  if (
    pageNames.some(
      (pageName) => typeof to.name === "string" && to.name.startsWith(pageName),
    )
  ) {
    useHead({
      meta: [
        {
          name: "robots",
          content: "noindex, nofollow",
        },
      ],
    });
  } else {
    useHead({
      meta: [
        {
          name: "robots",
          content: null,
        },
      ],
    });
  }
});
