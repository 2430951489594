import { COOKIE_ID_TOKEN } from "../constants";

export const goodbitsApi = (_token?: string | null) => {
  const { public: publicConfig } = useRuntimeConfig();

  return $fetch.create({
    baseURL: publicConfig.goodbitsUrl,
    headers: {
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": publicConfig.goodbitsKey,
    },
    onRequest({ options }) {
      if (_token) {
        options.headers.set("Authorization", `Bearer ${_token}`);
      } else if (import.meta.client) {
        // Fallback when cookie is set after initialisation of the plugin
        const token = useCookie(COOKIE_ID_TOKEN);
        if (token.value) {
          options.headers.set("Authorization", `Bearer ${token.value}`);
        }
      }
    },
    async onResponse({ response }) {
      response._data = {
        data: response._data,
        status: response.status,
      };
    },
  });
};
