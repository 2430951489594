import { goodBitsClient } from "./httpService";

export function fetchAvailability(
  hotelCodes,
  arrivalDate,
  departureDate,
  totalGuests,
  numberOfRooms,
  promoCode,
  useGoodbitsKeyForApp,
) {
  // TODO Because we always book guests related to the selected rooms
  // Example: 2 room is selected and 1 guest, booking systems books 2 rooms for each 1 person
  const totalGuestsMultipliedByRooms = totalGuests * numberOfRooms;
  const params = {
    hotelCodes,
    numberOfRooms,
    totalGuests: totalGuestsMultipliedByRooms,
    arrivalDate,
    departureDate,
  };

  if (promoCode && promoCode.length) {
    params["promoCode"] = params["corporateCode"] = promoCode;
  }

  const options = {};

  if (useGoodbitsKeyForApp) {
    options.useGoodbitsKeyForApp = true;
  }

  return goodBitsClient(options).get("/offers/v1/hotel-rooms", { params });
}

export function createCart(shopper, products) {
  return goodBitsClient().post("/shopping-carts/v1/cart", {
    shopper,
    products,
  });
}

export function monitorCart(instanceId) {
  return goodBitsClient().get(`/shopping-carts/v1/monitor/${instanceId}`);
}

export function confirmCart(cartId) {
  return goodBitsClient().post(`/shopping-carts/v1/cart/${cartId}/confirm`);
}

export function createPaymentSessions(cartId, returnUrl) {
  return goodBitsClient().post(
    `/shopping-carts/v1/cart/${cartId}/payment-sessions`,
    {
      returnUrl,
      shopperPlatform: "website",
      storePaymentMethodInProfile: true,
    },
  );
}

export function fetchServiceOffers(offerId) {
  return goodBitsClient().get(`/offers/v1/hotel-rooms/${offerId}/services`);
}

export function fetchRoomUpgrades(offerId, numberOfRooms) {
  return goodBitsClient().get(
    `/offers/v1/hotel-rooms/${offerId}/room-upgrades?numberOfRooms=${numberOfRooms}`,
  );
}

export function getBookingBaseJWT(lastName, bookingCode) {
  return goodBitsClient().post(`/passwordlesslogin/v1/booking-based-jwt`, {
    lastName,
    bookingCode,
  });
}

export async function getBookingInfo(bookingCode, token) {
  return await goodBitsClient({ otherToken: token }).get(
    `/my-reservation-bff/v1/bookings/${bookingCode}`,
    {
      params: {
        expand: "balances",
      },
    },
  );
}

export function getReservationInfo(reservationCode) {
  return goodBitsClient().get(
    `/my-reservation-bff/v1/reservations/${reservationCode}`,
    {
      params: {
        expand:
          "timeSlices,services,booker,actions,company,assignedRooms,allowedModifications,balances",
        // 'expand': 'hotel,booker,services,actions,company,allowedModifications,timeSlices,balances,assignedRooms'
      },
    },
  );
}

export function getReservationInfoWithToken(reservationCode, token) {
  return goodBitsClient({ otherToken: token }).get(
    `/my-reservation-bff/v1/reservations/${reservationCode}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        expand:
          "timeSlices,services,booker,actions,company,assignedRooms,allowedModifications,balances",
        // 'expand': 'hotel,booker,services,actions,company,allowedModifications,timeSlices,balances,assignedRooms'
      },
    },
  );
}

export async function getReservationServiceOffers(reservationCode) {
  return await goodBitsClient().get(
    `/my-reservation-bff/v1/reservations/${reservationCode}/services/offers`,
  );
}

export async function getReservationServiceOffersWithToken(
  reservationCode,
  token,
) {
  return await goodBitsClient({ otherToken: token }).get(
    `/my-reservation-bff/v1/reservations/${reservationCode}/services/offers`,
  );
}

export async function getReservationInvoices(reservationCode, token) {
  return await goodBitsClient().get(
    `/my-reservation-bff/v1/reservations/${reservationCode}/invoices`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        expand: "pdf",
      },
    },
  );
}

export async function getReservationInvoice(reservationCode, invoiceId, token) {
  return await goodBitsClient().get(
    `/my-reservation-bff/v1/reservations/${reservationCode}/invoices/${invoiceId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        expand: "pdf",
      },
    },
  );
}

export async function updateAdditionalGuest(
  reservationCode,
  guestId,
  data,
  token,
) {
  return await goodBitsClient({ otherToken: token }).put(
    `/my-reservation-bff/v1/reservations/${reservationCode}/guests/additional/${guestId}`,
    data,
  );
}

export async function addAdditionalGuest(reservationCode, data, token) {
  return await goodBitsClient({ otherToken: token }).post(
    `/my-reservation-bff/v1/reservations/${reservationCode}/guests/additional`,
    data,
  );
}

export async function updatePrimaryGuest(reservationCode, data, token) {
  return await goodBitsClient({ otherToken: token }).put(
    `/my-reservation-bff/v1/reservations/${reservationCode}/guests/primary`,
    data,
  );
}

export async function addBookingPaymentSessions(
  bookingCode,
  type,
  returnUrl,
  additionalAmount,
  token,
) {
  return await goodBitsClient({ otherToken: token }).post(
    `/my-reservation-bff/v1/bookings/${bookingCode}/payment/sessions?notifyBooker=false`,
    {
      type,
      returnUrl,
      additionalAmount,
      storePaymentMethodInProfile: true,
    },
  );
}

export async function addReservationPaymentSessions(
  reservationCode,
  type,
  returnUrl,
  additionalAmount,
  token,
) {
  return await goodBitsClient({ otherToken: token }).post(
    `/my-reservation-bff/v1/reservations/${reservationCode}/payment/sessions`,
    {
      type,
      returnUrl,
      additionalAmount,
      storePaymentMethodInProfile: true,
    },
  );
}

export async function getRoomOffers(
  arrivalDate,
  departureDate,
  numberOfRooms,
  totalGuests,
  hotelCodes,
) {
  const params = {
    arrivalDate,
    departureDate,
    numberOfRooms,
    totalGuests,
    hotelCodes,
  };

  return await goodBitsClient().get("/offers/v1/hotel-rooms", { params });
}
