// not in use, not working

/*
export default defineNuxtPlugin({
  name: 'tt',
  setup() {
    return {
      provide: {
        $tt: (msg, msgDefault, args) => `${msg}`
      }
    }
  }
})
*/

export default defineNuxtPlugin((nuxtApp) => {
  const $tt = (msg, msgDefault, args) => `${msg}`;
  nuxtApp.provide("$tt", $tt);
});
