// Vendor
import { createClient, type CreateClientParams } from "contentful";

// Constants
import { CONTENTFUL_SPACE } from "~/shared/types/contentful/constants";

// Types
import type { ContentfulSpace } from "~/shared/types/contentful";

export function createContentfulClient(
  space: ContentfulSpace = CONTENTFUL_SPACE.Product,
  preview = false,
) {
  const { public: publicConfig } = useRuntimeConfig();

  const {
    // Product
    ctfSpaceId,
    ctfEnvironment,
    ctfCdaAccessToken,
    ctfCdaPreviewToken,
    // Marketing
    ctfMarketingSpaceId,
    ctfMarketingEnvironment,
    ctfMarketingCdaAccessToken,
    ctfMarketingCdaPreviewToken,
  } = publicConfig;

  if (
    // Product
    !ctfSpaceId ||
    !ctfEnvironment ||
    !ctfCdaAccessToken ||
    !ctfCdaPreviewToken ||
    // Marketing
    !ctfMarketingSpaceId ||
    !ctfMarketingEnvironment ||
    !ctfMarketingCdaAccessToken
  ) {
    throw new Error(
      "Contentful client is not initialized. Some env variables are missing.",
    );
  }

  const config: CreateClientParams =
    space === CONTENTFUL_SPACE.Marketing
      ? // Marketing config
        {
          accessToken: preview
            ? ctfMarketingCdaPreviewToken
            : ctfMarketingCdaAccessToken,
          environment: ctfMarketingEnvironment,
          headers: {
            "x-contentful-resource-resolution": btoa(
              JSON.stringify({
                spaces: {
                  [ctfSpaceId]: preview
                    ? ctfCdaPreviewToken
                    : ctfCdaAccessToken,
                },
              }),
            ),
          },
          host: `${preview ? "preview" : "cdn"}.contentful.com`,
          space: ctfMarketingSpaceId,
        }
      : // Product config
        {
          accessToken: ctfCdaAccessToken,
          environment: ctfEnvironment,
          space: ctfSpaceId,
        };

  return createClient(config);
}
