import { goodBitsClient } from "./httpService";

export async function getProfile() {
  return await goodBitsClient().get("/profiles/v1/profile", {
    params: {
      include:
        "preferences,consent,homeAddress,invoiceAddress,subscription,subscriptionBenefits",
      //'include': 'all'
    },
  });
}

export async function updateProfile(fieldsToPatch) {
  return await goodBitsClient().patch(
    "/profiles/v1/profile/fields",
    fieldsToPatch,
  );
}

export function getSubscription() {
  return goodBitsClient().get("/subscriptions/v3/status");
}

export async function getBookings() {
  return await goodBitsClient().get("/profiles/v1/profile/bookings");
}

export async function getMeetingRooms() {
  return await goodBitsClient().get("/profiles/v1/bookings/meeting-rooms");
}

/**
 *
 * @param {string} reservationCode
 * @returns {any}
 */
export async function getMeetingRoom(reservationCode) {
  return await goodBitsClient().get(
    `/meetingrooms-bff/v1/reservations/${Number(reservationCode)}`,
  );
}

export const encryptUrlInformation = (info) => {
  const string = JSON.stringify(info);
};
export const decryptUrlInformation = (crypto) => {};
