export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("wmx-outside", {
    mounted(el, binding) {
      const handleClickOutside = (event) => {
        if (!el.contains(event.target) && binding.value) {
          try {
            binding.value(event);
          } catch (e) {
            console.log(
              "Binding value for 'wmx-outside' should be of type function/method.",
            );
          }
        }
      };

      // Store the event listeners in the element to reference them later
      el.__handleClickOutside__ = handleClickOutside;

      document.addEventListener("click", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    },
    unmounted(el) {
      // Remove event listeners when the element is unmounted
      document.removeEventListener("click", el.__handleClickOutside__);
      document.removeEventListener("touchstart", el.__handleClickOutside__);
      delete el.__handleClickOutside__;
    },
  });
});
