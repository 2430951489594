const useMycitizenmNavi = () => {
  const config = useRuntimeConfig();

  const navigationLinks = [
    {
      title: "website.mycitzenm.dashboard",
      to: "/my/dashboard",
    },
    {
      title: "website.mycitzenm.my_reservations",
      to: "/my/reservations",
    },
    {
      title: "website.mycitzenm.my_profile",
      to: "/my/profile",
    },
    {
      title: "website.mycitzenm.membership",
      to: "/my/membership",
    },
  ];

  if (config.public.societymManage === true) {
    navigationLinks.push({
      title: "website.mycitzenm.meetingRooms",
      to: "/my/meeting-rooms",
    });
  }

  return navigationLinks;
};

export default useMycitizenmNavi;
