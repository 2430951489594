import validate from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.3.1_eslint@9.22.0_jiti@2.4_0a10e4b1150148363c7327f78562b5b8/node_modules/nuxt/dist/pages/runtime/validate.js";
import keep_45params_45global from "/home/vsts/work/1/s/apps/lobby/middleware/keepParams.global.js";
import robots_45no_45follow_45meta_45global from "/home/vsts/work/1/s/apps/lobby/middleware/robotsNoFollowMeta.global.ts";
import user_45interface_45global from "/home/vsts/work/1/s/apps/lobby/middleware/userInterface.global.ts";
import manifest_45route_45rule from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.3.1_eslint@9.22.0_jiti@2.4_0a10e4b1150148363c7327f78562b5b8/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  keep_45params_45global,
  robots_45no_45follow_45meta_45global,
  user_45interface_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/vsts/work/1/s/apps/lobby/middleware/auth.js"),
  "cached-page": () => import("/home/vsts/work/1/s/apps/lobby/middleware/cachedPage.ts"),
  "message-from-url": () => import("/home/vsts/work/1/s/apps/lobby/middleware/messageFromUrl.js")
}