// Utils
import { createContentfulClient } from "~/shared/utils/contentful";

// Constants
import { CONTENTFUL_SPACE } from "~/shared/types/contentful/constants";

export default defineNuxtPlugin(({ $config }) => {
  return {
    provide: {
      contentfulClient: createContentfulClient(),
      contentfulMarketingClient: createContentfulClient(
        CONTENTFUL_SPACE.Marketing,
        !!$config.public.ctfMarketingUsePreview,
      ),
    },
  };
});
