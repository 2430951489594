import { goodBitsClient } from "./httpService";

export function getSubscriptionList() {
  return goodBitsClient().get("/subscriptions/v3/list");
}

export function getBusinessSubscriptionList() {
  return goodBitsClient().get("/subscriptions/v3/business/list");
}

export function searchBusinessAccounts(params) {
  return goodBitsClient().post("/subscriptions/v3/business/search", params);
}

export function purchaseSeatSubscription(accountCode, params) {
  return goodBitsClient().post(
    `/subscriptions/v3/business/${accountCode}/seats`,
    params,
  );
}

export function removeSeatSubscription(accountCode, seatAccountCode) {
  return goodBitsClient().delete(
    `/subscriptions/v3/business/${accountCode}/seats/${seatAccountCode}`,
  );
}

export function getSubscriptionOffers(promoCode = false) {
  if (promoCode) {
    return goodBitsClient().get(
      `/offers/v1/subscriptions/?promoCode=${promoCode}`,
    );
  }
  return goodBitsClient().get("/offers/v1/subscriptions");
}

export function validateVoucher(voucherCode, subscriptionName, currencyCode) {
  const params = {
    code: voucherCode,
    subscriptionName,
    currencyCode,
  };
  return goodBitsClient().post("/subscriptions/v3/voucher/validate", params);
}

// Update getSubscriptionDashboard to handle the new API response
export async function getSubscriptionDashboard() {
  try {
    const response = await goodBitsClient().get(
      "/subscriptions-bff/v1/subscriptions",
    );
    // Ensure we always return an object with items array
    return {
      data: {
        items: response?.data?.items || [],
      },
    };
  } catch (error) {
    console.error("Error fetching subscription dashboard:", error);
    // Return empty items array to prevent null reference errors
    return {
      data: {
        items: [],
      },
    };
  }
}

export function addPaymentSession(returnUrl) {
  return goodBitsClient().post("/subscriptions/v3/payment/sessions", {
    returnUrl,
  });
}

export async function getPaymentSession() {
  try {
    const response = await goodBitsClient().get(
      "/subscriptions-bff/v1/payment-method/status",
    );
    return {
      data: [
        {
          sessionId: response?.data?.sessionId,
          status: response?.data?.paymentStatus,
          sessionData: response?.data?.sessionData,
          expiresAt: response?.data?.sessionExpiresAt,
          reference: response?.data?.paymentReference,
        },
      ],
    };
  } catch (error) {
    console.error("Error in getPaymentSession:", error);
    return {
      data: [],
    };
  }
}

export function updateAddressInfo(data) {
  // Transform the incoming data to match the new API structure
  const payload = {
    firstName: data.fullName?.split(" ")[0] || "",
    lastName: data.fullName?.split(" ").slice(1).join(" ") || "",
    address: {
      addressLine1: data.street || "",
      postalCode: data.postalCode || "",
      city: data.city || "",
      countryCode: data.country || "",
    },
  };

  return goodBitsClient().put(
    "/subscriptions-bff/v1/billing-information",
    payload,
  );
}

export async function getInvoices() {
  try {
    return await goodBitsClient().get("/subscriptions-bff/v1/invoices");
  } catch (error) {
    console.error("Error in getInvoices:", error);
    return {
      data: [],
    };
  }
}

export function getInvoice(invoiceId) {
  return goodBitsClient().get(`/subscriptions/v3/invoices/${invoiceId}`, {
    params: { expand: "pdf" },
  });
}

export function getInvoicePdf(invoiceId) {
  return goodBitsClient().get(
    `/invoices/v1/subscription-invoices/${invoiceId}/pdf`,
  );
}

export function cancelSubscription(subscriptionId) {
  return goodBitsClient().post(`/subscriptions/v3/cancel`, { subscriptionId });
}

export function getBusinessSeats(businessAccountCode) {
  return goodBitsClient().get(
    `/subscriptions/v3/business/${businessAccountCode}/seats`,
  );
}

export const getSmbSubscription = async (email) => {
  const smbData = await searchBusinessAccounts({ email })
    .then((res) => res.data)
    .then((res) => res[0])
    .catch((e) => {
      console.log({ e });
      return null;
    });

  if (smbData?.isParent) {
    const seats = await getBusinessSeats(smbData.code)
      .then((res) => res.data)
      .then((res) => res.seats)
      .catch((e) => {
        console.log({ e });
        return null;
      });

    return { ...smbData, seats };
  }

  return smbData;
};
