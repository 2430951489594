const useTracking = () => {
  const config = useRuntimeConfig();
  const { locale } = useI18n();

  function trackPageInfo(payload) {
    if (import.meta.server) return;

    window.cmtr;

    try {
      cmtr.pageInfo(
        payload.pageType || "generalPage",
        payload.ecommerceStage || "none",
        payload.pageTitle || "cms page",
        payload.pageTitle || "cms page",
        cfLocation(locale.value),
        config.public.stage,
        payload.version || null,
      );
    } catch (error) {
      console.log(error);
    }
  }

  function trackUserInfo(user) {
    if (import.meta.server) return;

    window.cmtr;

    try {
      cmtr.callDDM("user.info", { user });
    } catch (error) {
      console.log(error);
    }
  }
  function track404() {
    if (import.meta.server) return;

    window.cmtr;
    try {
      cmtr.callDDM("exception", {
        value: "404",
        referrer: location.href,
        environment: "nuxt3",
      });
    } catch (error) {
      console.log(error);
    }
  }

  function trackMainNavi(text) {
    if (import.meta.server) return;

    window.cmtr;
    try {
      cmtr.callDDM("click", {
        category: "header navigation",
        label: text,
      });
    } catch (error) {
      console.log(error);
    }
  }

  function trackButton(label, category) {
    if (import.meta.server) return;

    window.cmtr;

    try {
      cmtr.callDDM("click", {
        category,
        label,
      });
    } catch (error) {
      console.log(error);
    }
  }

  function trackInputInvalid(text, form, field) {
    if (import.meta.server) return;

    window.cmtr;
    try {
      cmtr.callDDM("form_message", {
        referrer: location.href,
        text,
        form_name: form,
        formfield_name: field,
        environment: "nuxt3",
      });
    } catch (error) {
      console.log(error);
    }
  }

  return {
    trackPageInfo,
    trackUserInfo,
    track404,
    trackMainNavi,
    trackInputInvalid,
    trackButton,
  };
};

export default useTracking;
