import { goodbitsApi } from "~/shared/utils/goodbits";
import { COOKIE_ID_TOKEN } from "~/shared/constants";

export default defineNuxtPlugin({
  name: "goodbits-api",
  parallel: true,
  setup() {
    const _token = useCookie(COOKIE_ID_TOKEN);

    return {
      provide: {
        goodbits: goodbitsApi(_token.value),
      },
    };
  },
});
