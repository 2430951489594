import { validateEmail, validatePhone, validatePassword } from "./validators";

export const validateInput = (model, inputName, required = true, $t) => {
  const input = model[inputName];

  if (input) {
    if (input.changed) {
      const validation = getInputValidation(inputName, input.value, required);

      if (validation) {
        input.error =
          validation === true ? input.serverError || false : $t(validation);
      }
    }

    return input.changed
      ? input.error === false
      : input.serverError
        ? false
        : null;
  }

  return null;
};

export const getInputValidationMessage = (inputName, value, required) => {
  if (!required && !value) return "website.form.smsConsent.info";

  if (inputName.includes("phone")) {
    return validatePhone(value)
      ? "website.form.smsConsent.info"
      : "website.form.error.phone_invalid";
  }
};

export const getInputValidation = (inputName, value, required) => {
  if (required && !value) return "website.form.requiredValue";

  if (inputName.includes("email")) {
    return validateEmail(value) ? null : "website.form.error.email_invalid";
  }

  if (inputName.includes("phone")) {
    return validatePhone(value) ? null : "website.form.error.phone_invalid";
  }

  if (inputName.includes("password")) {
    return validatePassword(value)
      ? null
      : "website.form.error.password_invalid";
  }

  return null;
};
