import { screens } from "vue-screen-utils";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(screens, {
    mobile: "0px",
    tablet: "640px",
    laptop: "1024px",
    desktop: "1280px",
  });
});
