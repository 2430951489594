
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93Rjv7UxL0OOlhZt8L5_8DEih40jHo4HLKU5MvHhdVyd8Meta } from "/home/vsts/work/1/s/apps/lobby/pages/[...slug].vue?macro=true";
import { default as addonssV88Rcxkb6UjN5xONUR71bxqNwaev2cFaM9fwshzs_MMeta } from "/home/vsts/work/1/s/apps/lobby/pages/app/addons.vue?macro=true";
import { default as detailsu4U84_45ZMxlTRHGj8eo_45LUb581GbjPJlF8A7n5kpLvu0Meta } from "/home/vsts/work/1/s/apps/lobby/pages/app/details.vue?macro=true";
import { default as indexLQPkM5ZRFO_KDP1rDkI9jwEFMty67JkZXYnr_B6efewMeta } from "/home/vsts/work/1/s/apps/lobby/pages/app/index.vue?macro=true";
import { default as membership_45confirmationwiibVGPilUwRoFiGsSxrOLM6pkfC69f_45cHDj63E_459YgMeta } from "/home/vsts/work/1/s/apps/lobby/pages/app/membership-confirmation.vue?macro=true";
import { default as membership_45detailsKkZjeZbFNfGqyT74v163W961qTLP3V9nqHpWiZFgqkoMeta } from "/home/vsts/work/1/s/apps/lobby/pages/app/membership-details.vue?macro=true";
import { default as membership_45paymentVSva1QhCPSZWyZ86_45JHB3Wr7etVEUyr5mKFVEUHYN44Meta } from "/home/vsts/work/1/s/apps/lobby/pages/app/membership-payment.vue?macro=true";
import { default as paymentyhUdcAxwD0jYJpTIYt0YpfbA_45raIB5nmuIRDiq_45U9DoMeta } from "/home/vsts/work/1/s/apps/lobby/pages/app/payment.vue?macro=true";
import { default as ratessib0dI3KocmzZpdHzW_451QyAiU91_WuK7xWpAYgSM8VoMeta } from "/home/vsts/work/1/s/apps/lobby/pages/app/rates.vue?macro=true";
import { default as _91_46_46_46slug_93Khs45JrKR50Wf5g_u8Rh5_45N6DYQFO324aNYr4t1zdXwMeta } from "/home/vsts/work/1/s/apps/lobby/pages/blog/[...slug].vue?macro=true";
import { default as addonsJ3REwvX94kAxlZj0IXwSJU6Lybw8F65_45goigIG9aJiEMeta } from "/home/vsts/work/1/s/apps/lobby/pages/book/addons.vue?macro=true";
import { default as confirmationnS2YhDkPH8PeZZRkABpU17q3b7iqQplPn9qphtf05OgMeta } from "/home/vsts/work/1/s/apps/lobby/pages/book/confirmation.vue?macro=true";
import { default as detailsswue2F29sklM13eVXL0fPWiUTWg7e8cvBqf7iMGSvOcMeta } from "/home/vsts/work/1/s/apps/lobby/pages/book/details.vue?macro=true";
import { default as indexQGgD0lJgsoOAu6_45M_DBm1nagfeqLG1BuXTbp9_YhZvkMeta } from "/home/vsts/work/1/s/apps/lobby/pages/book/index.vue?macro=true";
import { default as membership_45confirmationQorjMzBoi__45UxpzdB90Z01pw6WKisqtAABS84hHOfC0Meta } from "/home/vsts/work/1/s/apps/lobby/pages/book/membership-confirmation.vue?macro=true";
import { default as membership_45detailsB_5KLcCWxRQtitj4UAuAUpRfonXPWXpcTLKushvYaSkMeta } from "/home/vsts/work/1/s/apps/lobby/pages/book/membership-details.vue?macro=true";
import { default as membership_45paymentckGhjffK96C_mGQreS16omc9A3ErRXzBgQ86XxlleN0Meta } from "/home/vsts/work/1/s/apps/lobby/pages/book/membership-payment.vue?macro=true";
import { default as _91shoppingCartId_93_bPKXSAoK5_Yx_x6IBGrihjAHCzo_yXbnqn2PT3SncUMeta } from "/home/vsts/work/1/s/apps/lobby/pages/book/payment-pending/[shoppingCartId].vue?macro=true";
import { default as paymentQj0R2uKdnGnAKkkwmGgSFl7Xd_O02hgJT_45Q4SJwIyUEMeta } from "/home/vsts/work/1/s/apps/lobby/pages/book/payment.vue?macro=true";
import { default as ratesNDpVmqXVKictRzI6El9EmT48CQeZt8xuHLp00TDzEpIMeta } from "/home/vsts/work/1/s/apps/lobby/pages/book/rates.vue?macro=true";
import { default as smb_45membership_45detailssGFmGfYKXW_45E6Y8dw5U5yCyD4TYZyS__45vZMINixtjysMeta } from "/home/vsts/work/1/s/apps/lobby/pages/book/smb-membership-details.vue?macro=true";
import { default as _91featureSlug_93HPcPNVIHOade6VrD0xVtq_IsnVFdXUEoRVaQW0c4xUAMeta } from "/home/vsts/work/1/s/apps/lobby/pages/coworking/[continent]/[city]/[featureSlug].vue?macro=true";
import { default as demoO4tnhpuVKKDPnEgVMf_458exfEyW65PDhrUpeDHjo8PhcMeta } from "/home/vsts/work/1/s/apps/lobby/pages/demo.vue?macro=true";
import { default as indexu0gEwtOEZRkiAR0pQibU_ibsoPRgoRh1X3fkqE5UqBoMeta } from "/home/vsts/work/1/s/apps/lobby/pages/get-booking/index.vue?macro=true";
import { default as _91invoiceId_93KMcFAMwqgGC4dZSe1fetVhqJFlGXRKfRb7dW7Dpx_HYMeta } from "/home/vsts/work/1/s/apps/lobby/pages/get-booking/invoice/[reservationId]/[invoiceId].vue?macro=true";
import { default as login_45bookingnumberRJ_45UDh7AMA_45rItC_45T0I5LtbnbS0yZLpOgTA5JEB5S0wMeta } from "/home/vsts/work/1/s/apps/lobby/pages/get-booking/login-bookingnumber.vue?macro=true";
import { default as reservation_45paymenteuHsJJbo1NdHM8Sid8_QOmxGUJPPhMsQTru9gDPB0FEMeta } from "/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservation-payment.vue?macro=true";
import { default as reservationvepzWaT5RYLy5e9BqrCiFIBGYFyFNdH8XjjH99FDJtYMeta } from "/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservation.vue?macro=true";
import { default as reservationsbBH3jSKKWsfpGWU2252l4p45NJ1OPI4PLcPWAs82yAcMeta } from "/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservations.vue?macro=true";
import { default as groups_45form_45new9n_45SkitfmfA57xZWbgP0gXcdOtjGH8FMASgaiRy9pucMeta } from "/home/vsts/work/1/s/apps/lobby/pages/groups-form-new.vue?macro=true";
import { default as groups_45form_exXhN44FjRSO_V2Z6_45Cq7aILawoHa4eKqhro2Y8XbUMeta } from "/home/vsts/work/1/s/apps/lobby/pages/groups-form.vue?macro=true";
import { default as _91featureSlug_93DTBuO8n_45ZdmKiiCH5DUmb7_nSeUGO_458iNkHMziOjVCAMeta } from "/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/[hotelSlug]/[featureSlug].vue?macro=true";
import { default as indexSZxXywqeij2kG_45SvWDyRJpNt3_S6Wva7hVIc3kfxI8AMeta } from "/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/[hotelSlug]/index.vue?macro=true";
import { default as index4udXwgIBvpGLzcKqVPlpI3NqWcoz7PEEwkWKfGOvIHkMeta } from "/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/index.vue?macro=true";
import { default as indexihsySTZ55Sv6glIFl0QOblCUlpDvlcMX0UZ2WnVNWYwMeta } from "/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/index.vue?macro=true";
import { default as indexmgQj0Imk6m37KdFvqMHQjdoFPr53CXSiByExd5nAtsIMeta } from "/home/vsts/work/1/s/apps/lobby/pages/hotels/index.vue?macro=true";
import { default as _91_46_46_46slug_93PpEffV9n3BzFCl1pO6Y8z75CWHJfHZpf1V_45ei1huYHQMeta } from "/home/vsts/work/1/s/apps/lobby/pages/legal/[...slug].vue?macro=true";
import { default as login_45_e4y_45KrpLR5YCevTDbrt6Zqxsk3PPimidGSqEMVj_wMeta } from "/home/vsts/work/1/s/apps/lobby/pages/login.vue?macro=true";
import { default as _91_46_46_46meetingRoomSlug_93F3ddzgXqzLoWQGwhFizE27BjJT3_ZulGDuyZ8xVmYboMeta } from "/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[...meetingRoomSlug].vue?macro=true";
import { default as addonsqNZTES8EurPXDD8FjKuWrH9YoPM4IbwBVrkrhryd_qcMeta } from "/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/addons.vue?macro=true";
import { default as confirmation__452h2SR5Y8WG4HCOkOwgVC9xUe0P6DNX54k6SrCzrL8Meta } from "/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/confirmation.vue?macro=true";
import { default as detailsJBzNhzJl2_45gYw9Wh11mM2_FeV6A03QIQhjArAO9EpgwMeta } from "/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/details.vue?macro=true";
import { default as payment6xhPZPVXTVqA_451x74RWRZm11D0x_45EStebuSEDK0wcxoMeta } from "/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/payment.vue?macro=true";
import { default as switchJVMU7rzN2d43ouBg19GaPWxjL4U9jEderv2JrSHvXAsMeta } from "/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/switch.vue?macro=true";
import { default as testbz_455RgMJIH6OuDb4_hnevv16y4j0TRJgQfxJ3IhenZQMeta } from "/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/test.vue?macro=true";
import { default as booking_45_91reservationId_93EDT3HbbAvLsonTnqhjO6IyPkx6o6q6Dz_sGOfoI9mDUMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/booking-[reservationId].vue?macro=true";
import { default as dashboardB6Bfhf2CSdgVB3RefTwP2J4EFEcELLAhvMapyF8ENZYMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/dashboard.vue?macro=true";
import { default as indexGLINSKMgBfa7K_KgGQMyv4_AUveDmLg1MOl6nEPYkpsMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/index.vue?macro=true";
import { default as _91invoiceId_936u9Ft_45mo8gG_45XYxM_45ErPleMvwa1gfvxikcvJHXg86roMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/invoice/[reservationId]/[invoiceId].vue?macro=true";
import { default as logouthIE09NwvoVp_mGnvFQSTeV5WMGXYxiXE2_457XCf1GezwMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/logout.vue?macro=true";
import { default as _91reservationCode_93D5gfVikkRf25fmCauanwLX6uCUkTHAA7o7gpcFdrMBMMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/meeting-rooms/[reservationCode].vue?macro=true";
import { default as indexKDwMCMdgEDJcsi2sa4XyBwSn1QJay834cDs0rtrWbhMMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/meeting-rooms/index.vue?macro=true";
import { default as membership_45manage_j_455nrwxiyPx_45cudDYaL0b47um8_ptFNjIzJN9E7rLUMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/membership-manage.vue?macro=true";
import { default as membership_45teammates_45inviteBje8Q99QJrNJgRzK0VCmsXrrhHaICqHMQrzbW56kUgEMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/membership-teammates-invite.vue?macro=true";
import { default as indexHqR9TRZzl9Y1bj6WTqMQbU1FdwTJVa7RtDf3_45wOnBqMMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/membership/index.vue?macro=true";
import { default as invoicesBGNRxV9jJ_RAfIiQmOAOGbk0_453dSDo38hCYyyoVZfSYMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/membership/invoices.vue?macro=true";
import { default as payment_45mbgkhDYuUGA0E8xmhl074G1wlY3fUJwLWJVfPmuk_gMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/membership/payment.vue?macro=true";
import { default as payment_45_91reservationId_93_cjgOcQQ5jrjxPnbrDQNU0rTVf5Bk9askvMkBAMJPvwMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/payment-[reservationId].vue?macro=true";
import { default as profile4Je7J0TCUuKif_45rVVPSIkZuHp7hdbNbh3IvVkeFTyDoMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/profile.vue?macro=true";
import { default as reservation_45_91reservationId_93J6zWkUngtI3NqJ80DNXmgdvJS9PJwmqmJPDiNJSAi1oMeta } from "/home/vsts/work/1/s/apps/lobby/pages/my/reservation-[reservationId].vue?macro=true";
import { default as reservationstcm_45L_45QWZckdV2qYeaQm8WxhMmTYH4Dtk5JEHJFSNj8Meta } from "/home/vsts/work/1/s/apps/lobby/pages/my/reservations.vue?macro=true";
import { default as pingbbRaBU9tjFVyZCtgcSCj7SaHbRp0P9M4XUZK5GAAPUQMeta } from "/home/vsts/work/1/s/apps/lobby/pages/ping.vue?macro=true";
import { default as ping3LOthTh_45ZuIsdYfhkOqBQ00IWXiYcP0t8d9DW6BWw7HsMeta } from "/home/vsts/work/1/s/apps/lobby/pages/ping3.vue?macro=true";
import { default as registervvieI_45Z3tXnX38HovY8jbLTZwdohfAlPTFp2yKI8eXsMeta } from "/home/vsts/work/1/s/apps/lobby/pages/register.vue?macro=true";
import { default as _91featureSlug_93SczqV8HSGv1_ZSUVnza9NYFTNGvg181DdsesCPLP_455cMeta } from "/home/vsts/work/1/s/apps/lobby/pages/rooftop-bars/[continent]/[city]/[featureSlug].vue?macro=true";
import { default as test_45iconsqmhX99A1cqTzaZo_459O2iqooOXie1JyUFcXZia99S_459cMeta } from "/home/vsts/work/1/s/apps/lobby/pages/test-icons.vue?macro=true";
import { default as testXABh4n36OI5RaAu6uz_IK9aMarpWJZoH3l_elm43TQQMeta } from "/home/vsts/work/1/s/apps/lobby/pages/test.vue?macro=true";
import { default as component_45stubm_qj6vEeBTUaAb1znCYox_45H8h0i1bl0al_45HHgP7gFVYMeta } from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.3.1_eslint@9.22.0_jiti@2.4_0a10e4b1150148363c7327f78562b5b8/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubm_qj6vEeBTUaAb1znCYox_45H8h0i1bl0al_45HHgP7gFVY } from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@20.17.24_db0@0.3.1_eslint@9.22.0_jiti@2.4_0a10e4b1150148363c7327f78562b5b8/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93Rjv7UxL0OOlhZt8L5_8DEih40jHo4HLKU5MvHhdVyd8Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/[...slug].vue")
  },
  {
    name: "slug___nl",
    path: "/nl/:slug(.*)*",
    meta: _91_46_46_46slug_93Rjv7UxL0OOlhZt8L5_8DEih40jHo4HLKU5MvHhdVyd8Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/[...slug].vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    meta: _91_46_46_46slug_93Rjv7UxL0OOlhZt8L5_8DEih40jHo4HLKU5MvHhdVyd8Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/[...slug].vue")
  },
  {
    name: "slug___zh",
    path: "/zh/:slug(.*)*",
    meta: _91_46_46_46slug_93Rjv7UxL0OOlhZt8L5_8DEih40jHo4HLKU5MvHhdVyd8Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/[...slug].vue")
  },
  {
    name: "slug___it",
    path: "/it/:slug(.*)*",
    meta: _91_46_46_46slug_93Rjv7UxL0OOlhZt8L5_8DEih40jHo4HLKU5MvHhdVyd8Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/[...slug].vue")
  },
  {
    name: "slug___es",
    path: "/es/:slug(.*)*",
    meta: _91_46_46_46slug_93Rjv7UxL0OOlhZt8L5_8DEih40jHo4HLKU5MvHhdVyd8Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/[...slug].vue")
  },
  {
    name: "app-addons___en",
    path: "/app/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/addons.vue")
  },
  {
    name: "app-addons___nl",
    path: "/nl/app/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/addons.vue")
  },
  {
    name: "app-addons___fr",
    path: "/fr/app/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/addons.vue")
  },
  {
    name: "app-addons___zh",
    path: "/zh/app/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/addons.vue")
  },
  {
    name: "app-addons___it",
    path: "/it/app/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/addons.vue")
  },
  {
    name: "app-addons___es",
    path: "/es/app/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/addons.vue")
  },
  {
    name: "app-details___en",
    path: "/app/details",
    meta: detailsu4U84_45ZMxlTRHGj8eo_45LUb581GbjPJlF8A7n5kpLvu0Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/details.vue")
  },
  {
    name: "app-details___nl",
    path: "/nl/app/details",
    meta: detailsu4U84_45ZMxlTRHGj8eo_45LUb581GbjPJlF8A7n5kpLvu0Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/details.vue")
  },
  {
    name: "app-details___fr",
    path: "/fr/app/details",
    meta: detailsu4U84_45ZMxlTRHGj8eo_45LUb581GbjPJlF8A7n5kpLvu0Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/details.vue")
  },
  {
    name: "app-details___zh",
    path: "/zh/app/details",
    meta: detailsu4U84_45ZMxlTRHGj8eo_45LUb581GbjPJlF8A7n5kpLvu0Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/details.vue")
  },
  {
    name: "app-details___it",
    path: "/it/app/details",
    meta: detailsu4U84_45ZMxlTRHGj8eo_45LUb581GbjPJlF8A7n5kpLvu0Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/details.vue")
  },
  {
    name: "app-details___es",
    path: "/es/app/details",
    meta: detailsu4U84_45ZMxlTRHGj8eo_45LUb581GbjPJlF8A7n5kpLvu0Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/details.vue")
  },
  {
    name: "app___en",
    path: "/app",
    meta: indexLQPkM5ZRFO_KDP1rDkI9jwEFMty67JkZXYnr_B6efewMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/index.vue")
  },
  {
    name: "app___nl",
    path: "/nl/app",
    meta: indexLQPkM5ZRFO_KDP1rDkI9jwEFMty67JkZXYnr_B6efewMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/index.vue")
  },
  {
    name: "app___fr",
    path: "/fr/app",
    meta: indexLQPkM5ZRFO_KDP1rDkI9jwEFMty67JkZXYnr_B6efewMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/index.vue")
  },
  {
    name: "app___zh",
    path: "/zh/app",
    meta: indexLQPkM5ZRFO_KDP1rDkI9jwEFMty67JkZXYnr_B6efewMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/index.vue")
  },
  {
    name: "app___it",
    path: "/it/app",
    meta: indexLQPkM5ZRFO_KDP1rDkI9jwEFMty67JkZXYnr_B6efewMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/index.vue")
  },
  {
    name: "app___es",
    path: "/es/app",
    meta: indexLQPkM5ZRFO_KDP1rDkI9jwEFMty67JkZXYnr_B6efewMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/index.vue")
  },
  {
    name: "app-membership-confirmation___en",
    path: "/app/membership-confirmation",
    meta: membership_45confirmationwiibVGPilUwRoFiGsSxrOLM6pkfC69f_45cHDj63E_459YgMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-confirmation.vue")
  },
  {
    name: "app-membership-confirmation___nl",
    path: "/nl/app/membership-confirmation",
    meta: membership_45confirmationwiibVGPilUwRoFiGsSxrOLM6pkfC69f_45cHDj63E_459YgMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-confirmation.vue")
  },
  {
    name: "app-membership-confirmation___fr",
    path: "/fr/app/membership-confirmation",
    meta: membership_45confirmationwiibVGPilUwRoFiGsSxrOLM6pkfC69f_45cHDj63E_459YgMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-confirmation.vue")
  },
  {
    name: "app-membership-confirmation___zh",
    path: "/zh/app/membership-confirmation",
    meta: membership_45confirmationwiibVGPilUwRoFiGsSxrOLM6pkfC69f_45cHDj63E_459YgMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-confirmation.vue")
  },
  {
    name: "app-membership-confirmation___it",
    path: "/it/app/membership-confirmation",
    meta: membership_45confirmationwiibVGPilUwRoFiGsSxrOLM6pkfC69f_45cHDj63E_459YgMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-confirmation.vue")
  },
  {
    name: "app-membership-confirmation___es",
    path: "/es/app/membership-confirmation",
    meta: membership_45confirmationwiibVGPilUwRoFiGsSxrOLM6pkfC69f_45cHDj63E_459YgMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-confirmation.vue")
  },
  {
    name: "app-membership-details___en",
    path: "/app/membership-details",
    meta: membership_45detailsKkZjeZbFNfGqyT74v163W961qTLP3V9nqHpWiZFgqkoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-details.vue")
  },
  {
    name: "app-membership-details___nl",
    path: "/nl/app/membership-details",
    meta: membership_45detailsKkZjeZbFNfGqyT74v163W961qTLP3V9nqHpWiZFgqkoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-details.vue")
  },
  {
    name: "app-membership-details___fr",
    path: "/fr/app/membership-details",
    meta: membership_45detailsKkZjeZbFNfGqyT74v163W961qTLP3V9nqHpWiZFgqkoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-details.vue")
  },
  {
    name: "app-membership-details___zh",
    path: "/zh/app/membership-details",
    meta: membership_45detailsKkZjeZbFNfGqyT74v163W961qTLP3V9nqHpWiZFgqkoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-details.vue")
  },
  {
    name: "app-membership-details___it",
    path: "/it/app/membership-details",
    meta: membership_45detailsKkZjeZbFNfGqyT74v163W961qTLP3V9nqHpWiZFgqkoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-details.vue")
  },
  {
    name: "app-membership-details___es",
    path: "/es/app/membership-details",
    meta: membership_45detailsKkZjeZbFNfGqyT74v163W961qTLP3V9nqHpWiZFgqkoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-details.vue")
  },
  {
    name: "app-membership-payment___en",
    path: "/app/membership-payment",
    meta: membership_45paymentVSva1QhCPSZWyZ86_45JHB3Wr7etVEUyr5mKFVEUHYN44Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-payment.vue")
  },
  {
    name: "app-membership-payment___nl",
    path: "/nl/app/membership-payment",
    meta: membership_45paymentVSva1QhCPSZWyZ86_45JHB3Wr7etVEUyr5mKFVEUHYN44Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-payment.vue")
  },
  {
    name: "app-membership-payment___fr",
    path: "/fr/app/membership-payment",
    meta: membership_45paymentVSva1QhCPSZWyZ86_45JHB3Wr7etVEUyr5mKFVEUHYN44Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-payment.vue")
  },
  {
    name: "app-membership-payment___zh",
    path: "/zh/app/membership-payment",
    meta: membership_45paymentVSva1QhCPSZWyZ86_45JHB3Wr7etVEUyr5mKFVEUHYN44Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-payment.vue")
  },
  {
    name: "app-membership-payment___it",
    path: "/it/app/membership-payment",
    meta: membership_45paymentVSva1QhCPSZWyZ86_45JHB3Wr7etVEUyr5mKFVEUHYN44Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-payment.vue")
  },
  {
    name: "app-membership-payment___es",
    path: "/es/app/membership-payment",
    meta: membership_45paymentVSva1QhCPSZWyZ86_45JHB3Wr7etVEUyr5mKFVEUHYN44Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/membership-payment.vue")
  },
  {
    name: "app-payment___en",
    path: "/app/payment",
    meta: paymentyhUdcAxwD0jYJpTIYt0YpfbA_45raIB5nmuIRDiq_45U9DoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/payment.vue")
  },
  {
    name: "app-payment___nl",
    path: "/nl/app/payment",
    meta: paymentyhUdcAxwD0jYJpTIYt0YpfbA_45raIB5nmuIRDiq_45U9DoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/payment.vue")
  },
  {
    name: "app-payment___fr",
    path: "/fr/app/payment",
    meta: paymentyhUdcAxwD0jYJpTIYt0YpfbA_45raIB5nmuIRDiq_45U9DoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/payment.vue")
  },
  {
    name: "app-payment___zh",
    path: "/zh/app/payment",
    meta: paymentyhUdcAxwD0jYJpTIYt0YpfbA_45raIB5nmuIRDiq_45U9DoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/payment.vue")
  },
  {
    name: "app-payment___it",
    path: "/it/app/payment",
    meta: paymentyhUdcAxwD0jYJpTIYt0YpfbA_45raIB5nmuIRDiq_45U9DoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/payment.vue")
  },
  {
    name: "app-payment___es",
    path: "/es/app/payment",
    meta: paymentyhUdcAxwD0jYJpTIYt0YpfbA_45raIB5nmuIRDiq_45U9DoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/payment.vue")
  },
  {
    name: "app-rates___en",
    path: "/app/rates",
    meta: ratessib0dI3KocmzZpdHzW_451QyAiU91_WuK7xWpAYgSM8VoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/rates.vue")
  },
  {
    name: "app-rates___nl",
    path: "/nl/app/rates",
    meta: ratessib0dI3KocmzZpdHzW_451QyAiU91_WuK7xWpAYgSM8VoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/rates.vue")
  },
  {
    name: "app-rates___fr",
    path: "/fr/app/rates",
    meta: ratessib0dI3KocmzZpdHzW_451QyAiU91_WuK7xWpAYgSM8VoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/rates.vue")
  },
  {
    name: "app-rates___zh",
    path: "/zh/app/rates",
    meta: ratessib0dI3KocmzZpdHzW_451QyAiU91_WuK7xWpAYgSM8VoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/rates.vue")
  },
  {
    name: "app-rates___it",
    path: "/it/app/rates",
    meta: ratessib0dI3KocmzZpdHzW_451QyAiU91_WuK7xWpAYgSM8VoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/rates.vue")
  },
  {
    name: "app-rates___es",
    path: "/es/app/rates",
    meta: ratessib0dI3KocmzZpdHzW_451QyAiU91_WuK7xWpAYgSM8VoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/app/rates.vue")
  },
  {
    name: "blog-slug___en",
    path: "/blog/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___nl",
    path: "/nl/blog/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blog/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___zh",
    path: "/zh/blog/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___it",
    path: "/it/blog/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___es",
    path: "/es/blog/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/blog/[...slug].vue")
  },
  {
    name: "book-addons___en",
    path: "/book/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/addons.vue")
  },
  {
    name: "book-addons___nl",
    path: "/nl/book/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/addons.vue")
  },
  {
    name: "book-addons___fr",
    path: "/fr/book/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/addons.vue")
  },
  {
    name: "book-addons___zh",
    path: "/zh/book/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/addons.vue")
  },
  {
    name: "book-addons___it",
    path: "/it/book/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/addons.vue")
  },
  {
    name: "book-addons___es",
    path: "/es/book/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/addons.vue")
  },
  {
    name: "book-confirmation___en",
    path: "/book/confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/confirmation.vue")
  },
  {
    name: "book-confirmation___nl",
    path: "/nl/book/confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/confirmation.vue")
  },
  {
    name: "book-confirmation___fr",
    path: "/fr/book/confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/confirmation.vue")
  },
  {
    name: "book-confirmation___zh",
    path: "/zh/book/confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/confirmation.vue")
  },
  {
    name: "book-confirmation___it",
    path: "/it/book/confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/confirmation.vue")
  },
  {
    name: "book-confirmation___es",
    path: "/es/book/confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/confirmation.vue")
  },
  {
    name: "book-details___en",
    path: "/book/details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/details.vue")
  },
  {
    name: "book-details___nl",
    path: "/nl/book/details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/details.vue")
  },
  {
    name: "book-details___fr",
    path: "/fr/book/details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/details.vue")
  },
  {
    name: "book-details___zh",
    path: "/zh/book/details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/details.vue")
  },
  {
    name: "book-details___it",
    path: "/it/book/details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/details.vue")
  },
  {
    name: "book-details___es",
    path: "/es/book/details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/details.vue")
  },
  {
    name: "book___en",
    path: "/book",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/index.vue")
  },
  {
    name: "book___nl",
    path: "/nl/book",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/index.vue")
  },
  {
    name: "book___fr",
    path: "/fr/book",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/index.vue")
  },
  {
    name: "book___zh",
    path: "/zh/book",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/index.vue")
  },
  {
    name: "book___it",
    path: "/it/book",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/index.vue")
  },
  {
    name: "book___es",
    path: "/es/book",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/index.vue")
  },
  {
    name: "book-membership-confirmation___en",
    path: "/book/membership-confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-confirmation.vue")
  },
  {
    name: "book-membership-confirmation___nl",
    path: "/nl/book/membership-confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-confirmation.vue")
  },
  {
    name: "book-membership-confirmation___fr",
    path: "/fr/book/membership-confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-confirmation.vue")
  },
  {
    name: "book-membership-confirmation___zh",
    path: "/zh/book/membership-confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-confirmation.vue")
  },
  {
    name: "book-membership-confirmation___it",
    path: "/it/book/membership-confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-confirmation.vue")
  },
  {
    name: "book-membership-confirmation___es",
    path: "/es/book/membership-confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-confirmation.vue")
  },
  {
    name: "book-membership-details___en",
    path: "/book/membership-details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-details.vue")
  },
  {
    name: "book-membership-details___nl",
    path: "/nl/book/membership-details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-details.vue")
  },
  {
    name: "book-membership-details___fr",
    path: "/fr/book/membership-details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-details.vue")
  },
  {
    name: "book-membership-details___zh",
    path: "/zh/book/membership-details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-details.vue")
  },
  {
    name: "book-membership-details___it",
    path: "/it/book/membership-details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-details.vue")
  },
  {
    name: "book-membership-details___es",
    path: "/es/book/membership-details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-details.vue")
  },
  {
    name: "book-membership-payment___en",
    path: "/book/membership-payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-payment.vue")
  },
  {
    name: "book-membership-payment___nl",
    path: "/nl/book/membership-payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-payment.vue")
  },
  {
    name: "book-membership-payment___fr",
    path: "/fr/book/membership-payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-payment.vue")
  },
  {
    name: "book-membership-payment___zh",
    path: "/zh/book/membership-payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-payment.vue")
  },
  {
    name: "book-membership-payment___it",
    path: "/it/book/membership-payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-payment.vue")
  },
  {
    name: "book-membership-payment___es",
    path: "/es/book/membership-payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/membership-payment.vue")
  },
  {
    name: "book-payment-pending-shoppingCartId___en",
    path: "/book/payment-pending/:shoppingCartId()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/payment-pending/[shoppingCartId].vue")
  },
  {
    name: "book-payment-pending-shoppingCartId___nl",
    path: "/nl/book/payment-pending/:shoppingCartId()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/payment-pending/[shoppingCartId].vue")
  },
  {
    name: "book-payment-pending-shoppingCartId___fr",
    path: "/fr/book/payment-pending/:shoppingCartId()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/payment-pending/[shoppingCartId].vue")
  },
  {
    name: "book-payment-pending-shoppingCartId___zh",
    path: "/zh/book/payment-pending/:shoppingCartId()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/payment-pending/[shoppingCartId].vue")
  },
  {
    name: "book-payment-pending-shoppingCartId___it",
    path: "/it/book/payment-pending/:shoppingCartId()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/payment-pending/[shoppingCartId].vue")
  },
  {
    name: "book-payment-pending-shoppingCartId___es",
    path: "/es/book/payment-pending/:shoppingCartId()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/payment-pending/[shoppingCartId].vue")
  },
  {
    name: "book-payment___en",
    path: "/book/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/payment.vue")
  },
  {
    name: "book-payment___nl",
    path: "/nl/book/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/payment.vue")
  },
  {
    name: "book-payment___fr",
    path: "/fr/book/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/payment.vue")
  },
  {
    name: "book-payment___zh",
    path: "/zh/book/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/payment.vue")
  },
  {
    name: "book-payment___it",
    path: "/it/book/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/payment.vue")
  },
  {
    name: "book-payment___es",
    path: "/es/book/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/payment.vue")
  },
  {
    name: "book-rates___en",
    path: "/book/rates",
    meta: ratesNDpVmqXVKictRzI6El9EmT48CQeZt8xuHLp00TDzEpIMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/rates.vue")
  },
  {
    name: "book-rates___nl",
    path: "/nl/book/rates",
    meta: ratesNDpVmqXVKictRzI6El9EmT48CQeZt8xuHLp00TDzEpIMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/rates.vue")
  },
  {
    name: "book-rates___fr",
    path: "/fr/book/rates",
    meta: ratesNDpVmqXVKictRzI6El9EmT48CQeZt8xuHLp00TDzEpIMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/rates.vue")
  },
  {
    name: "book-rates___zh",
    path: "/zh/book/rates",
    meta: ratesNDpVmqXVKictRzI6El9EmT48CQeZt8xuHLp00TDzEpIMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/rates.vue")
  },
  {
    name: "book-rates___it",
    path: "/it/book/rates",
    meta: ratesNDpVmqXVKictRzI6El9EmT48CQeZt8xuHLp00TDzEpIMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/rates.vue")
  },
  {
    name: "book-rates___es",
    path: "/es/book/rates",
    meta: ratesNDpVmqXVKictRzI6El9EmT48CQeZt8xuHLp00TDzEpIMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/rates.vue")
  },
  {
    name: "book-smb-membership-details___en",
    path: "/book/smb-membership-details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/smb-membership-details.vue")
  },
  {
    name: "book-smb-membership-details___nl",
    path: "/nl/book/smb-membership-details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/smb-membership-details.vue")
  },
  {
    name: "book-smb-membership-details___fr",
    path: "/fr/book/smb-membership-details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/smb-membership-details.vue")
  },
  {
    name: "book-smb-membership-details___zh",
    path: "/zh/book/smb-membership-details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/smb-membership-details.vue")
  },
  {
    name: "book-smb-membership-details___it",
    path: "/it/book/smb-membership-details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/smb-membership-details.vue")
  },
  {
    name: "book-smb-membership-details___es",
    path: "/es/book/smb-membership-details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/book/smb-membership-details.vue")
  },
  {
    name: "coworking-continent-city-featureSlug___en",
    path: "/coworking/:continent()/:city()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/coworking/[continent]/[city]/[featureSlug].vue")
  },
  {
    name: "coworking-continent-city-featureSlug___nl",
    path: "/nl/coworking/:continent()/:city()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/coworking/[continent]/[city]/[featureSlug].vue")
  },
  {
    name: "coworking-continent-city-featureSlug___fr",
    path: "/fr/coworking/:continent()/:city()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/coworking/[continent]/[city]/[featureSlug].vue")
  },
  {
    name: "coworking-continent-city-featureSlug___zh",
    path: "/zh/coworking/:continent()/:city()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/coworking/[continent]/[city]/[featureSlug].vue")
  },
  {
    name: "coworking-continent-city-featureSlug___it",
    path: "/it/coworking/:continent()/:city()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/coworking/[continent]/[city]/[featureSlug].vue")
  },
  {
    name: "coworking-continent-city-featureSlug___es",
    path: "/es/coworking/:continent()/:city()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/coworking/[continent]/[city]/[featureSlug].vue")
  },
  {
    name: "demo___en",
    path: "/demo",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/demo.vue")
  },
  {
    name: "demo___nl",
    path: "/nl/demo",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/demo.vue")
  },
  {
    name: "demo___fr",
    path: "/fr/demo",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/demo.vue")
  },
  {
    name: "demo___zh",
    path: "/zh/demo",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/demo.vue")
  },
  {
    name: "demo___it",
    path: "/it/demo",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/demo.vue")
  },
  {
    name: "demo___es",
    path: "/es/demo",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/demo.vue")
  },
  {
    name: "get-booking___en",
    path: "/get-booking",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/index.vue")
  },
  {
    name: "get-booking___nl",
    path: "/nl/get-booking",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/index.vue")
  },
  {
    name: "get-booking___fr",
    path: "/fr/get-booking",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/index.vue")
  },
  {
    name: "get-booking___zh",
    path: "/zh/get-booking",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/index.vue")
  },
  {
    name: "get-booking___it",
    path: "/it/get-booking",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/index.vue")
  },
  {
    name: "get-booking___es",
    path: "/es/get-booking",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/index.vue")
  },
  {
    name: "get-booking-invoice-reservationId-invoiceId___en",
    path: "/get-booking/invoice/:reservationId()/:invoiceId()",
    meta: _91invoiceId_93KMcFAMwqgGC4dZSe1fetVhqJFlGXRKfRb7dW7Dpx_HYMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/invoice/[reservationId]/[invoiceId].vue")
  },
  {
    name: "get-booking-invoice-reservationId-invoiceId___nl",
    path: "/nl/get-booking/invoice/:reservationId()/:invoiceId()",
    meta: _91invoiceId_93KMcFAMwqgGC4dZSe1fetVhqJFlGXRKfRb7dW7Dpx_HYMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/invoice/[reservationId]/[invoiceId].vue")
  },
  {
    name: "get-booking-invoice-reservationId-invoiceId___fr",
    path: "/fr/get-booking/invoice/:reservationId()/:invoiceId()",
    meta: _91invoiceId_93KMcFAMwqgGC4dZSe1fetVhqJFlGXRKfRb7dW7Dpx_HYMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/invoice/[reservationId]/[invoiceId].vue")
  },
  {
    name: "get-booking-invoice-reservationId-invoiceId___zh",
    path: "/zh/get-booking/invoice/:reservationId()/:invoiceId()",
    meta: _91invoiceId_93KMcFAMwqgGC4dZSe1fetVhqJFlGXRKfRb7dW7Dpx_HYMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/invoice/[reservationId]/[invoiceId].vue")
  },
  {
    name: "get-booking-invoice-reservationId-invoiceId___it",
    path: "/it/get-booking/invoice/:reservationId()/:invoiceId()",
    meta: _91invoiceId_93KMcFAMwqgGC4dZSe1fetVhqJFlGXRKfRb7dW7Dpx_HYMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/invoice/[reservationId]/[invoiceId].vue")
  },
  {
    name: "get-booking-invoice-reservationId-invoiceId___es",
    path: "/es/get-booking/invoice/:reservationId()/:invoiceId()",
    meta: _91invoiceId_93KMcFAMwqgGC4dZSe1fetVhqJFlGXRKfRb7dW7Dpx_HYMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/invoice/[reservationId]/[invoiceId].vue")
  },
  {
    name: "get-booking-login-bookingnumber___en",
    path: "/get-booking/login-bookingnumber",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/login-bookingnumber.vue")
  },
  {
    name: "get-booking-login-bookingnumber___nl",
    path: "/nl/get-booking/login-bookingnumber",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/login-bookingnumber.vue")
  },
  {
    name: "get-booking-login-bookingnumber___fr",
    path: "/fr/get-booking/login-bookingnumber",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/login-bookingnumber.vue")
  },
  {
    name: "get-booking-login-bookingnumber___zh",
    path: "/zh/get-booking/login-bookingnumber",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/login-bookingnumber.vue")
  },
  {
    name: "get-booking-login-bookingnumber___it",
    path: "/it/get-booking/login-bookingnumber",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/login-bookingnumber.vue")
  },
  {
    name: "get-booking-login-bookingnumber___es",
    path: "/es/get-booking/login-bookingnumber",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/login-bookingnumber.vue")
  },
  {
    name: "get-booking-reservation-payment___en",
    path: "/get-booking/reservation-payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservation-payment.vue")
  },
  {
    name: "get-booking-reservation-payment___nl",
    path: "/nl/get-booking/reservering-payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservation-payment.vue")
  },
  {
    name: "get-booking-reservation-payment___fr",
    path: "/fr/get-booking/reservation-payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservation-payment.vue")
  },
  {
    name: "get-booking-reservation-payment___zh",
    path: "/zh/get-booking/reservation-payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservation-payment.vue")
  },
  {
    name: "get-booking-reservation-payment___it",
    path: "/it/get-booking/prenotazione-payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservation-payment.vue")
  },
  {
    name: "get-booking-reservation-payment___es",
    path: "/es/get-booking/reserva-payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservation-payment.vue")
  },
  {
    name: "get-booking-reservation___en",
    path: "/get-booking/reservation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservation.vue")
  },
  {
    name: "get-booking-reservation___nl",
    path: "/nl/get-booking/reservering",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservation.vue")
  },
  {
    name: "get-booking-reservation___fr",
    path: "/fr/get-booking/reservation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservation.vue")
  },
  {
    name: "get-booking-reservation___zh",
    path: "/zh/get-booking/reservation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservation.vue")
  },
  {
    name: "get-booking-reservation___it",
    path: "/it/get-booking/prenotazione",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservation.vue")
  },
  {
    name: "get-booking-reservation___es",
    path: "/es/get-booking/reserva",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservation.vue")
  },
  {
    name: "get-booking-reservations___en",
    path: "/get-booking/reservations",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservations.vue")
  },
  {
    name: "get-booking-reservations___nl",
    path: "/nl/get-booking/reserveringen",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservations.vue")
  },
  {
    name: "get-booking-reservations___fr",
    path: "/fr/get-booking/reservations",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservations.vue")
  },
  {
    name: "get-booking-reservations___zh",
    path: "/zh/get-booking/reservations",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservations.vue")
  },
  {
    name: "get-booking-reservations___it",
    path: "/it/get-booking/prenotaziones",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservations.vue")
  },
  {
    name: "get-booking-reservations___es",
    path: "/es/get-booking/reservas",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/get-booking/reservations.vue")
  },
  {
    name: "groups-form-new___en",
    path: "/groups-form-new",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/groups-form-new.vue")
  },
  {
    name: "groups-form-new___nl",
    path: "/nl/groups-form-new",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/groups-form-new.vue")
  },
  {
    name: "groups-form-new___fr",
    path: "/fr/groups-form-new",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/groups-form-new.vue")
  },
  {
    name: "groups-form-new___zh",
    path: "/zh/groups-form-new",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/groups-form-new.vue")
  },
  {
    name: "groups-form-new___it",
    path: "/it/groups-form-new",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/groups-form-new.vue")
  },
  {
    name: "groups-form-new___es",
    path: "/es/groups-form-new",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/groups-form-new.vue")
  },
  {
    name: "groups-form___en",
    path: "/groups-form",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/groups-form.vue")
  },
  {
    name: "groups-form___nl",
    path: "/nl/groups-form",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/groups-form.vue")
  },
  {
    name: "groups-form___fr",
    path: "/fr/groups-form",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/groups-form.vue")
  },
  {
    name: "groups-form___zh",
    path: "/zh/groups-form",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/groups-form.vue")
  },
  {
    name: "groups-form___it",
    path: "/it/groups-form",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/groups-form.vue")
  },
  {
    name: "groups-form___es",
    path: "/es/groups-form",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/groups-form.vue")
  },
  {
    name: "hotels-continent-city-hotelSlug-featureSlug___en",
    path: "/hotels/:continent()/:city()/:hotelSlug()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/[hotelSlug]/[featureSlug].vue")
  },
  {
    name: "hotels-continent-city-hotelSlug-featureSlug___nl",
    path: "/nl/hotels/:continent()/:city()/:hotelSlug()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/[hotelSlug]/[featureSlug].vue")
  },
  {
    name: "hotels-continent-city-hotelSlug-featureSlug___fr",
    path: "/fr/hotels/:continent()/:city()/:hotelSlug()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/[hotelSlug]/[featureSlug].vue")
  },
  {
    name: "hotels-continent-city-hotelSlug-featureSlug___zh",
    path: "/zh/hotels/:continent()/:city()/:hotelSlug()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/[hotelSlug]/[featureSlug].vue")
  },
  {
    name: "hotels-continent-city-hotelSlug-featureSlug___it",
    path: "/it/hotels/:continent()/:city()/:hotelSlug()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/[hotelSlug]/[featureSlug].vue")
  },
  {
    name: "hotels-continent-city-hotelSlug-featureSlug___es",
    path: "/es/hoteles/:continent()/:city()/:hotelSlug()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/[hotelSlug]/[featureSlug].vue")
  },
  {
    name: "hotels-continent-city-hotelSlug___en",
    path: "/hotels/:continent()/:city()/:hotelSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/[hotelSlug]/index.vue")
  },
  {
    name: "hotels-continent-city-hotelSlug___nl",
    path: "/nl/hotels/:continent()/:city()/:hotelSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/[hotelSlug]/index.vue")
  },
  {
    name: "hotels-continent-city-hotelSlug___fr",
    path: "/fr/hotels/:continent()/:city()/:hotelSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/[hotelSlug]/index.vue")
  },
  {
    name: "hotels-continent-city-hotelSlug___zh",
    path: "/zh/hotels/:continent()/:city()/:hotelSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/[hotelSlug]/index.vue")
  },
  {
    name: "hotels-continent-city-hotelSlug___it",
    path: "/it/hotels/:continent()/:city()/:hotelSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/[hotelSlug]/index.vue")
  },
  {
    name: "hotels-continent-city-hotelSlug___es",
    path: "/es/hoteles/:continent()/:city()/:hotelSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/[hotelSlug]/index.vue")
  },
  {
    name: "hotels-continent-city___en",
    path: "/hotels/:continent()/:city()",
    meta: index4udXwgIBvpGLzcKqVPlpI3NqWcoz7PEEwkWKfGOvIHkMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/index.vue")
  },
  {
    name: "hotels-continent-city___nl",
    path: "/nl/hotels/:continent()/:city()",
    meta: index4udXwgIBvpGLzcKqVPlpI3NqWcoz7PEEwkWKfGOvIHkMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/index.vue")
  },
  {
    name: "hotels-continent-city___fr",
    path: "/fr/hotels/:continent()/:city()",
    meta: index4udXwgIBvpGLzcKqVPlpI3NqWcoz7PEEwkWKfGOvIHkMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/index.vue")
  },
  {
    name: "hotels-continent-city___zh",
    path: "/zh/hotels/:continent()/:city()",
    meta: index4udXwgIBvpGLzcKqVPlpI3NqWcoz7PEEwkWKfGOvIHkMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/index.vue")
  },
  {
    name: "hotels-continent-city___it",
    path: "/it/hotels/:continent()/:city()",
    meta: index4udXwgIBvpGLzcKqVPlpI3NqWcoz7PEEwkWKfGOvIHkMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/index.vue")
  },
  {
    name: "hotels-continent-city___es",
    path: "/es/hoteles/:continent()/:city()",
    meta: index4udXwgIBvpGLzcKqVPlpI3NqWcoz7PEEwkWKfGOvIHkMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/[city]/index.vue")
  },
  {
    name: "hotels-continent___en",
    path: "/hotels/:continent()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/index.vue")
  },
  {
    name: "hotels-continent___nl",
    path: "/nl/hotels/:continent()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/index.vue")
  },
  {
    name: "hotels-continent___fr",
    path: "/fr/hotels/:continent()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/index.vue")
  },
  {
    name: "hotels-continent___zh",
    path: "/zh/hotels/:continent()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/index.vue")
  },
  {
    name: "hotels-continent___it",
    path: "/it/hotels/:continent()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/index.vue")
  },
  {
    name: "hotels-continent___es",
    path: "/es/hoteles/:continent()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/[continent]/index.vue")
  },
  {
    name: "hotels___en",
    path: "/hotels",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/index.vue")
  },
  {
    name: "hotels___nl",
    path: "/nl/hotels",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/index.vue")
  },
  {
    name: "hotels___fr",
    path: "/fr/hotels",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/index.vue")
  },
  {
    name: "hotels___zh",
    path: "/zh/hotels",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/index.vue")
  },
  {
    name: "hotels___it",
    path: "/it/hotels",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/index.vue")
  },
  {
    name: "hotels___es",
    path: "/es/hoteles",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/hotels/index.vue")
  },
  {
    name: "legal-slug___en",
    path: "/legal/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/legal/[...slug].vue")
  },
  {
    name: "legal-slug___nl",
    path: "/nl/legal/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/legal/[...slug].vue")
  },
  {
    name: "legal-slug___fr",
    path: "/fr/legal/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/legal/[...slug].vue")
  },
  {
    name: "legal-slug___zh",
    path: "/zh/legal/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/legal/[...slug].vue")
  },
  {
    name: "legal-slug___it",
    path: "/it/legal/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/legal/[...slug].vue")
  },
  {
    name: "legal-slug___es",
    path: "/es/legal/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/legal/[...slug].vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: login_45_e4y_45KrpLR5YCevTDbrt6Zqxsk3PPimidGSqEMVj_wMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/login.vue")
  },
  {
    name: "login___nl",
    path: "/nl/login",
    meta: login_45_e4y_45KrpLR5YCevTDbrt6Zqxsk3PPimidGSqEMVj_wMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/login.vue")
  },
  {
    name: "login___fr",
    path: "/fr/login",
    meta: login_45_e4y_45KrpLR5YCevTDbrt6Zqxsk3PPimidGSqEMVj_wMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/login.vue")
  },
  {
    name: "login___zh",
    path: "/zh/login",
    meta: login_45_e4y_45KrpLR5YCevTDbrt6Zqxsk3PPimidGSqEMVj_wMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/login.vue")
  },
  {
    name: "login___it",
    path: "/it/login",
    meta: login_45_e4y_45KrpLR5YCevTDbrt6Zqxsk3PPimidGSqEMVj_wMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/login.vue")
  },
  {
    name: "login___es",
    path: "/es/login",
    meta: login_45_e4y_45KrpLR5YCevTDbrt6Zqxsk3PPimidGSqEMVj_wMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/login.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-meetingRoomSlug___en",
    path: "/meeting-rooms/v2/:continent()/:city()/:meetingRoomSlug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[...meetingRoomSlug].vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-meetingRoomSlug___nl",
    path: "/nl/meeting-rooms/v2/:continent()/:city()/:meetingRoomSlug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[...meetingRoomSlug].vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-meetingRoomSlug___fr",
    path: "/fr/meeting-rooms/v2/:continent()/:city()/:meetingRoomSlug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[...meetingRoomSlug].vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-meetingRoomSlug___zh",
    path: "/zh/meeting-rooms/v2/:continent()/:city()/:meetingRoomSlug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[...meetingRoomSlug].vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-meetingRoomSlug___it",
    path: "/it/meeting-rooms/v2/:continent()/:city()/:meetingRoomSlug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[...meetingRoomSlug].vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-meetingRoomSlug___es",
    path: "/es/meeting-rooms/v2/:continent()/:city()/:meetingRoomSlug(.*)*",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[...meetingRoomSlug].vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-addons___en",
    path: "/meeting-rooms/v2/:continent()/:city()/:property()/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/addons.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-addons___nl",
    path: "/nl/meeting-rooms/v2/:continent()/:city()/:property()/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/addons.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-addons___fr",
    path: "/fr/meeting-rooms/v2/:continent()/:city()/:property()/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/addons.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-addons___zh",
    path: "/zh/meeting-rooms/v2/:continent()/:city()/:property()/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/addons.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-addons___it",
    path: "/it/meeting-rooms/v2/:continent()/:city()/:property()/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/addons.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-addons___es",
    path: "/es/meeting-rooms/v2/:continent()/:city()/:property()/addons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/addons.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-confirmation___en",
    path: "/meeting-rooms/v2/:continent()/:city()/:property()/confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/confirmation.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-confirmation___nl",
    path: "/nl/meeting-rooms/v2/:continent()/:city()/:property()/confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/confirmation.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-confirmation___fr",
    path: "/fr/meeting-rooms/v2/:continent()/:city()/:property()/confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/confirmation.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-confirmation___zh",
    path: "/zh/meeting-rooms/v2/:continent()/:city()/:property()/confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/confirmation.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-confirmation___it",
    path: "/it/meeting-rooms/v2/:continent()/:city()/:property()/confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/confirmation.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-confirmation___es",
    path: "/es/meeting-rooms/v2/:continent()/:city()/:property()/confirmation",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/confirmation.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-details___en",
    path: "/meeting-rooms/v2/:continent()/:city()/:property()/details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/details.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-details___nl",
    path: "/nl/meeting-rooms/v2/:continent()/:city()/:property()/details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/details.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-details___fr",
    path: "/fr/meeting-rooms/v2/:continent()/:city()/:property()/details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/details.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-details___zh",
    path: "/zh/meeting-rooms/v2/:continent()/:city()/:property()/details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/details.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-details___it",
    path: "/it/meeting-rooms/v2/:continent()/:city()/:property()/details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/details.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-details___es",
    path: "/es/meeting-rooms/v2/:continent()/:city()/:property()/details",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/details.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-payment___en",
    path: "/meeting-rooms/v2/:continent()/:city()/:property()/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/payment.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-payment___nl",
    path: "/nl/meeting-rooms/v2/:continent()/:city()/:property()/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/payment.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-payment___fr",
    path: "/fr/meeting-rooms/v2/:continent()/:city()/:property()/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/payment.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-payment___zh",
    path: "/zh/meeting-rooms/v2/:continent()/:city()/:property()/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/payment.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-payment___it",
    path: "/it/meeting-rooms/v2/:continent()/:city()/:property()/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/payment.vue")
  },
  {
    name: "meeting-rooms-v2-continent-city-property-payment___es",
    path: "/es/meeting-rooms/v2/:continent()/:city()/:property()/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/[continent]/[city]/[property]/payment.vue")
  },
  {
    name: "meeting-rooms-v2-switch___en",
    path: "/meeting-rooms/v2/switch",
    meta: switchJVMU7rzN2d43ouBg19GaPWxjL4U9jEderv2JrSHvXAsMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/switch.vue")
  },
  {
    name: "meeting-rooms-v2-switch___nl",
    path: "/nl/meeting-rooms/v2/switch",
    meta: switchJVMU7rzN2d43ouBg19GaPWxjL4U9jEderv2JrSHvXAsMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/switch.vue")
  },
  {
    name: "meeting-rooms-v2-switch___fr",
    path: "/fr/meeting-rooms/v2/switch",
    meta: switchJVMU7rzN2d43ouBg19GaPWxjL4U9jEderv2JrSHvXAsMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/switch.vue")
  },
  {
    name: "meeting-rooms-v2-switch___zh",
    path: "/zh/meeting-rooms/v2/switch",
    meta: switchJVMU7rzN2d43ouBg19GaPWxjL4U9jEderv2JrSHvXAsMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/switch.vue")
  },
  {
    name: "meeting-rooms-v2-switch___it",
    path: "/it/meeting-rooms/v2/switch",
    meta: switchJVMU7rzN2d43ouBg19GaPWxjL4U9jEderv2JrSHvXAsMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/switch.vue")
  },
  {
    name: "meeting-rooms-v2-switch___es",
    path: "/es/meeting-rooms/v2/switch",
    meta: switchJVMU7rzN2d43ouBg19GaPWxjL4U9jEderv2JrSHvXAsMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/switch.vue")
  },
  {
    name: "meeting-rooms-v2-test___en",
    path: "/meeting-rooms/v2/test",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/test.vue")
  },
  {
    name: "meeting-rooms-v2-test___nl",
    path: "/nl/meeting-rooms/v2/test",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/test.vue")
  },
  {
    name: "meeting-rooms-v2-test___fr",
    path: "/fr/meeting-rooms/v2/test",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/test.vue")
  },
  {
    name: "meeting-rooms-v2-test___zh",
    path: "/zh/meeting-rooms/v2/test",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/test.vue")
  },
  {
    name: "meeting-rooms-v2-test___it",
    path: "/it/meeting-rooms/v2/test",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/test.vue")
  },
  {
    name: "meeting-rooms-v2-test___es",
    path: "/es/meeting-rooms/v2/test",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/meeting-rooms/v2/test.vue")
  },
  {
    name: "my-booking-reservationId___en",
    path: "/my/booking-:reservationId()",
    meta: booking_45_91reservationId_93EDT3HbbAvLsonTnqhjO6IyPkx6o6q6Dz_sGOfoI9mDUMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/booking-[reservationId].vue")
  },
  {
    name: "my-booking-reservationId___nl",
    path: "/nl/my/mijn-boeking-:reservationId()",
    meta: booking_45_91reservationId_93EDT3HbbAvLsonTnqhjO6IyPkx6o6q6Dz_sGOfoI9mDUMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/booking-[reservationId].vue")
  },
  {
    name: "my-booking-reservationId___fr",
    path: "/fr/my/booking-:reservationId()",
    meta: booking_45_91reservationId_93EDT3HbbAvLsonTnqhjO6IyPkx6o6q6Dz_sGOfoI9mDUMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/booking-[reservationId].vue")
  },
  {
    name: "my-booking-reservationId___zh",
    path: "/zh/my/booking-:reservationId()",
    meta: booking_45_91reservationId_93EDT3HbbAvLsonTnqhjO6IyPkx6o6q6Dz_sGOfoI9mDUMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/booking-[reservationId].vue")
  },
  {
    name: "my-booking-reservationId___it",
    path: "/it/my/booking-:reservationId()",
    meta: booking_45_91reservationId_93EDT3HbbAvLsonTnqhjO6IyPkx6o6q6Dz_sGOfoI9mDUMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/booking-[reservationId].vue")
  },
  {
    name: "my-booking-reservationId___es",
    path: "/es/my/booking-:reservationId()",
    meta: booking_45_91reservationId_93EDT3HbbAvLsonTnqhjO6IyPkx6o6q6Dz_sGOfoI9mDUMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/booking-[reservationId].vue")
  },
  {
    name: "my-dashboard___en",
    path: "/my/dashboard",
    meta: dashboardB6Bfhf2CSdgVB3RefTwP2J4EFEcELLAhvMapyF8ENZYMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/dashboard.vue")
  },
  {
    name: "my-dashboard___nl",
    path: "/nl/my/dashboard",
    meta: dashboardB6Bfhf2CSdgVB3RefTwP2J4EFEcELLAhvMapyF8ENZYMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/dashboard.vue")
  },
  {
    name: "my-dashboard___fr",
    path: "/fr/my/dashboard",
    meta: dashboardB6Bfhf2CSdgVB3RefTwP2J4EFEcELLAhvMapyF8ENZYMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/dashboard.vue")
  },
  {
    name: "my-dashboard___zh",
    path: "/zh/my/dashboard",
    meta: dashboardB6Bfhf2CSdgVB3RefTwP2J4EFEcELLAhvMapyF8ENZYMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/dashboard.vue")
  },
  {
    name: "my-dashboard___it",
    path: "/it/my/dashboard",
    meta: dashboardB6Bfhf2CSdgVB3RefTwP2J4EFEcELLAhvMapyF8ENZYMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/dashboard.vue")
  },
  {
    name: "my-dashboard___es",
    path: "/es/my/dashboard",
    meta: dashboardB6Bfhf2CSdgVB3RefTwP2J4EFEcELLAhvMapyF8ENZYMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/dashboard.vue")
  },
  {
    name: "my___en",
    path: "/my",
    meta: indexGLINSKMgBfa7K_KgGQMyv4_AUveDmLg1MOl6nEPYkpsMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/index.vue")
  },
  {
    name: "my___nl",
    path: "/nl/my",
    meta: indexGLINSKMgBfa7K_KgGQMyv4_AUveDmLg1MOl6nEPYkpsMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/index.vue")
  },
  {
    name: "my___fr",
    path: "/fr/my",
    meta: indexGLINSKMgBfa7K_KgGQMyv4_AUveDmLg1MOl6nEPYkpsMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/index.vue")
  },
  {
    name: "my___zh",
    path: "/zh/my",
    meta: indexGLINSKMgBfa7K_KgGQMyv4_AUveDmLg1MOl6nEPYkpsMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/index.vue")
  },
  {
    name: "my___it",
    path: "/it/my",
    meta: indexGLINSKMgBfa7K_KgGQMyv4_AUveDmLg1MOl6nEPYkpsMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/index.vue")
  },
  {
    name: "my___es",
    path: "/es/my",
    meta: indexGLINSKMgBfa7K_KgGQMyv4_AUveDmLg1MOl6nEPYkpsMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/index.vue")
  },
  {
    name: "my-invoice-reservationId-invoiceId___en",
    path: "/my/invoice/:reservationId()/:invoiceId()",
    meta: _91invoiceId_936u9Ft_45mo8gG_45XYxM_45ErPleMvwa1gfvxikcvJHXg86roMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/invoice/[reservationId]/[invoiceId].vue")
  },
  {
    name: "my-invoice-reservationId-invoiceId___nl",
    path: "/nl/my/invoice/:reservationId()/:invoiceId()",
    meta: _91invoiceId_936u9Ft_45mo8gG_45XYxM_45ErPleMvwa1gfvxikcvJHXg86roMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/invoice/[reservationId]/[invoiceId].vue")
  },
  {
    name: "my-invoice-reservationId-invoiceId___fr",
    path: "/fr/my/invoice/:reservationId()/:invoiceId()",
    meta: _91invoiceId_936u9Ft_45mo8gG_45XYxM_45ErPleMvwa1gfvxikcvJHXg86roMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/invoice/[reservationId]/[invoiceId].vue")
  },
  {
    name: "my-invoice-reservationId-invoiceId___zh",
    path: "/zh/my/invoice/:reservationId()/:invoiceId()",
    meta: _91invoiceId_936u9Ft_45mo8gG_45XYxM_45ErPleMvwa1gfvxikcvJHXg86roMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/invoice/[reservationId]/[invoiceId].vue")
  },
  {
    name: "my-invoice-reservationId-invoiceId___it",
    path: "/it/my/invoice/:reservationId()/:invoiceId()",
    meta: _91invoiceId_936u9Ft_45mo8gG_45XYxM_45ErPleMvwa1gfvxikcvJHXg86roMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/invoice/[reservationId]/[invoiceId].vue")
  },
  {
    name: "my-invoice-reservationId-invoiceId___es",
    path: "/es/my/invoice/:reservationId()/:invoiceId()",
    meta: _91invoiceId_936u9Ft_45mo8gG_45XYxM_45ErPleMvwa1gfvxikcvJHXg86roMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/invoice/[reservationId]/[invoiceId].vue")
  },
  {
    name: "my-logout___en",
    path: "/my/logout",
    meta: logouthIE09NwvoVp_mGnvFQSTeV5WMGXYxiXE2_457XCf1GezwMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/logout.vue")
  },
  {
    name: "my-logout___nl",
    path: "/nl/my/logout",
    meta: logouthIE09NwvoVp_mGnvFQSTeV5WMGXYxiXE2_457XCf1GezwMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/logout.vue")
  },
  {
    name: "my-logout___fr",
    path: "/fr/my/logout",
    meta: logouthIE09NwvoVp_mGnvFQSTeV5WMGXYxiXE2_457XCf1GezwMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/logout.vue")
  },
  {
    name: "my-logout___zh",
    path: "/zh/my/logout",
    meta: logouthIE09NwvoVp_mGnvFQSTeV5WMGXYxiXE2_457XCf1GezwMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/logout.vue")
  },
  {
    name: "my-logout___it",
    path: "/it/my/logout",
    meta: logouthIE09NwvoVp_mGnvFQSTeV5WMGXYxiXE2_457XCf1GezwMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/logout.vue")
  },
  {
    name: "my-logout___es",
    path: "/es/my/logout",
    meta: logouthIE09NwvoVp_mGnvFQSTeV5WMGXYxiXE2_457XCf1GezwMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/logout.vue")
  },
  {
    name: "my-meeting-rooms-reservationCode___en",
    path: "/my/meeting-rooms/:reservationCode()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/meeting-rooms/[reservationCode].vue")
  },
  {
    name: "my-meeting-rooms-reservationCode___nl",
    path: "/nl/my/meeting-rooms/:reservationCode()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/meeting-rooms/[reservationCode].vue")
  },
  {
    name: "my-meeting-rooms-reservationCode___fr",
    path: "/fr/my/meeting-rooms/:reservationCode()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/meeting-rooms/[reservationCode].vue")
  },
  {
    name: "my-meeting-rooms-reservationCode___zh",
    path: "/zh/my/meeting-rooms/:reservationCode()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/meeting-rooms/[reservationCode].vue")
  },
  {
    name: "my-meeting-rooms-reservationCode___it",
    path: "/it/my/meeting-rooms/:reservationCode()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/meeting-rooms/[reservationCode].vue")
  },
  {
    name: "my-meeting-rooms-reservationCode___es",
    path: "/es/my/meeting-rooms/:reservationCode()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/meeting-rooms/[reservationCode].vue")
  },
  {
    name: "my-meeting-rooms___en",
    path: "/my/meeting-rooms",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/meeting-rooms/index.vue")
  },
  {
    name: "my-meeting-rooms___nl",
    path: "/nl/my/meeting-rooms",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/meeting-rooms/index.vue")
  },
  {
    name: "my-meeting-rooms___fr",
    path: "/fr/my/meeting-rooms",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/meeting-rooms/index.vue")
  },
  {
    name: "my-meeting-rooms___zh",
    path: "/zh/my/meeting-rooms",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/meeting-rooms/index.vue")
  },
  {
    name: "my-meeting-rooms___it",
    path: "/it/my/meeting-rooms",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/meeting-rooms/index.vue")
  },
  {
    name: "my-meeting-rooms___es",
    path: "/es/my/meeting-rooms",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/meeting-rooms/index.vue")
  },
  {
    name: "my-membership-manage___en",
    path: "/my/membership-manage",
    meta: membership_45manage_j_455nrwxiyPx_45cudDYaL0b47um8_ptFNjIzJN9E7rLUMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership-manage.vue")
  },
  {
    name: "my-membership-manage___nl",
    path: "/nl/my/membership-manage",
    meta: membership_45manage_j_455nrwxiyPx_45cudDYaL0b47um8_ptFNjIzJN9E7rLUMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership-manage.vue")
  },
  {
    name: "my-membership-manage___fr",
    path: "/fr/my/membership-manage",
    meta: membership_45manage_j_455nrwxiyPx_45cudDYaL0b47um8_ptFNjIzJN9E7rLUMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership-manage.vue")
  },
  {
    name: "my-membership-manage___zh",
    path: "/zh/my/membership-manage",
    meta: membership_45manage_j_455nrwxiyPx_45cudDYaL0b47um8_ptFNjIzJN9E7rLUMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership-manage.vue")
  },
  {
    name: "my-membership-manage___it",
    path: "/it/my/membership-manage",
    meta: membership_45manage_j_455nrwxiyPx_45cudDYaL0b47um8_ptFNjIzJN9E7rLUMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership-manage.vue")
  },
  {
    name: "my-membership-manage___es",
    path: "/es/my/membership-manage",
    meta: membership_45manage_j_455nrwxiyPx_45cudDYaL0b47um8_ptFNjIzJN9E7rLUMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership-manage.vue")
  },
  {
    name: "my-membership-teammates-invite___en",
    path: "/my/membership-teammates-invite",
    meta: membership_45teammates_45inviteBje8Q99QJrNJgRzK0VCmsXrrhHaICqHMQrzbW56kUgEMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership-teammates-invite.vue")
  },
  {
    name: "my-membership-teammates-invite___nl",
    path: "/nl/my/membership-teammates-invite",
    meta: membership_45teammates_45inviteBje8Q99QJrNJgRzK0VCmsXrrhHaICqHMQrzbW56kUgEMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership-teammates-invite.vue")
  },
  {
    name: "my-membership-teammates-invite___fr",
    path: "/fr/my/membership-teammates-invite",
    meta: membership_45teammates_45inviteBje8Q99QJrNJgRzK0VCmsXrrhHaICqHMQrzbW56kUgEMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership-teammates-invite.vue")
  },
  {
    name: "my-membership-teammates-invite___zh",
    path: "/zh/my/membership-teammates-invite",
    meta: membership_45teammates_45inviteBje8Q99QJrNJgRzK0VCmsXrrhHaICqHMQrzbW56kUgEMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership-teammates-invite.vue")
  },
  {
    name: "my-membership-teammates-invite___it",
    path: "/it/my/membership-teammates-invite",
    meta: membership_45teammates_45inviteBje8Q99QJrNJgRzK0VCmsXrrhHaICqHMQrzbW56kUgEMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership-teammates-invite.vue")
  },
  {
    name: "my-membership-teammates-invite___es",
    path: "/es/my/membership-teammates-invite",
    meta: membership_45teammates_45inviteBje8Q99QJrNJgRzK0VCmsXrrhHaICqHMQrzbW56kUgEMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership-teammates-invite.vue")
  },
  {
    name: "my-membership___en",
    path: "/my/membership",
    meta: indexHqR9TRZzl9Y1bj6WTqMQbU1FdwTJVa7RtDf3_45wOnBqMMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/index.vue")
  },
  {
    name: "my-membership___nl",
    path: "/nl/my/membership",
    meta: indexHqR9TRZzl9Y1bj6WTqMQbU1FdwTJVa7RtDf3_45wOnBqMMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/index.vue")
  },
  {
    name: "my-membership___fr",
    path: "/fr/my/membership",
    meta: indexHqR9TRZzl9Y1bj6WTqMQbU1FdwTJVa7RtDf3_45wOnBqMMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/index.vue")
  },
  {
    name: "my-membership___zh",
    path: "/zh/my/membership",
    meta: indexHqR9TRZzl9Y1bj6WTqMQbU1FdwTJVa7RtDf3_45wOnBqMMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/index.vue")
  },
  {
    name: "my-membership___it",
    path: "/it/my/membership",
    meta: indexHqR9TRZzl9Y1bj6WTqMQbU1FdwTJVa7RtDf3_45wOnBqMMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/index.vue")
  },
  {
    name: "my-membership___es",
    path: "/es/my/membership",
    meta: indexHqR9TRZzl9Y1bj6WTqMQbU1FdwTJVa7RtDf3_45wOnBqMMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/index.vue")
  },
  {
    name: "my-membership-invoices___en",
    path: "/my/membership/invoices",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/invoices.vue")
  },
  {
    name: "my-membership-invoices___nl",
    path: "/nl/my/membership/invoices",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/invoices.vue")
  },
  {
    name: "my-membership-invoices___fr",
    path: "/fr/my/membership/invoices",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/invoices.vue")
  },
  {
    name: "my-membership-invoices___zh",
    path: "/zh/my/membership/invoices",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/invoices.vue")
  },
  {
    name: "my-membership-invoices___it",
    path: "/it/my/membership/invoices",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/invoices.vue")
  },
  {
    name: "my-membership-invoices___es",
    path: "/es/my/membership/invoices",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/invoices.vue")
  },
  {
    name: "my-membership-payment___en",
    path: "/my/membership/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/payment.vue")
  },
  {
    name: "my-membership-payment___nl",
    path: "/nl/my/membership/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/payment.vue")
  },
  {
    name: "my-membership-payment___fr",
    path: "/fr/my/membership/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/payment.vue")
  },
  {
    name: "my-membership-payment___zh",
    path: "/zh/my/membership/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/payment.vue")
  },
  {
    name: "my-membership-payment___it",
    path: "/it/my/membership/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/payment.vue")
  },
  {
    name: "my-membership-payment___es",
    path: "/es/my/membership/payment",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/membership/payment.vue")
  },
  {
    name: "my-payment-reservationId___en",
    path: "/my/payment-:reservationId()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/payment-[reservationId].vue")
  },
  {
    name: "my-payment-reservationId___nl",
    path: "/nl/my/payment-:reservationId()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/payment-[reservationId].vue")
  },
  {
    name: "my-payment-reservationId___fr",
    path: "/fr/my/payment-:reservationId()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/payment-[reservationId].vue")
  },
  {
    name: "my-payment-reservationId___zh",
    path: "/zh/my/payment-:reservationId()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/payment-[reservationId].vue")
  },
  {
    name: "my-payment-reservationId___it",
    path: "/it/my/payment-:reservationId()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/payment-[reservationId].vue")
  },
  {
    name: "my-payment-reservationId___es",
    path: "/es/my/payment-:reservationId()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/payment-[reservationId].vue")
  },
  {
    name: "my-profile___en",
    path: "/my/profile",
    meta: profile4Je7J0TCUuKif_45rVVPSIkZuHp7hdbNbh3IvVkeFTyDoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/profile.vue")
  },
  {
    name: "my-profile___nl",
    path: "/nl/my/mijn-profiel",
    meta: profile4Je7J0TCUuKif_45rVVPSIkZuHp7hdbNbh3IvVkeFTyDoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/profile.vue")
  },
  {
    name: "my-profile___fr",
    path: "/fr/my/mon-profil",
    meta: profile4Je7J0TCUuKif_45rVVPSIkZuHp7hdbNbh3IvVkeFTyDoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/profile.vue")
  },
  {
    name: "my-profile___zh",
    path: "/zh/my/profile",
    meta: profile4Je7J0TCUuKif_45rVVPSIkZuHp7hdbNbh3IvVkeFTyDoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/profile.vue")
  },
  {
    name: "my-profile___it",
    path: "/it/my/il-mio-profilo",
    meta: profile4Je7J0TCUuKif_45rVVPSIkZuHp7hdbNbh3IvVkeFTyDoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/profile.vue")
  },
  {
    name: "my-profile___es",
    path: "/es/my/mi-perfil",
    meta: profile4Je7J0TCUuKif_45rVVPSIkZuHp7hdbNbh3IvVkeFTyDoMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/profile.vue")
  },
  {
    name: "my-reservation-reservationId___en",
    path: "/my/reservation-:reservationId()",
    meta: reservation_45_91reservationId_93J6zWkUngtI3NqJ80DNXmgdvJS9PJwmqmJPDiNJSAi1oMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/reservation-[reservationId].vue")
  },
  {
    name: "my-reservation-reservationId___nl",
    path: "/nl/my/mijn-reservering-:reservationId()",
    meta: reservation_45_91reservationId_93J6zWkUngtI3NqJ80DNXmgdvJS9PJwmqmJPDiNJSAi1oMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/reservation-[reservationId].vue")
  },
  {
    name: "my-reservation-reservationId___fr",
    path: "/fr/my/ma-reservation-:reservationId()",
    meta: reservation_45_91reservationId_93J6zWkUngtI3NqJ80DNXmgdvJS9PJwmqmJPDiNJSAi1oMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/reservation-[reservationId].vue")
  },
  {
    name: "my-reservation-reservationId___zh",
    path: "/zh/my/reservation-:reservationId()",
    meta: reservation_45_91reservationId_93J6zWkUngtI3NqJ80DNXmgdvJS9PJwmqmJPDiNJSAi1oMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/reservation-[reservationId].vue")
  },
  {
    name: "my-reservation-reservationId___it",
    path: "/it/my/la-mia-prenotazione-:reservationId()",
    meta: reservation_45_91reservationId_93J6zWkUngtI3NqJ80DNXmgdvJS9PJwmqmJPDiNJSAi1oMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/reservation-[reservationId].vue")
  },
  {
    name: "my-reservation-reservationId___es",
    path: "/es/my/mi-reserva-:reservationId()",
    meta: reservation_45_91reservationId_93J6zWkUngtI3NqJ80DNXmgdvJS9PJwmqmJPDiNJSAi1oMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/reservation-[reservationId].vue")
  },
  {
    name: "my-reservations___en",
    path: "/my/reservations",
    meta: reservationstcm_45L_45QWZckdV2qYeaQm8WxhMmTYH4Dtk5JEHJFSNj8Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/reservations.vue")
  },
  {
    name: "my-reservations___nl",
    path: "/nl/my/mijn-reserveringen",
    meta: reservationstcm_45L_45QWZckdV2qYeaQm8WxhMmTYH4Dtk5JEHJFSNj8Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/reservations.vue")
  },
  {
    name: "my-reservations___fr",
    path: "/fr/my/mes-reservations",
    meta: reservationstcm_45L_45QWZckdV2qYeaQm8WxhMmTYH4Dtk5JEHJFSNj8Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/reservations.vue")
  },
  {
    name: "my-reservations___zh",
    path: "/zh/my/reservations",
    meta: reservationstcm_45L_45QWZckdV2qYeaQm8WxhMmTYH4Dtk5JEHJFSNj8Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/reservations.vue")
  },
  {
    name: "my-reservations___it",
    path: "/it/my/le-mie-prenotazioni",
    meta: reservationstcm_45L_45QWZckdV2qYeaQm8WxhMmTYH4Dtk5JEHJFSNj8Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/reservations.vue")
  },
  {
    name: "my-reservations___es",
    path: "/es/my/mis-reservas",
    meta: reservationstcm_45L_45QWZckdV2qYeaQm8WxhMmTYH4Dtk5JEHJFSNj8Meta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/my/reservations.vue")
  },
  {
    name: "ping___en",
    path: "/ping",
    meta: pingbbRaBU9tjFVyZCtgcSCj7SaHbRp0P9M4XUZK5GAAPUQMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/ping.vue")
  },
  {
    name: "ping___nl",
    path: "/nl/ping",
    meta: pingbbRaBU9tjFVyZCtgcSCj7SaHbRp0P9M4XUZK5GAAPUQMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/ping.vue")
  },
  {
    name: "ping___fr",
    path: "/fr/ping",
    meta: pingbbRaBU9tjFVyZCtgcSCj7SaHbRp0P9M4XUZK5GAAPUQMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/ping.vue")
  },
  {
    name: "ping___zh",
    path: "/zh/ping",
    meta: pingbbRaBU9tjFVyZCtgcSCj7SaHbRp0P9M4XUZK5GAAPUQMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/ping.vue")
  },
  {
    name: "ping___it",
    path: "/it/ping",
    meta: pingbbRaBU9tjFVyZCtgcSCj7SaHbRp0P9M4XUZK5GAAPUQMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/ping.vue")
  },
  {
    name: "ping___es",
    path: "/es/ping",
    meta: pingbbRaBU9tjFVyZCtgcSCj7SaHbRp0P9M4XUZK5GAAPUQMeta || {},
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/ping.vue")
  },
  {
    name: "ping3___en",
    path: "/ping3",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/ping3.vue")
  },
  {
    name: "ping3___nl",
    path: "/nl/ping3",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/ping3.vue")
  },
  {
    name: "ping3___fr",
    path: "/fr/ping3",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/ping3.vue")
  },
  {
    name: "ping3___zh",
    path: "/zh/ping3",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/ping3.vue")
  },
  {
    name: "ping3___it",
    path: "/it/ping3",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/ping3.vue")
  },
  {
    name: "ping3___es",
    path: "/es/ping3",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/ping3.vue")
  },
  {
    name: "register___en",
    path: "/register",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/register.vue")
  },
  {
    name: "register___nl",
    path: "/nl/inschrijven",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/register.vue")
  },
  {
    name: "register___fr",
    path: "/fr/souscription",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/register.vue")
  },
  {
    name: "register___zh",
    path: "/zh/register",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/register.vue")
  },
  {
    name: "register___it",
    path: "/it/registro",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/register.vue")
  },
  {
    name: "register___es",
    path: "/es/register",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/register.vue")
  },
  {
    name: "rooftop-bars-continent-city-featureSlug___en",
    path: "/rooftop-bars/:continent()/:city()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/rooftop-bars/[continent]/[city]/[featureSlug].vue")
  },
  {
    name: "rooftop-bars-continent-city-featureSlug___nl",
    path: "/nl/rooftop-bars/:continent()/:city()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/rooftop-bars/[continent]/[city]/[featureSlug].vue")
  },
  {
    name: "rooftop-bars-continent-city-featureSlug___fr",
    path: "/fr/rooftop-bars/:continent()/:city()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/rooftop-bars/[continent]/[city]/[featureSlug].vue")
  },
  {
    name: "rooftop-bars-continent-city-featureSlug___zh",
    path: "/zh/rooftop-bars/:continent()/:city()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/rooftop-bars/[continent]/[city]/[featureSlug].vue")
  },
  {
    name: "rooftop-bars-continent-city-featureSlug___it",
    path: "/it/rooftop-bars/:continent()/:city()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/rooftop-bars/[continent]/[city]/[featureSlug].vue")
  },
  {
    name: "rooftop-bars-continent-city-featureSlug___es",
    path: "/es/rooftop-bars/:continent()/:city()/:featureSlug()",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/rooftop-bars/[continent]/[city]/[featureSlug].vue")
  },
  {
    name: "test-icons___en",
    path: "/test-icons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/test-icons.vue")
  },
  {
    name: "test-icons___nl",
    path: "/nl/test-icons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/test-icons.vue")
  },
  {
    name: "test-icons___fr",
    path: "/fr/test-icons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/test-icons.vue")
  },
  {
    name: "test-icons___zh",
    path: "/zh/test-icons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/test-icons.vue")
  },
  {
    name: "test-icons___it",
    path: "/it/test-icons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/test-icons.vue")
  },
  {
    name: "test-icons___es",
    path: "/es/test-icons",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/test-icons.vue")
  },
  {
    name: "test___en",
    path: "/test",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/test.vue")
  },
  {
    name: "test___nl",
    path: "/nl/test",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/test.vue")
  },
  {
    name: "test___fr",
    path: "/fr/test",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/test.vue")
  },
  {
    name: "test___zh",
    path: "/zh/test",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/test.vue")
  },
  {
    name: "test___it",
    path: "/it/test",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/test.vue")
  },
  {
    name: "test___es",
    path: "/es/test",
    component: () => import("/home/vsts/work/1/s/apps/lobby/pages/test.vue")
  },
  {
    name: component_45stubm_qj6vEeBTUaAb1znCYox_45H8h0i1bl0al_45HHgP7gFVYMeta?.name,
    path: "/my/login",
    component: component_45stubm_qj6vEeBTUaAb1znCYox_45H8h0i1bl0al_45HHgP7gFVY
  },
  {
    name: component_45stubm_qj6vEeBTUaAb1znCYox_45H8h0i1bl0al_45HHgP7gFVYMeta?.name,
    path: "/profile",
    component: component_45stubm_qj6vEeBTUaAb1znCYox_45H8h0i1bl0al_45HHgP7gFVY
  },
  {
    name: component_45stubm_qj6vEeBTUaAb1znCYox_45H8h0i1bl0al_45HHgP7gFVYMeta?.name,
    path: "/error",
    component: component_45stubm_qj6vEeBTUaAb1znCYox_45H8h0i1bl0al_45HHgP7gFVY
  },
  {
    name: component_45stubm_qj6vEeBTUaAb1znCYox_45H8h0i1bl0al_45HHgP7gFVYMeta?.name,
    path: "/nuxt3",
    component: component_45stubm_qj6vEeBTUaAb1znCYox_45H8h0i1bl0al_45HHgP7gFVY
  }
]