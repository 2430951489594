import { parseURL, withBase } from "ufo";

const bynderDomain = "https://brandportal.citizenm.com";
const bynderMaxWidth = 2000;

const ioValues = ["transform", "width", "height"];

const operationsGenerator = (modifiers) => {
  const params = [];
  const io = [];
  const modKeys = Object.keys(modifiers);

  if (modKeys.includes("transform")) {
    io.push("transform:" + modKeys["transform"]);
  } else {
    io.push("transform:fill");
  }

  modKeys.forEach((mod) => {
    let val = modifiers[mod];
    if (!val) return;
    if (val == "width" && val > bynderMaxWidth) val = bynderMaxWidth;
    if (ioValues.includes(mod)) {
      io.push(mod + ":" + val);
    } else {
      params.push(mod + "=" + val);
    }
  });

  if (io.length) {
    params.push("io=" + io.join(","));
  }

  return params.join("&");
};

export const getImage = (
  src,
  { modifiers = {}, baseURL = bynderDomain } = {},
) => {
  const operations = operationsGenerator(modifiers);
  const { pathname } = parseURL(src);
  const path = pathname + (operations ? "?" + operations : "");
  const url = withBase(path, baseURL);
  return {
    url,
  };
};

export default {
  name: "bynder",
  getImage: getImage,
};
